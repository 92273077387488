import React, { createContext, useState, FC, useEffect } from "react";
import Toast from "../components/Toast";
import Version from "../components/Version";
import { IToastOptions } from "../Interfaces/IToastOptions";
import { IUIContext, IUIContextProps } from "../Interfaces/IUIContext";

const toastInitialState = { open: false, message: "Cliente cadastrado com sucesso!", type: "error" } as IToastOptions;

const UIContext = createContext<IUIContext>({} as IUIContext);

export const UIContextProvider: FC = ({ children }) => {

  const [componentUIState, setComponentUIState] = useState<IUIContextProps>({} as IUIContextProps);
  const [toastState, setToastState] = useState<IToastOptions>(toastInitialState);

  useEffect(() => {
    switch (componentUIState.component) {
      case 'Toast': setToastState(componentUIState.componentState as IToastOptions); break;
    }
  }, [componentUIState]);
  

  return (
    <UIContext.Provider value={{ setComponentUIState }}>
       <Toast 
        open={toastState.open} 
        onClose={() => setToastState({ ...toastState, open: false })}
        message={toastState.message}
        type={toastState.type}
        duration={toastState.duration}
      />
      { children }
      <Version />
    </UIContext.Provider>
  );
}

export default UIContext;