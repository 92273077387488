import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { useContext } from 'react';
import { icons } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { IParameters } from '../../Interfaces/IParameters';
import { parameterService } from '../../services/parameter';

import './styles.css';
import Swal from 'sweetalert2';
import { dreList } from '../../utils/contants/dreList';

interface IProps {
  open: boolean;
  data: {
    id: number;
    parameter: IParameters;
  }
  onClose(): void;
  onRefresh(): void;
}

const ParameterView: React.FC<IProps> = ({ open, data: { id, parameter }, onClose, onRefresh }) => {
  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [classification, setClassification] = useState<string>("");
  const [dreClassification, setDreClassification] = useState<string>("");
  const [newInvoice, setNewInvoice] = useState<string>("");

  useEffect(() => {
    if (open) {
      setNewInvoice(parameter.invoice);
      setClassification(parameter.classification);
      setDreClassification(parameter.dreClassification);
    }
    if (!open) {
      setNewInvoice("");
      setClassification("");
      setDreClassification("");
    }
  }, [open])

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const alertUpdate = () => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      icon: 'warning',
      html: `Tem certeza que deseja alterar esse lançamento ?\n Todos os lançamentos desta conta irão ser alterados.`,
      text: 'aopa',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Sim',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i> Não',
      cancelButtonAriaLabel: 'Thumbs down'
    }).then((data: any) => {
      if (data.isConfirmed) {
        handleUpdate();
      }
    })
  }

  const middlewareUpdate = () => {
    if (parameter.invoice !== newInvoice) return alertUpdate();
    return handleUpdate();
  }

  const handleUpdate = async () => {
    try {
      if (!!!classification) return showToast("Campo Classificação é obrigatório!", "error");
      if (!!!dreClassification) return showToast("Campo DRE é obrigatório!", "error");
      const { status, data } = await parameterService.update(token, id, { ...parameter, invoice: newInvoice, classification: classification, dreClassification: dreClassification });
      if (status === 200) {
        onRefresh()
        onClose();
        showToast("Alterado com sucesso!", "success");
      } else {
        throw `Falha ao alterar parâmetro, status: ${status}`;
      }
    } catch (error) {
      showToast(`${error}`, "error");
    }
  }

  return (
    <div className={open ? "view-page" : "close-view-page"}>
      <div className="bg-view-container" onClick={onClose}></div>
      <div className="view-container">
        <img src={icons.closeIconAppColor} className="close-icon-modal" onClick={onClose} />
        <div className="content">
          <span>Conta</span>
          <input value={newInvoice} onChange={(e) => setNewInvoice(e.target.value)} />
        </div>
        <div className="content2">
          <span>Classificação</span>
          <div className="select-input">
            <Select
              placeholder="Selecione a classificação"
              options={[
                { value: 'entrada', label: 'entrada' },
                { value: 'saída', label: 'saída' }
              ]}
              value={{ value: classification, label: classification }}
              onChange={(e) => setClassification(e?.value || "")}
            />
          </div>
        </div>
        <div className="content2">
          <span>DRE</span>
          <div className="select-input">
            <Select
              placeholder="Selecione o tipo DRE"
              options={dreList}
              value={{ value: dreClassification, label: dreClassification }}
              onChange={(e) => setDreClassification(e?.value || "")}
            />
          </div>
        </div>
        <div className="footer">
          <button className="cancel-btn" onClick={onClose}>Cancelar</button>
          <button className="save-btn" onClick={middlewareUpdate}>Alterar</button>
        </div>

      </div>
    </div>
  );
}

export default ParameterView;