import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { cashflowService } from '../../services/cashflow';

import './styles.css';

interface IProps {
  data: {
    defaultSelected: number;
    recurrenceId: string;
  };
  onClose(): void;
  onSave(ids: number[]): void;
}

const DeleteRecurrenceModal: React.FC<IProps> = ({ data: { recurrenceId, defaultSelected }, onClose, onSave }) => {
  const { token } = useContext(AuthContext);

  const [selectedIds, setSelectedsIds] = useState(() => new Set<number>());
  const [items, setItems] = useState<{ id: number; date: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    onClose()
  }

  const handleSave = () => {
    const data = Array.from(selectedIds)
    onSave(data)
  }

  async function getRecurrences() {
    try {
      setIsLoading(true);
      const response = await cashflowService.getRecurrences(recurrenceId, token);
      if (response.status === 200) {
        setItems(response.data.data)
        setSelectedsIds((prev) => prev.add(defaultSelected))
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getRecurrences();
  }, [recurrenceId])

  return (
    <div className='modal-delete-recourrence'>
      <div className='off-container' />
      <div className='on-container'>
        <header>
          <span>({selectedIds.size}) Selecionados</span>
        </header>
        <div>
          <p>
            Este lançamento foi cadastrado em uma <b>recorrência</b>, por padrão foi selecionado o lançamento clicado, caso queira apagar outros selecione-os.
          </p>
          <table>
            <tr>
              <th>#</th>
              <th>Data</th>
            </tr>
            {
              items.map(({ id, date }) => {
                const isSelected = selectedIds.has(id)

                const handleChecked = () => {
                  if (isSelected) {
                    return setSelectedsIds((prev) => {
                      const data = new Set(prev);
                      data.delete(id);
                      return data;
                    })
                  }

                  return setSelectedsIds((prev) => {
                    const data = new Set(prev);
                    data.add(id);
                    return data;
                  })
                }

                return (
                  <tr>
                    <td>
                      <input id='delete-recurrence-modal-checkbox' type={'checkbox'} checked={isSelected} onClick={handleChecked} />
                    </td>
                    <td>{date}</td>
                  </tr>
                )
              })
            }
          </table>
        </div>
        <footer>
          <button className="cancel-btn" onClick={handleCancel}>Cancelar</button>
          <button className="save-btn" onClick={handleSave}>Apagar</button>
        </footer>
      </div>
    </div>
  );
}

export default DeleteRecurrenceModal;