import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { icons } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { userService } from '../../services/user';

import './styles.css';

interface IProps {
  open: boolean;
  onClose(): void;
  userId: number;
}

interface IPasswords {
  newPassword: string;
  newPasswordRepeat: string;
}

const ChangePassword: React.FC<IProps> = ({ open, onClose, userId }) => {
  const [password, setPassword] = useState<IPasswords>({
    newPassword: "",
    newPasswordRepeat: ""
  });

  const { setComponentUIState } = useContext(UIContext);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    setPassword({
      newPassword: "",
      newPasswordRepeat: ""
    })
  },[])

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleSave = async () => {
    if (!isValidPassword()) return;

    try {
      const { status } = await userService.changePassword(token, userId, password.newPassword);
      if(status !== 200) throw `Falha ao alterar senha, status code: ${status}`;
      
      showToast("Senha alterada com sucessso", "success");
      onClose();
    } catch (error) {
      showToast(`${error}`, "error");
    }
    
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const property = e.target.name;
    const value = e.target.value;
    setPassword(oldState => ({ ...oldState, [property]: value }));
  }

  function isValidPassword(): boolean {
    const { newPassword, newPasswordRepeat } = password;
    if (newPassword === newPasswordRepeat) return true;

    if (newPassword === "") {
      showToast("Todos os campos são obrigatórios", "warning");
      return false;
    }

    if (newPasswordRepeat === "") {
      showToast("Todos os campos são obrigatórios", "warning");
      return false;
    }

    if (newPassword !== newPasswordRepeat) {
      showToast("Senhas não conferem", "warning");
      return false;
    }

    return false;
  }

  return (
    <>
      {open &&
        <>
          <div className="bg-container" onClick={onClose} />
          <div className="change-password-container">
            <div className="header">
              <img src={icons.passwordKey} />
              <span>Alteração de Senha</span>
            </div>
            <div className="content">
              <div className="input-container">
                <span>Nova senha</span>
                <input type="password" name="newPassword" value={password.newPassword} onChange={handleChangeInput} />
              </div>
              <div className="input-container">
                <span>Confirme a Senha</span>
                <input type="password" name="newPasswordRepeat" value={password.newPasswordRepeat} onChange={handleChangeInput} />
              </div>
            </div>
            <div className="footer">
              <button className="cancel-btn" onClick={onClose}>Cancelar</button>
              <button className="save-btn" onClick={handleSave}>Salvar</button>
            </div>

          </div>
        </>
      }
    </>
  );
}

export default ChangePassword;