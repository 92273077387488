export function daysBetweenDates(initialDate: string, endDate: string ){
    const formatedInitialDate = initialDate.replace(/-/g, '')
    const formatedEndDate = endDate.replace(/-/g, '')
    const start = `${formatedInitialDate.substr(0,4)}/${formatedInitialDate.substr(4,2)}/${formatedInitialDate.substr(6,2)}`;
    const end = `${formatedEndDate.substr(0,4)}/${formatedEndDate.substr(4,2)}/${formatedEndDate.substr(6,2)}`;

    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}
