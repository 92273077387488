import React from 'react';

import './styles.css';
import { icons } from '../../assets/images';

type CloseData = { type: "close" | "manual" | "upload", classification?: "pagamento" | "recebimento" }

interface IProps {
  open: boolean;
  onClose(): void;
}

const ProductInfo: React.FC<IProps> = ({ open, onClose }) => {
  return (
    <>
      <div className={open ? "background-options" : ""} />
      <div className={open ? "options-container" : "close-options-container"}>
        <div className="info-container">
          <img src={icons.closeIconAppColor} className="close-icon" onClick={onClose}/>
          <h1>Seja bem-vindo(a)!!</h1>
          <span>Veja o vídeo abaixo para aproveitar ao máximo a plataforma</span>
          <div className="video-section">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/LyZZRpiuA_U?si=oRTH-hrU7pe7Xm_F"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductInfo;