import { API } from "./config"
import { AxiosResponse } from 'axios';
import { IParameterResponse } from "../Interfaces/IParameterResponse";
import { IParameters } from "../Interfaces/IParameters";
import { create } from "domain";
import { IDREGeneric, IDREResponse } from "../Interfaces/IDreResponse";

export const parameterService = {
  async findAll(token: string): Promise<AxiosResponse<IParameterResponse>> {
    return await API.get('/parameter', { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async update(token: string, id: number, parameter: IParameters): Promise<AxiosResponse> {
    return await API.post('/parameter/update', { id, parameter }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async delete(token: string, id: number): Promise<AxiosResponse<IParameterResponse>> {
    return await API.delete(`/parameter/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async create(token: string, invoice: Partial<IParameters>): Promise<AxiosResponse> {
    const parameters = { ...invoice };
    return await API.post('/parameter/create', { parameters }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async order(token: string, parameters: IParameters[]): Promise<AxiosResponse> {
    return await API.post('/parameter/order', { parameters }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async getDre(token: string, { month, year, consumer }: { month?: string, year?: string, consumer: number }): Promise<{ data: { dre: IDREGeneric } }> {
    let query = '';

    if (month) query = `month=${month}&`;
    if (year) query += `year=${year}&`;
    if (consumer) query += `consumerId=${consumer}`;

    // return {
    //   data: {
    //     'Receita': { percentage: null, total: 125060.59 },
    //     'Impostos': { percentage: 29, total: -36366.53 },
    //     'Receita Bruta': { percentage: null, total: 88694.06 },
    //     'Custo Operacional': { percentage: 66, total: -82067.70 },
    //     'Margem de Contribuiçao': { percentage: null, total: 6626.36 },
    //     'Despesas': { percentage: 20, total: -24594.63 },
    //     'Resultado': { percentage: null, total: -17968.27 },
    //     'Receitas/Despesas Financeiras': { percentage: 12, total: -14819.75 },
    //     'Lucro/Prejuízo Líquido': { percentage: 26, total: -32788.02 }
    //   }
    // }


    return await API.get(`/parameter/dre?${query}`, { headers: { 'Authorization': `Bearer ${token}` } });
  }

}