import React, { useState } from 'react';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns'
import { paymentsService } from '../../services/payments';
import { icons } from '../../assets/images';

function RefreshPayment() {
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();

  const { dueDate, userId } = location.state as { dueDate: Date, userId: number };

  async function generateLink() {
    if (loading) return;

    setLoading(true);

    try {
      const { status, data: { data: { url } } } = await paymentsService.generatePaymentLink(userId, false);

      if (status === 200) {
        window.location.href = url;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    // <div className="refresh-payment-container">
    //   <div className="content">
    //     <span>Assinatura Justo e Calculado</span>
    //     <p>Seu plano venceu em: {format(new Date(dueDate), 'dd/MM/yyyy')}</p>
    //     <button
    //       className={loading ? 'refresh-payment-btn-loading' : 'refresh-payment-btn'}
    //       onClick={generateLink}>
    //       {loading ? "Gerando link ..." : "Pagar Agora"}
    //     </button>
    //   </div>
    // </div>
    <div className="layout">
      <div className="image">
        <img src={icons.sideBarLogoWhite} alt="logo" className="logo" />
      </div>
      <div className="message">
        <div className='messages'>
          <h1>Atenção</h1>
          <h2>Seu plano venceu em {format(new Date(dueDate), 'dd/MM/yyyy')} </h2>
          <h3>Não perca tempo, controle o seu dinheiro</h3>
        </div>
        <button
          className={loading ? 'refresh-payment-btn-loading' : 'refresh-payment-btn'}
          onClick={generateLink}>
          {loading ? "Gerando link ..." : "Pagar Agora"}
        </button>
      </div>
    </div>
  );
}

export default RefreshPayment;
