export const calculateTotalByMonth = (data: {
  name: string; MOUNTH_VALUES: {
    ["JANEIRO"]: number;
    ["FEVEREIRO"]: number;
    ["MARCO"]: number;
    ["ABRIL"]: number;
    ["MAIO"]: number;
    ["JUNHO"]: number;
    ["JULHO"]: number;
    ["AGOSTO"]: number;
    ["SETEMBRO"]: number;
    ["OUTUBRO"]: number;
    ["NOVEMBRO"]: number;
    ["DEZEMBRO"]: number;
  }; order: number; type: "entrada" | "saída"
}[], initialValue: number = 0): {
  name: string;
  order: number;
  type: "entrada" | "saída";
  MOUNTH_VALUES: object;
} => {
  
  const baseArray: {
    ["JANEIRO"]: number;
    ["FEVEREIRO"]: number;
    ["MARCO"]: number;
    ["ABRIL"]: number;
    ["MAIO"]: number;
    ["JUNHO"]: number;
    ["JULHO"]: number;
    ["AGOSTO"]: number;
    ["SETEMBRO"]: number;
    ["OUTUBRO"]: number;
    ["NOVEMBRO"]: number;
    ["DEZEMBRO"]: number;
    ["type"]: "entrada" | "saída";
  }[] = []

  data.map(row => {
    const newObj = {
      ...row.MOUNTH_VALUES,
      'type': row.type
    }
    baseArray.push(newObj)
  })

  const totalMonthValues = {
    ["JANEIRO"]: 0,
    ["FEVEREIRO"]: 0,
    ["MARCO"]: 0,
    ["ABRIL"]: 0,
    ["MAIO"]: 0,
    ["JUNHO"]: 0,
    ["JULHO"]: 0,
    ["AGOSTO"]: 0,
    ["SETEMBRO"]: 0,
    ["OUTUBRO"]: 0,
    ["NOVEMBRO"]: 0,
    ["DEZEMBRO"]: 0
  }

  Object.entries(totalMonthValues).map((month) => {
    const currentMonth = month[0] as
      "JANEIRO" |
      "FEVEREIRO" |
      "MARCO" |
      "ABRIL" |
      "MAIO" |
      "JUNHO" |
      "JULHO" |
      "AGOSTO" |
      "SETEMBRO" |
      "OUTUBRO" |
      "NOVEMBRO" |
      "DEZEMBRO"

    const total = baseArray.reduce((accumulator, current) =>
      current.type === "entrada" ? accumulator + current[currentMonth] : accumulator - current[currentMonth], 0);
 
    initialValue = calculateInitialValue(total, initialValue);
    totalMonthValues[currentMonth] = initialValue;
  })

  return {
    name: "Total Geral",
    MOUNTH_VALUES: {
      ...totalMonthValues
    },
    order: 9999999,
    type: "entrada"
  }

}


function calculateInitialValue(total: number, initialValue: number): number{
  const signal = Math.sign(total);

  if(signal === -1) {
    return initialValue - Math.abs(total);
  } else if (signal === 1) {
    return initialValue + total;
  } else {
    return initialValue
  }
}