import React, { createContext, FC } from 'react';
import { useContext } from 'react';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import Sidebar from '../components/Sidebar';
import { IMainContext } from '../Interfaces/IMainContext';
import AuthContext from './AuthContext';

const MainContext = createContext<IMainContext>({} as IMainContext);

export const MainContextProvider: FC = ({ children }) => {
  const { isAutenticated } = useContext(AuthContext);

  return (
    <MainContext.Provider value={{}} >
      {isAutenticated && <div id="main">
        <Sidebar />
        <div className="main-body">
          <Header />
          <MainContent>
            {children}
          </MainContent>
        </div>
      </div>}
    </MainContext.Provider>
  )
}

export default MainContext;