import React, { useState } from 'react'
import { useRef } from 'react';
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import { login_logo } from '../../assets/images'
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext'
import { IsEmail } from '../../helpers/isEmail';
import { loginService } from '../../services/login';
import './styles.css'

const RecoveryPasswd: React.FC = () => {
  const { setComponentUIState } = useContext(UIContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleRecovery = async () => {
    if (loading) return;
    try {
      const email = emailRef?.current?.value || "";
      if (!email || email.trim() === "") {
        return showToast("Email é obrigatório", "warning");
      }
      const response = await loginService.recoveryPassword(email.trim());
      if(response.status === 200) {
        showToast("Uma nova senha foi enviada para seu email", "success");
        navigate("/");
        return;
      }

      if(response.status === 404) {
        showToast(response.data.message, "error");
        return;
      }

      throw "";

    } catch (error) {
      showToast("Ops... Não foi possivel enviar nova senha", "error");
    }
  }



  return (
    <div id="login-container">
      <div className="form-box">
        <div className="logo-container">
          <img src={login_logo} role="logo" />
        </div>
        <div className="form">
          <div className="input-box">
            <label>Email</label>
            <input ref={emailRef} />
          </div>
          <button onClick={handleRecovery} role="access-btn">Recuperar Senha</button>
        </div>
      </div>
    </div>
  )
}

export default RecoveryPasswd