import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import CashflowContextProvider from './contexts/CashflowContext';
import ContextProvider from './contexts/ContextProvider';
import DREContextProvider from './contexts/DREContext';
import { MainContextProvider } from './contexts/MainContext';
import ResumeContextProvider from './contexts/ResumeContext';
import { UsersFormContextProvider } from './contexts/UsersFormContext';
import Cashflow from './pages/Cashflow';
import CashflowForm from './pages/CashflowForm';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ParameterForm from './pages/ParameterForm';
import Parameters from './pages/Parameters';
import PowerBI from './pages/PowerBI';
import Profile from './pages/Profile';
import UserForm from './pages/UserForm';
import Users from './pages/Users';
import Payments from './pages/Payments';
import RefreshPayment from './pages/RefreshPayment';
import RecoveryPasswd from './pages/RecoveryPasswd';
import WhatsappButton from './components/WhatsappButton';

export const PageRoutes = () => {

  return (
    <BrowserRouter>
      <ContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/recuperar-senha" element={<RecoveryPasswd />} />
          <Route path="/assinatura" element={<RefreshPayment />} />
        </Routes>
        <MainContextProvider>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/powerBi" element={<PowerBI />} />
            <Route path="/resume" element={<ResumeContextProvider />} />
            <Route path="/dre" element={<DREContextProvider />} />
            <Route path="/parameters" element={<Parameters />} />
            <Route path="/parameters/new-parameter" element={<ParameterForm />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/form" element={
              <UsersFormContextProvider>
                <UserForm />
              </UsersFormContextProvider>
            } />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/payments" element={<Payments />} />
          </Routes>
          <CashflowContextProvider>
            <Routes>
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/cashflow/form" element={<CashflowForm />} />
            </Routes>
          </CashflowContextProvider>
        </MainContextProvider>
        <WhatsappButton />
      </ContextProvider>
    </BrowserRouter>
  )


}