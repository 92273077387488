import React from 'react'
import { useRef } from 'react';
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import { login_logo } from '../../assets/images'
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext'
import { IsEmail } from '../../helpers/isEmail';
import { loginService } from '../../services/login';
import './styles.css'

const Login: React.FC = () => {
  const { setComponentUIState } = useContext(UIContext)
  const { handleAutenticate, handlePopulateUser } = useContext(AuthContext);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  function showToast(open: boolean, msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open, message: msg, type }
    })
  }

  function validateForm(email: string, password: string) {
    if (email === "") {
      showToast(true, "O campo email deve ser preenchido.", "warning");
      return false;
    }

    if (password === "") {
      showToast(true, "O campo senha deve ser preenchido.", "warning");
      return false;
    }

    if (!IsEmail(email)) {
      showToast(true, "O email digitado não é valido.", "warning");
      return false;
    }

    return true;
  }

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    const email = emailRef?.current?.value || "";
    const password = passwordRef?.current?.value || "";

    const validform = validateForm(email, password);
    if (!validform) return;

    try {
      const { status, data: { user, token, msg, dueDate, userId } } = await loginService.auth(email, password);

      if (status !== 200) {
        if (status === 500) {
          return showToast(true, `Falha não esperada..., status ${status}`, "error");
        }

        const isPaymentError = ["overdue payment", "payment unrealized", "trial expired"];

        if (status === 403 && isPaymentError.includes(msg || "")) {
          return navigate('/assinatura', { state: { dueDate: dueDate || "", userId: userId } });
        }

        if (status === 403) {
          return showToast(true, "Usuário inativo.", "warning");
        }

        return showToast(true, "Usuário ou senha inválido", "error")
      }

      if (status === 200) {
        showToast(true, "Login efetuado com sucesso!", "success")
        handleAutenticate(token, true, user);
        handlePopulateUser(user);
        const isFirstAccess = user.user?.lastAccess ? false : true;
        navigate('/dashboard', { state: { isFirstAccess } });
      }


    } catch (error) {
      return showToast(true, "Falha não esperada...", "error");
    }
  }

  const handleRecovery = () => {
    return navigate('/recuperar-senha')
  }


  return (
    <div id="login-container">
      <form className="form-box">
        <div className="logo-container">
          <img src={login_logo} role="logo" />
        </div>
        <div className="form">
          <div className="input-box">
            <label>Usuário</label>
            <input ref={emailRef} />
          </div>
          <div className="input-box">
            <label>Senha</label>
            <input ref={passwordRef} type="password" />
          </div>
          <button onClick={handleLogin} role="access-btn">Entrar</button>
          <span className="recovery-passwd" onClick={handleRecovery}>Esqueci minha senha</span>
        </div>
      </form>
    </div>
  )
}

export default Login