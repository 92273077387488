import { icons } from '../assets/images';

export const toastIcons = (type: "info" | "warning" | "error" | "success") => {
  switch (type) {
    case "info": return icons.infoIcon;
    case "success": return icons.successIcon;
    case "warning": return icons.warningIcon;
    case "error": return icons.errorIcon;
    default: {
      return icons.successIcon
    }
  }
}