import React, { InputHTMLAttributes, useCallback } from "react";
import { currency } from "../../helpers/masks/currency";

import "./styles.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  mask: "currency";
  prefix?: string;
}


const CurrencyInput = React.forwardRef<any, InputProps>(({ mask, prefix, ...props }, ref) => {
  const handleKeyUp = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === "currency") {
        currency(e);
      }
    },
    [mask]
  );

  return (
    <div className="currency-container">
      {prefix && <span className="prefix-span">{prefix}</span>}
      <input {...props} onKeyUp={handleKeyUp} className={"currencyInput"} ref={ref} />
    </div>
  );
});

export default CurrencyInput;