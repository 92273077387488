import React, { useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import CountUp from 'react-countup'
import Select from 'react-select'
import { icons } from '../../assets/images'
import DashboardBankOptions from '../../components/DashboardBankOptions';
import DashboardFilterMobile from '../../components/DashboardFilterMobile'
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { getDate } from '../../helpers/dates'
import { getConsumersSelectOptions } from '../../helpers/getConsumersSelectOptions';
import { IDashboardFilterData } from '../../Interfaces/IDashboardFilterData'
import { IBanksData } from '../../Interfaces/IUserFormData';
import { canViewAllOptionsDashboard } from '../../policies/canViewAllOptionsDashboard'
import { dashboardService } from '../../services/dashboard'
import { userService } from '../../services/user';
import { DashboardOptions } from '../../types/dashboardOptions'
import './styles.css'
import ProductInfo from '../../components/ProductInfo';
import { useLocation } from 'react-router-dom';

const initialStates = {
  dashboardFilterData: {
    initialDate: getDate.startOfCurrentMonth(),
    finalDate: getDate.endOfCurrentMonth(),
    consumer: 0
  } as IDashboardFilterData
}

const Dashboard: React.FC = () => {
  const { setComponentUIState } = useContext(UIContext)
  const { token, userType, consumerList, userData, consumerData } = useContext(AuthContext);
  const location = useLocation();
  const dataState = location.state as any;

  const [productInfoModal, setProductInfoModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<IDashboardFilterData>(initialStates.dashboardFilterData)
  const [data, setData] = useState<Record<DashboardOptions, number>>({
    "pago": 0,
    "a pagar": 0,
    "recebido": 0,
    "a receber": 0,
    "entrada": 0,
    "saída": 0,
    "saldo": 0
  })
  const [selectedBank, setSelectedBank] = useState<{ label: string; value: number }>({ label: 'all', value: 0 });
  const [consumerBanks, setConsumerBanks] = useState<IBanksData[]>([]);


  useEffect(() => {
    const { isFirstAccess } = dataState || { isFirstAccess: false }
    if(isFirstAccess) {
      setProductInfoModal(true);
    }
  },[dataState])

  const optionsRule = canViewAllOptionsDashboard(userType)

  const showFilterBanks = filter.consumer && consumerBanks.length > 0 || userType === "Consumer";

  useEffect(() => {
    userType === "Consumer" && getData()
  }, [])

  useEffect(() => {
    if (userType !== "Consumer" && filter.consumer) {
      const consumerId = consumerList.find(consumer => consumer.user_id === filter.consumer);
      if (consumerId) getConsumerBanks(consumerId.id);
    }
  }, [userType, filter])

  function showToast(open: boolean, msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open, message: msg, type }
    })
  }

  const handleSelectBank = (data: { label: string; value: number }) => {
    setSelectedBank(data);
    getData(undefined, data.value);
  }

  const handleSelectBankMobile = (data: { label: string; value: number }) => {
    setSelectedBank(data);
  }

  const getData = async (filterData: IDashboardFilterData = {
    initialDate: filter.initialDate,
    finalDate: filter.finalDate,
    consumer: filter.consumer || userData.id
  }, bankId: number = selectedBank.value) => {
    const options: DashboardOptions[] = ["a pagar", "a receber", "pago", "recebido", "entrada", "saída", "saldo"];
    const { initialDate, finalDate, consumer } = filterData;

    const consumerId = consumer || userData.id;

    for (let option of options) {
      try {
        const { status, data: { amount } } = await dashboardService.filter({ initialDate, finalDate, consumer: consumerId }, bankId, option, token);

        if (status !== 200) {
          if (status === 500) {
            return showToast(true, `Falha não esperada..., status ${status}`, "error");
          }

          return showToast(true, "Cliente não encontrado...", "error")
        }

        if (status === 200) {
          setData(prev => ({
            ...prev,
            [option]: amount
          }))
        }
      } catch (error) {
        return showToast(true, `Falha não esperada...`, "error");
      }
    }

  }

  const handleChangeDates = (field: keyof IDashboardFilterData, event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [field]: event.target.value })
  }

  const handleChangeSelect = (ev: {
    value: number;
    label: string;
  } | null) => {
    if (ev) {
      setFilter({ ...filter, "consumer": ev.value })
    }
  }


  const handleFilter = (data: IDashboardFilterData = filter) => {
    data.consumer = data.consumer ? data.consumer : userData.id;
    setFilter(data);
    return getData(data)
  }

  const bankOptions = useMemo(() => {
    if (userType === "Consumer") {
      const banks = consumerData?.banks.map((bank) => ({ label: bank.accountName, value: bank.id })) || []
      return banks;
    }

    if (consumerBanks.length > 0) {
      const banks = consumerBanks.map((bank) => ({ label: bank.accountName, value: bank.id })) || []
      return banks;
    }

    return [];

  }, [consumerData, userType, consumerBanks])


  const getConsumerBanks = (consumerId: number) => {
    if (consumerId && userType !== "Consumer") {
      userService.getConsumerBanks(token, consumerId).then(({ data, status }) => {
        if (status !== 200) {
          return setComponentUIState({
            component: "Toast",
            componentState: { open: true, message: 'Falha para buscar bancos do cliente selecionado!', type: 'error' }
          })
        }


        return setConsumerBanks(data.banks);
      }).catch((err) => {
        setComponentUIState({
          component: "Toast",
          componentState: { open: true, message: 'Falha para buscar bancos do cliente selecionado!', type: 'error' }
        })
      })
    }
  }

  return (
    <>
      <div className="dashboard-container">
        <div className="dashboard-header">
          <div className='filter-header'>
            <div className="date-container" style={!optionsRule ? { width: '45%' } : {}}>
              <span>Data Inicial</span>
              <input
                type="date"
                name="initialDate"
                onChange={(ev) => handleChangeDates("initialDate", ev)}
                value={filter.initialDate}
              />
            </div>
            <div className="date-container" style={!optionsRule ? { width: '45%' } : {}}>
              <span>Data Final</span>
              <input
                type="date"
                name="finalDate"
                onChange={(ev) => handleChangeDates("finalDate", ev)}
                value={filter.finalDate}
              />
            </div>
            {optionsRule &&
              <>
                <div className="customer-container">
                  <span>Cliente</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o cliente"
                      options={getConsumersSelectOptions(consumerList)}
                      onChange={handleChangeSelect}
                    />
                  </div>
                </div>
              </>
            }
            <div className="search-container" role="search" onClick={() => handleFilter()}>
              <img src={icons.searchIcon} />
            </div>
          </div>
          {showFilterBanks && <div className="options-header">
            <span className="title">Conta Bancária</span>
            <DashboardBankOptions onSelect={handleSelectBank} selected={selectedBank} banks={bankOptions} />
          </div> || <></>}
        </div>

        <div className="dashboard-cards">
          <div className="card">
            <div className="card-image-container">
              <img src={icons.toReceive} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>A receber</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["a receber"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.toPay} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>A pagar</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["a pagar"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.toReceive} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>Recebido</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["recebido"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.toPay} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>Pago</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["pago"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.entranceMoneyIcon} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>Entrada</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["entrada"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.outMoneyIcon} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>Saída</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["saída"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-image-container">
              <img src={icons.saldoIcon} role="figure" />
            </div>
            <div className="card-content">
              <div className="title">
                <span>Saldo</span>
              </div>
              <div className="content">
                <CountUp
                  start={0}
                  end={data["saldo"]}
                  duration={1.5}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      <DashboardFilterMobile applyFilter={handleFilter} banks={bankOptions} onSelect={handleSelectBankMobile} selected={selectedBank} getBanks={(consumerId) => getConsumerBanks(consumerId)} />
      <ProductInfo open={productInfoModal} onClose={() => setProductInfoModal(false)} />
      <div
        className='info-button'
        onClick={() => setProductInfoModal(true)}
        title='Dúvidas sobre a plataforma'
      >
        <span>?</span>
      </div>
    </>
  )
}

export default Dashboard