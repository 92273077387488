import React, { useCallback, useContext, useRef, useState } from 'react';
import Select from 'react-select';
import { orderByHelper } from '../../helpers/orderByHelper';
import { commonService } from '../../services/common';
import { banksList } from '../../utils/contants/banksList';
import CurrencyInput from '../CurrencyInput';
import { FaTrash } from 'react-icons/fa';

import './styles.css';
import MoreBanksButton from './components/MoreBanksButton';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import UsersFormContext from '../../contexts/UsersFormContext';
import { IConsumerForm } from '../../Interfaces/IUserTypes';

interface IProps {
  action: "edit" | "add";
  data: IConsumerForm;
}

type BankTypeOptions = 'money' | 'bank';
type MoneyBanksData = { id: number; type: 'money', accountName: string; };
type BankBanksData = {
  type: 'bank';
  id: number;
  accountName: string;
  bankName: string;
  agency: string;
  account: string;
  initialDate: string;
}


type TFormFields = {
  firstName: {
    value: string;
    required: boolean;
  },
  lastName: {
    value: string;
    required: boolean;
  },
  email: {
    value: string;
    required: boolean;
  },
  frameBI: {
    value: string;
    required: boolean;
  },
  cpf: {
    value: string;
    required: boolean;
  },
  companyName: {
    value: string;
    required: boolean;
  },
  cnpj: {
    value: string;
    required: false
  },
  zipCode: {
    value: string;
    required: boolean;
  },
  street: {
    value: string;
    required: boolean;
  },
  city: {
    value: string;
    required: boolean;
  },
  state: {
    value: string;
    required: boolean;
  },
  neighborhood: {
    value: string;
    required: boolean;
  },
  numberAddress: {
    value: string;
    required: boolean;
  },
  telNumberPrimary: {
    value: string;
    required: boolean;
  },
  telNumberSecondary: {
    value: string;
    required: boolean;
  },
  plan_id: {
    value: string;
    required: boolean;
  },
  openingBalance: {
    value: number;
    required: boolean;
  },
}



const ConsumerForm: React.FC<IProps> = ({ action, data }) => {
  const { currencyRef, banksData, formData, setFormData, setBanksData, handleChangePassword, passwords, setDeleteBanks } = useContext(UsersFormContext);

  useEffect(() => {
    if (action === 'edit') {
      assignData()
    }
  }, [action])

  const assignData = () => {
    let cloneFormData = cloneDeep(formData);
    let { userData, userTypeData } = cloneFormData;

    userData.firstName.value = data.user.firstName;
    userData.email.value = data.user.email;
    userData.lastName.value = data.user.lastName || "";
    userData.frameBI.value = data.user.frameBI || "";
    if (currencyRef.current) currencyRef.current.value = data.user.openingBalance || 0 as any;

    userTypeData.plan_id.value = data.plan_id;
    userTypeData.companyName.value = data.companyName;
    userTypeData.cnpj.value = data.cnpj;
    userTypeData.cpf.value = data.cpf;
    userTypeData.zipCode.value = data?.zipCode?.toString() || "";
    userTypeData.street.value = data?.street|| "";
    userTypeData.city.value = data?.city|| "";
    userTypeData.state.value = data?.state|| "";
    userTypeData.neighborhood.value = data?.neighborhood|| "";
    userTypeData.numberAddress.value = String(data?.numberAddress)|| "";
    userTypeData.telNumberPrimary.value = data?.telNumberPrimary|| "";
    userTypeData.telNumberSecondary.value = data?.telNumberSecondary|| "";


    setFormData(cloneFormData);
    setBanksData(data.banks);
  }

  const handleBlurZipCode = async () => {
    const zipCode = String(formData.userTypeData.zipCode.value) || "";
    try {
      const { status, data: { logradouro, bairro, localidade, uf } } = await commonService.getAddress(zipCode);
      if (status === 200) {
        setFormData((prev) => ({
          ...prev,
          userTypeData: {
            ...prev.userTypeData,
            street: {
              ...prev.userTypeData.street,
              value: logradouro || ""
            },
            neighborhood: {
              ...prev.userTypeData.neighborhood,
              value: bairro || ""
            },
            city: {
              ...prev.userTypeData.city,
              value: localidade || ""
            },
            state: {
              ...prev.userTypeData.state,
              value: uf || ""
            }
          }
        }))
      }
    } catch (error) { }
  }

  const handleChangeBankType = (targetIndex: number, selectedType: BankTypeOptions) => {
    const copyArray = cloneDeep(banksData);
    copyArray[targetIndex].type = selectedType;
    setBanksData(copyArray);
  }

  const handleChangeBankData = (targetIndex: number, field: keyof Omit<BankBanksData, 'type' | 'id'>, newValue: string) => {
    const copyArray = [...banksData] as BankBanksData[];
    copyArray[targetIndex][field] = newValue;
    setBanksData(copyArray);
  }

  const handleAddNewBank = () => {
    const copyArray = cloneDeep(banksData);
    copyArray.push({ type: "money" } as MoneyBanksData);
    setBanksData(copyArray);
  }

  const handleDeleteBank = (targetIndex: number) => {
    const copyArray = cloneDeep(banksData);
    copyArray.splice(targetIndex, 1);
    setBanksData(copyArray);

    if (action === 'edit') {
      setDeleteBanks((prev) => ([...prev, banksData[targetIndex].id]));
    }
  }

  const handleChangeForm = (group: "userData" | "userTypeData", field: keyof TFormFields, newValue: string | number) => {
    setFormData((prev) => ({
      ...prev,
      [group]: {
        ...prev[group],
        [field]: {
          ...prev[group][field],
          value: newValue
        }
      }
    }))
  }

  const renderBankForm = useCallback(() => {
    return banksData.map((bankData, index) => {
      if (bankData.type === 'money') {
        return (
          <>
            <div className="generic-form">
              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Tipo de Conta</span>
                <div className="select-input">
                  <Select
                    placeholder="Selecione o tipo"
                    options={[
                      { value: 'bank', label: 'Banco' },
                      { value: 'money', label: 'Caixa' }
                    ]}
                    defaultValue={{ value: 'bank', label: 'Banco' }}
                    onChange={(e) => e && handleChangeBankType(index, e.value as BankTypeOptions)}
                    value={{ value: 'money', label: 'Caixa' }}
                  />
                </div>
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Nome da Conta Bancária</span>
                <input
                  placeholder="Digite o nome da conta"
                  className="text-input"
                  onChange={(e) => handleChangeBankData(index, 'accountName', e.target.value)}
                  value={bankData.accountName}
                />
              </div>
            </div>
            {
              index !== 0 &&
              <div>
                <FaTrash color={'red'} className="consumer-form-trash-icon" onClick={() => handleDeleteBank(index)} />
              </div>
            }
          </>
        )
      };

      const selectedBank = banksList.find(bank => bank.value === bankData.bankName);

      return (
        <>
          <div className="generic-form">
            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Tipo de Conta</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o tipo"
                  options={[
                    { value: 'bank', label: 'Banco' },
                    { value: 'money', label: 'Caixa' }
                  ]}
                  defaultValue={{ value: 'bank', label: 'Banco' }}
                  onChange={(e) => e && handleChangeBankType(index, e.value as BankTypeOptions)}
                  value={{ value: 'bank', label: 'Banco' }}
                />
              </div>
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Nome da Conta Bancária</span>
              <input
                placeholder="Digite o nome da conta"
                className="text-input"
                onChange={(e) => handleChangeBankData(index, 'accountName', e.target.value)}
                value={bankData.accountName}
              />
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Banco</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o banco"
                  options={orderByHelper<{ label: String; value: string }>(banksList, 'label')}
                  onChange={(e) => e && handleChangeBankData(index, 'bankName', e.value)}
                  value={bankData.bankName && selectedBank ? { value: selectedBank.label, label: selectedBank.label } : undefined}
                />
              </div>
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Agência</span>
              <input
                placeholder="Digite a agência"
                className="text-input"
                onChange={(e) => handleChangeBankData(index, 'agency', e.target.value)}
                value={bankData.agency}
              />
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Conta</span>
              <input
                placeholder="Digite a conta"
                className="text-input"
                onChange={(e) => handleChangeBankData(index, 'account', e.target.value)}
                value={bankData.account}
              />
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Data Inicial</span>
              <input
                type="date"
                className="input-date"
                onChange={(e) => handleChangeBankData(index, 'initialDate', e.target.value)}
                value={bankData.initialDate}
              />
            </div>

          </div>
          {
            index !== 0 &&
            <div>
              <FaTrash color={'red'} className="consumer-form-trash-icon" onClick={() => handleDeleteBank(index)} />
            </div>
          }
        </>
      )
    })
  }, [banksData])


  return (
    <div className={action === "edit" ? "form-container form-container-edit" : "form-container"}>
      <span className="genereic-form-title">Dados do Cliente/Empresa</span>
      <div className="generic-form">
        <div className="form-box">
          <span className="span-title">
            <span className="required">*</span>Nome
          </span>
          <input
            placeholder="Digite seu nome"
            className="text-input"
            onChange={(ev) => handleChangeForm('userData', 'firstName', ev.target.value)}
            value={formData.userData.firstName.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Sobrenome</span>
          <input
            placeholder="Digite seu sobrenome"
            className="text-input"
            onChange={(ev) => handleChangeForm('userData', 'lastName', ev.target.value)}
            value={formData.userData.lastName.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Email</span>
          <input
            placeholder="Digite seu email"
            className="text-input"
            disabled={action === "edit"}
            onChange={(ev) => handleChangeForm('userData', 'email', ev.target.value)}
            value={formData.userData.email.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>CPF</span>
          <input
            placeholder="Digite seu cpf"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'cpf', ev.target.value)}
            value={formData.userTypeData.cpf.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Plano</span>
          <div className="select-input">
            <Select
              placeholder="Selecione o plano"
              options={[
                { value: 1, label: 'Gratuito' },
                { value: 2, label: 'Pago' }
              ]}
              defaultValue={{ value: 1, label: 'Gratuito' }}
              onChange={(newValue) => newValue && handleChangeForm('userTypeData', 'plan_id', newValue.value)}
              value={formData.userTypeData.plan_id.value === 1 ? { value: 1, label: 'Gratuito' } : { value: 2, label: 'Pago' }}
            />
          </div>
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Nome da Empresa</span>
          <input
            placeholder="Digite o nome da empresa"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'companyName', ev.target.value)}
            value={formData.userTypeData.companyName.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title">CNPJ</span>
          <input
            placeholder="Digite o cnpj"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'cnpj', ev.target.value)}
            value={formData.userTypeData.cnpj.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>CEP</span>
          <input
            placeholder="Digite o cep"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'zipCode', ev.target.value)}
            value={formData.userTypeData.zipCode.value}
            onBlur={action === "edit" ? undefined : handleBlurZipCode}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Endereço</span>
          <input
            placeholder="Digite o endereço"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'street', ev.target.value)}
            value={formData.userTypeData.street.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Cidade</span>
          <input
            placeholder="Digite a cidade"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'city', ev.target.value)}
            value={formData.userTypeData.city.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Estado</span>
          <input
            placeholder="Digite o estado"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'state', ev.target.value)}
            value={formData.userTypeData.state.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Bairro</span>
          <input
            placeholder="Digite o bairro"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'neighborhood', ev.target.value)}
            value={formData.userTypeData.neighborhood.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Número</span>
          <input
            placeholder="Digite o número"
            type="number "
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'numberAddress', ev.target.value)}
            value={formData.userTypeData.numberAddress.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title"><span className="required">*</span>Telefone</span>
          <input
            placeholder="Digite o telefone"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'telNumberPrimary', ev.target.value)}
            value={formData.userTypeData.telNumberPrimary.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title">Telefone Secundário</span>
          <input
            placeholder="Digite o telefone secundario"
            className="text-input"
            onChange={(ev) => handleChangeForm('userTypeData', 'telNumberSecondary', ev.target.value)}
            value={formData.userTypeData.telNumberSecondary.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title">Power BI</span>
          <input
            placeholder="Insira a url"
            className="text-input"
            onChange={(ev) => handleChangeForm('userData', 'frameBI', ev.target.value)}
            value={formData.userData.frameBI.value}
          />
        </div>

        <div className="form-box">
          <span className="span-title">Saldo inicial</span>
          <CurrencyInput
            name="price"
            mask="currency"
            prefix="R$"
            placeholder="0,00"
            ref={currencyRef}
          />
        </div>
      </div>

      <span className="genereic-form-title">Dados Bancários</span>
      {renderBankForm()}

      <div className="generic-form">
        <MoreBanksButton onPress={handleAddNewBank} />
      </div>

      {action === "add" &&
        <>
          <span className="genereic-form-title">Configuração da Conta</span>
          <div className="generic-form">
            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Senha</span>
              <input
                placeholder="Digite a senha"
                className="text-input"
                type="password"
                value={passwords.password}
                onChange={(e) => handleChangePassword('password', e.target.value)}
              />
            </div>

            <div className="form-box">
              <span className="span-title"><span className="required">*</span>Confirmar Senha</span>
              <input
                placeholder="Confirme a senha"
                className="text-input"
                type="password"
                value={passwords.confirmation}
                onChange={(e) => handleChangePassword('confirmation', e.target.value)}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default ConsumerForm;