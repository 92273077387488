import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-drag";
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { IParameters } from '../../Interfaces/IParameters';
import { parameterService } from '../../services/parameter';

import './styles.css';

interface IProps {
  open: boolean;
  onClose(): void;
  onRefresh(): void;
  data: IParameters[];
}

interface IModalProps {
  close(): void;
  onSave(newPosition: number): void
}

const OrderParameters: React.FC<IProps> = ({ open, onClose, onRefresh, data }) => {
  const [items, setItems] = useState<IParameters[]>(data);
  const [openManualOrder, setOpenManualOrder] = useState<{ open: boolean; currentIndex: number }>({ open: false, currentIndex: 0 });

  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  useEffect(() => {
    if (open) setItems(data)
  }, [open])

  const onChange = (sourceId: string, sourceIndex: number, targetIndex: number, targetId: string | undefined) => {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleOpenManualOrderModal = (index: number) => {
    setOpenManualOrder({ open: true, currentIndex: index })
  };

  const handleCloseManualOrderModal = () => setOpenManualOrder({ ...openManualOrder, open: false });

  function changeOrders(items: IParameters[]): IParameters[] {
    const newArray: IParameters[] = []
    for (let i in items) {
      const index = Number(i);
      items[index].order = index;
      newArray.push(items[index])
    }

    return newArray;
  }

  const handleSave = async () => {
    const parameters = changeOrders(items);
    try {
      const { status } = await parameterService.order(token, parameters);
      if (status === 200) {
        onRefresh()
        onClose()
        showToast("Ordenado com sucesso!", "success")
      } else {
        throw `Falha para ordenar, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error");
    }
  }

  return (
    <div className={open ? "order-page" : "close-order-page"}>
      <div className="bg-order-container" onClick={onClose}></div>
      <div className="order-container">
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={1}
            rowHeight={40}
            className="grid-zone"
          >
            {items.map((item, index) => (

              <GridItem key={item.id} className="grid-item-card" onDoubleClick={() => handleOpenManualOrderModal(index)}>
                <div className="grid-item-container">
                  {item.invoice}
                </div>
              </GridItem>

            ))}
          </GridDropZone>
        </GridContextProvider>
        <div className="footer">
          <button className="cancel-btn" onClick={onClose}>Cancelar</button>
          <button className="save-btn" onClick={handleSave}>Salvar</button>
        </div>
      </div>

      {
        openManualOrder.open &&
        <DoubleClickModal
          close={handleCloseManualOrderModal}
          onSave={(newPosition) => { onChange('', openManualOrder.currentIndex, newPosition, '') }}
        />
      }
    </div>
  );
}

const DoubleClickModal: React.FC<IModalProps> = ({ onSave, close }) => {

  const [orderNumber, setOrderNumber] = useState<number>(1);

  return (
    <div className='doubleClick-modal'>
      <span className='title'>Escolha a nova posição: </span>
      <input
        type={'number'}
        value={orderNumber}
        min={1}
        onChange={(e) => setOrderNumber(Number(e.target.value))}
      />
      <div>
        <button className='cancel' onClick={close}>Cancelar</button>
        <button className='save' onClick={() => {
          onSave(orderNumber - 1)
          close()
        }}>
          Salvar
        </button>
      </div>
    </div>
  )
}

export default OrderParameters;
