import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../@types/userRole';
import { icons } from '../../assets/images';
import { IUserFormLocationState } from '../../Interfaces/IUserFormLocationState';
import Select from 'react-select'
import CurrencyInput from '../../components/CurrencyInput';
import { IAdministrator, IAnalyst, IConsumer, IConsumerForm, IUser } from '../../Interfaces/IUserTypes';
import { useRef } from 'react';
import { userService } from '../../services/user';
import { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { IEditUser } from '../../Interfaces/IUserFormSave';
import { commonService } from '../../services/common';
import UIContext from '../../contexts/UIContext';
import Swal from 'sweetalert2';
import { getUserTypeFromRole } from '../../helpers/getUserTypeFromRole';
import { banksList } from '../../utils/contants/banksList';
import './styles.css';
import { orderByHelper } from '../../helpers/orderByHelper';
import ConsumerForm from '../../components/ConsumerForm';
import UsersFormContext from '../../contexts/UsersFormContext';

type ErrorMessage = {
  message: string;
}

type ConsumerFormData = {
  userData: {
    [field: string]: {
      required: boolean;
      value: string | number;
    }
  },

  userTypeData: {
    [field: string]: {
      required: boolean;
      value: string | number;
    }
  }
}

type MoneyBanksData = { type: 'money', accountName: string; };
type BankBanksData = {
  type: 'bank';
  id: number;
  accountName: string;
  bankName: string;
  agency: string;
  account: string;
  initialDate: string;
}


type BanksData = MoneyBanksData | BankBanksData;

const UserForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);
  const userFormContextData = useContext(UsersFormContext);

  const [selectedType, setSelectedType] = useState<UserRole>("Consumer")
  const [password, setPassword] = useState<string>("");
  const [confirmPassowrd, setConfirmPassword] = useState<string>("");

  const { action, data, userType, userId } = location.state as IUserFormLocationState<any>;


  useEffect(() => {
    toAssignData();
  }, [selectedType])

  const formData = {
    userData: {
      firstName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      lastName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      email: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      frameBI: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },

    },

    userTypeData: {
      cpf: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      companyName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      cnpj: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },
      zipCode: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      street: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      city: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      state: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      neighborhood: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      numberAddress: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      telNumberPrimary: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      telNumberSecondary: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },
      plan_id: {
        value: useRef<any>(null),
        type: 'select-element',
        required: true
      },
    },

    banksData: {
      id: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false,
      },
      accountName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      bankName: {
        value: useRef<any>(null),
        type: 'select-element',
        required: true
      },
      agency: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      account: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      initialDate: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      openingBalance: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },
    }

  }



  const toAssignData = () => {
    if (action === "add") {
      createRefValues();
    };

    if (action === "edit") {
      switch (userType) {
        case "Consumer": return assignDataToConsumer(data);
        case "Analyst": return assignDataToAnalyst(data);
        case "Administrator": return assignDataToAdministrator(data);
        default: return null;
      }
    }
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  function createRefValues() {
    Object.entries(formData).map(([property, data]) => {
      Object.entries(data).map(([prop, row]) => {
        if (row.type === "input-element") {
          if (row.value.current) row.value.current.value = "";
        } else {
          if (row.value.current) row.value.current.getValue()[0] = { value: "", label: "" }
        }
      })
    })
  }

  async function assignDataToConsumer(data: IConsumerForm) {
    return;
  }

  async function assignDataToAnalyst(data: IAnalyst) {
    const { firstName, email, lastName, frameBI } = formData.userData;
    if (firstName.value.current) firstName.value.current.value = data.user.firstName;
    if (email.value.current) email.value.current.value = data.user.email;
    if (lastName.value.current) lastName.value.current.value = data.user.lastName || "";
    if (frameBI.value.current) frameBI.value.current.value = data.user.frameBI || "";

    const { cpf } = formData.userTypeData;
    if (cpf.value.current) cpf.value.current.value = data.cpf;
  }

  async function assignDataToAdministrator(data: IAdministrator) {
    const { firstName, email, lastName, frameBI } = formData.userData;
    if (firstName.value.current) firstName.value.current.value = data.user.firstName;
    if (email.value.current) email.value.current.value = data.user.email;
    if (lastName.value.current) lastName.value.current.value = data.user.lastName || "";
    if (frameBI.value.current) frameBI.value.current.value = data.user.frameBI || "";

    const { cpf } = formData.userTypeData;
    if (cpf.value.current) cpf.value.current.value = data.cpf;
  }

  const handleBlurZipCode = async () => {
    const zipCode = String(formData.userTypeData.zipCode.value?.current?.value) || "";
    try {
      const { status, data: { logradouro, bairro, localidade, uf } } = await commonService.getAddress(zipCode);
      if (status === 200) {
        const { street, neighborhood, city, state } = formData.userTypeData;
        if (street.value.current) street.value.current.value = logradouro || "";
        if (neighborhood.value.current) neighborhood.value.current.value = bairro || "";
        if (city.value.current) city.value.current.value = localidade || "";
        if (state.value.current) state.value.current.value = uf || "";
      }
    } catch (error) { }
  }

  function defineMapData() {
    if (userType === "Administrator" || selectedType === "Administrator") {
      const mapData = {
        userData: formData.userData,
        userTypeData: {
          cpf: formData.userTypeData.cpf
        }
      }
      return mapData
    }

    if (userType === "Analyst" || selectedType === "Analyst") {
      const mapData = {
        userData: formData.userData,
        userTypeData: {
          cpf: formData.userTypeData.cpf
        }
      }
      return mapData
    }

    else {
      return formData;
    }
  }

  function getFormData(): { data: IEditUser; validation: boolean; fieldFailValidation: string } {
    let formDataValues = {}
    let validation = true;
    let fieldFailValidation = "";
    const mapData = defineMapData()
    Object.entries(mapData).map(([property, data]) => {
      let tempObject = {}
      Object.entries(data).map(([prop, row]) => {
        if (row.required) {
          //@ts-ignore
          const fieldValue = row.type === "input-element" ? row.value?.current?.value || "" : row.value?.current?.getValue()[0].value || "";
          if (!fieldValue || fieldValue === "") {
            validation = false;
            fieldFailValidation = prop;
          }
        }

        if (row.type === "input-element") {
          row.value.current && Object.assign(tempObject, { [prop]: row.value.current.value })
        } else {
          //@ts-ignore
          row.value.current && Object.assign(tempObject, { [prop]: row.value.current.getValue()[0].value })
        }
      })
      Object.assign(formDataValues, { [property]: { ...tempObject } })
      tempObject = {};
    })

    return { data: formDataValues as IEditUser, validation, fieldFailValidation };
  }

  function passwordValidation(password: string, confirmPassowrd: string): { valid: boolean, msg: string } {
    if (password === "") {
      return { valid: false, msg: "Campo senha deve ser preenchido." }
    }

    if (confirmPassowrd === "") {
      return { valid: false, msg: "Campo de confirmação de senha deve ser preenchido." }
    }

    if (password !== confirmPassowrd) {
      return { valid: false, msg: "As senhas não conferem." }
    }

    return { valid: true, msg: "" }
  }

  const handleConfirm = (): Promise<void> => {
    switch (action) {
      case "add": return save();
      case "edit": return edit();
    }
  }

  // REFATORAR FUTURAMENTE FORMULARIO PARA ADMIN / ANALISTA, BEM COMO EDIÇÃO E SALVAMENTO
  const newSave = async () => {


    const { banksData, currencyRef, formData, passwords } = userFormContextData;

    const isValidFormData = validateFormData(formData);
    if (!isValidFormData) return;

    const isValidBanksData = validateBanksData(banksData);
    if (!isValidBanksData) return;

    const passwdValid = passwordValidation(passwords.password, passwords.confirmation);
    if (!passwdValid.valid) {
      Swal.fire(
        'Opss...',
        `${passwdValid.msg}`,
        'warning'
      )
      return;
    }

    const params = { userData: { ...isValidFormData.userData, password: passwords.password, user_type: getUserTypeFromRole(selectedType), openingBalance: currencyRef?.current?.value || 0 }, userDataType: isValidFormData.userTypeData, userType: selectedType, bankData: banksData }

    try {
      const { status, data } = await userService.create(token, { ...params });

      if (status === 201) {
        showToast(`Usuário criado com sucesso!`, "success");
        return navigate("/users");
      }

      if (status === 422) {
        throw data.msg || `Falha ao criar usuário, status code: ${status}`;
      }

      throw `Falha ao criar usuário, status code: ${status}`


    } catch (error) {
      showToast(`${error}`, "error")
    }

  }

  const newEdit = async () => {
    const { banksData, currencyRef, formData, deleteBanks } = userFormContextData;

    const isValidFormData = validateFormData(formData);
    if (!isValidFormData) return;

    const isValidBanksData = validateBanksData(banksData);
    if (!isValidBanksData) return;

    const params = {
      id: userId,
      bankData: banksData,
      deleteBanks,
      isAdmin: data.user.user_type === 1,
      userObject: {
        ...isValidFormData.userTypeData,
        user: { ...isValidFormData.userData, openingBalance: currencyRef?.current?.value }
      },
      userType: data.user.user_type
    };

    try {
      const { status } = await userService.update(token, { ...params });


      if (status === 200) {
        showToast(`Usuário alterado com sucesso!`, "success");
        navigate("/users");
      } else {
        throw `Falha ao atualizar usuário, status code: ${status}`
      }

    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  const validateFormData = (formData: ConsumerFormData) => {
    try {
      Object.keys(formData.userData).map((key) => {
        if (formData.userData[key].required) {
          if (!!!formData.userData[key].value) throw new Error(`Campos obrigatórios não preenchidos!`)
        }
      })

      Object.keys(formData.userTypeData).map((key) => {
        if (formData.userTypeData[key].required) {
          if (!!!formData.userTypeData[key].value) throw new Error(`Campos obrigatórios não preenchidos!`)
        }
      })

      const userData = Object.entries(formData.userData).reduce((prev, curr) => prev = Object.assign(prev, { [curr[0]]: curr[1].value }), {}) as IUser;
      const userTypeData = Object.entries(formData.userTypeData).reduce((prev, curr) => prev = Object.assign(prev, { [curr[0]]: curr[1].value }), {}) as IAdministrator | IAnalyst | IConsumer;

      return { userData, userTypeData };
    } catch (error) {
      //@ts-ignore
      showToast(`${error.message}`, "warning");
      return false
    }
  }

  const validateBanksData = (banksData: BanksData[]) => {
    try {
      for (const bank of banksData) {
        if (bank.type === 'money') {
          if (!!!bank.accountName) throw new Error(`Nome da conta é obrigatório`);
        }

        if (bank.type === 'bank') {
          Object.entries(bank).map(([prop, value]) => {
            if (prop === 'id') return;

            if (!!!value) {
              throw new Error(`Todos os campos dos dados bancários são obrigatórios!`);
            }
          })
        }
      }

      return true
    } catch (error) {
      //@ts-ignore
      showToast(`${error.message}`, "warning");
      return false
    }
  }

  async function save() {
    if (selectedType === "Consumer") return newSave();

    try {
      const { data: formDataValues, validation, fieldFailValidation } = getFormData();
      if (!validation) {
        Swal.fire(
          'Opss...',
          // `O campo ${fieldFailValidation} é obrigatório.`,
          `Existe campo obrigatório não preenchido.`,
          'warning'
        )
        return;
      }

      const passwdValid = passwordValidation(password, confirmPassowrd);
      if (!passwdValid.valid) {
        Swal.fire(
          'Opss...',
          `${passwdValid.msg}`,
          'warning'
        )
        return;
      }

      const { banksData, userData, userTypeData } = formDataValues as IEditUser;
      // if (selectedType === "Consumer") bankData.push(banksData);
      const { status, data } = await userService.create(token, { userData: { ...userData, password: password, user_type: getUserTypeFromRole(selectedType) }, userDataType: userTypeData, userType: selectedType, bankData: [] })

      if (status === 201) {
        showToast(`Usuário criado com sucesso!`, "success");
        return navigate("/users");
      }

      if (status === 422) {
        throw data.msg || `Falha ao criar usuário, status code: ${status}`;
      }

      throw `Falha ao criar usuário, status code: ${status}`


    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  async function edit() {
    if (data.user.user_type == 3) return newEdit();

    try {
      const { data: formDataValues, validation, fieldFailValidation } = getFormData();
      if (!validation) {
        Swal.fire(
          'Opss...',
          `O campo ${fieldFailValidation} é obrigatório.`,
          'warning'
        )

        return;
      }

      const { banksData, userData, userTypeData } = formDataValues as IEditUser;
      let bankData = [];
      // if (data.user.user_type === 3) bankData.push(banksData);
      const { status } = await userService.update(token, { id: userId, bankData: [], userObject: { ...userTypeData, user: { ...userData, frameBI: userData.frameBI } }, userType: data.user.user_type, isAdmin: data.user.user_type === 1 })

      if (status === 200) {
        showToast(`Usuário alterado com sucesso!`, "success");
        navigate("/users");
      } else {
        throw `Falha ao atualizar usuário, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  const handleChangeSelected = (type: UserRole) => {
    setSelectedType(type)
  }

  const isConsumerView = () => {
    if (action === "add") {
      return selectedType === "Consumer";
    }

    return userType === "Consumer";
  }

  const handleBack = () => {
    navigate("/users")
  }

  return (
    <div className="user-form-container">
      <div className="box-container">
        <div className="back-container">
          <span className="back-button" onClick={handleBack} >
            <AiOutlineArrowLeft size={20} color="#ffffffe6" />
            <span>Voltar</span>
          </span>
        </div>
        {action === "add" && <div className="header-container">
          <div
            className={selectedType === "Administrator" ? `user-type-box selected` : "user-type-box"}
            onClick={() => handleChangeSelected("Administrator")}
          >
            <img src={icons.administratorIcon} alt="administrator-icon" />
            <span>Administrador</span>
          </div>
          <div
            className={selectedType === "Analyst" ? `user-type-box selected` : "user-type-box"}
            onClick={() => handleChangeSelected("Analyst")}
          >
            <img src={icons.analystIcon} alt="analyst-icon" />
            <span>Analista</span>
          </div>
          <div
            className={selectedType === "Consumer" ? `user-type-box selected` : "user-type-box"}
            onClick={() => handleChangeSelected("Consumer")}
          >
            <img src={icons.consumerIcon} alt="consumer-icon" />
            <span>Cliente</span>
          </div>
        </div>}


        {
          isConsumerView() ?
            <ConsumerForm action={action} data={data} /> :
            <div className={action === "edit" ? "form-container form-container-edit" : "form-container"}>
              <div className="form-box">
                <span className="span-title">
                  <span className="required">*</span>Nome
                </span>
                <input
                  placeholder="Digite seu nome"
                  className="text-input"
                  ref={formData.userData.firstName.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Sobrenome</span>
                <input
                  placeholder="Digite seu sobrenome"
                  className="text-input"
                  ref={formData.userData.lastName.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Email</span>
                <input
                  placeholder="Digite seu email"
                  className="text-input"
                  ref={formData.userData.email.value}
                  disabled={action === "edit"}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>CPF</span>
                <input
                  placeholder="Digite seu cpf"
                  className="text-input"
                  ref={formData.userTypeData.cpf.value}
                />
              </div>

              {isConsumerView() && <>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Plano</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o plano"
                      options={[
                        { value: 1, label: 'Gratuito' },
                        { value: 2, label: 'Pago' }
                      ]}
                      defaultValue={{ value: 1, label: 'Gratuito' }}
                      ref={formData.userTypeData.plan_id.value}
                    />
                  </div>
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Nome da Empresa</span>
                  <input
                    placeholder="Digite o nome da empresa"
                    className="text-input"
                    ref={formData.userTypeData.companyName.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title">CNPJ</span>
                  <input
                    placeholder="Digite o cnpj"
                    className="text-input"
                    ref={formData.userTypeData.cnpj.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>CEP</span>
                  <input
                    placeholder="Digite o cep"
                    className="text-input"
                    type="number"
                    ref={formData.userTypeData.zipCode.value}
                    onBlur={action === "edit" ? undefined : handleBlurZipCode}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Endereço</span>
                  <input
                    placeholder="Digite o endereço"
                    className="text-input"
                    ref={formData.userTypeData.street.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Cidade</span>
                  <input
                    placeholder="Digite a cidade"
                    className="text-input"
                    ref={formData.userTypeData.city.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Estado</span>
                  <input
                    placeholder="Digite o estado"
                    className="text-input"
                    ref={formData.userTypeData.state.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Bairro</span>
                  <input
                    placeholder="Digite o bairro"
                    className="text-input"
                    ref={formData.userTypeData.neighborhood.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Número</span>
                  <input
                    placeholder="Digite o número"
                    className="text-input"
                    ref={formData.userTypeData.numberAddress.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Telefone</span>
                  <input
                    placeholder="Digite o telefone"
                    className="text-input"
                    ref={formData.userTypeData.telNumberPrimary.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title">Telefone Secundário</span>
                  <input
                    placeholder="Digite o telefone secundario"
                    className="text-input"
                    ref={formData.userTypeData.telNumberSecondary.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Nome da Conta Bancária</span>
                  <input
                    placeholder="Digite o nome da conta"
                    className="text-input"
                    ref={formData.banksData.accountName.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Banco</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o banco"
                      options={orderByHelper<{ label: String; value: string }>(banksList, 'label')}
                      defaultValue={{ value: 'Banco do Brasil', label: 'Banco do Brasil' }}
                      ref={formData.banksData.bankName.value}
                    />
                  </div>
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Agência</span>
                  <input
                    placeholder="Digite a agência"
                    className="text-input"
                    ref={formData.banksData.agency.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Conta</span>
                  <input
                    placeholder="Digite a conta"
                    className="text-input"
                    ref={formData.banksData.account.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title"><span className="required">*</span>Data Inicial</span>
                  <input
                    type="date"
                    className="input-date"
                    ref={formData.banksData.initialDate.value}
                  />
                </div>

                <div className="form-box">
                  <span className="span-title">Saldo inicial</span>
                  <CurrencyInput
                    name="price"
                    mask="currency"
                    prefix="R$"
                    placeholder="0,00"
                    ref={formData.banksData.openingBalance.value}
                  />
                </div>

                <div style={{ display: 'none' }}>
                  <span className="span-title">BankID</span>
                  <input
                    ref={formData.banksData.id.value}
                  />
                </div>
              </>
              }
              <div className="form-box">
                <span className="span-title">Power BI</span>
                <input
                  placeholder="Insira a url"
                  className="text-input"
                  ref={formData.userData.frameBI.value}
                />
              </div>


              {action !== "edit" &&
                <>
                  <div className="form-box">
                    <span className="span-title"><span className="required">*</span>Senha</span>
                    <input
                      placeholder="Digite a senha"
                      className="text-input"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="form-box">
                    <span className="span-title"><span className="required">*</span>Confirmar Senha</span>
                    <input
                      placeholder="Confirme a senha"
                      className="text-input"
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </>
              }

            </div>
        }

        <div className="footer">
          <button className="cancel-btn" onClick={handleBack}>Cancelar</button>
          <button className="save-btn" onClick={handleConfirm}>{action === "add" ? "Salvar" : "Alterar"}</button>
        </div>
      </div>
    </div>
  );
}

export default UserForm;