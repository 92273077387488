export const Months = [
  {label: "Janeiro", value: "Janeiro", number: 1},
  {label: "Fevereiro", value: "Fevereiro", number: 2},
  {label: "Março", value: "Março", number: 3},
  {label: "Abril", value: "Abril", number: 4},
  {label: "Maio", value: "Maio", number: 5},
  {label: "Junho", value: "Junho", number: 6},
  {label: "Julho", value: "Julho", number: 7},
  {label: "Agosto", value: "Agosto", number: 8},
  {label: "Setembro", value: "Setembro", number: 9},
  {label: "Outubro", value: "Outubro", number: 10},
  {label: "Novembro", value: "Novembro", number: 11},
  {label: "Dezembro", value: "Dezembro", number: 12}
];