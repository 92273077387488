import React, { createContext } from 'react';
import { useState } from 'react';
import { IDREContext } from '../Interfaces/IDREContext';
import { IResumeContext } from '../Interfaces/IResumeContext';
import { IResumeData } from '../Interfaces/IResumeData';
import DRE from '../pages/DRE';
import Resume from '../pages/Resume';
import { Months } from '../utils/contants/months';

interface IDreFilterData {
  month?: string;
  year?: string;
  consumer?: number;
}

const getSelectCurrentMonth = (): {
  label: string;
  value: string;
  number: number;
} => {
  const date = new Date();
  const currentMonthNumber = date.getMonth() + 1;;
  const month = Months.find(month => month.number === currentMonthNumber);
  if (month) return month;

  return { label: '', value: '', number: 0 }
}

const getCurrentYear = (): { label: string; value: number } => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return { label: String(currentYear), value: currentYear };
}

const initialStates = {
  dreFilterData: {
    month: getSelectCurrentMonth().value,
    year: String(getCurrentYear().value),
    consumer: undefined
  } as IDreFilterData
}

export const DREContext = createContext<IDREContext>({} as IDREContext);


const DREContextProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IDreFilterData>(initialStates.dreFilterData);

  return (
    <DREContext.Provider value={{ filter, setFilter }} >
      <DRE />
    </DREContext.Provider>
  );
}

export default DREContextProvider;