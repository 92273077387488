import React, { useState } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { parameterService } from '../../services/parameter';
import Select from 'react-select';

import './styles.css';
import { dreList } from '../../utils/contants/dreList';

const ParameterForm: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [classification, setClassification] = useState<string>("");
  const [dreClassification, setDREClassification] = useState<string>("");

  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);
  const navigate = useNavigate();
  
  const handleCancel = () => {
    navigate("/parameters")
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleSave = async () => {
    try {
      const invoice = inputRef?.current?.value || "";
      if (!!!classification) return showToast("Campo Classificação é obrigatório!", "error");
      if (!!!dreClassification) return showToast("Campo DRE é obrigatório!", "error");
      const { status } = await parameterService.create(token, { invoice, classification, dreClassification });
      if(status === 200 || status === 201) {
        showToast("Parâmetro criado com sucesso!", "success");
        navigate("/parameters");
      } else {
        throw `Falha ao criar parâmetro, status code: ${status}`;
      }
    } catch (error) {
      showToast(`${error}`, "error");
    }
  }

  return (
    <div className="parameter-form-container">
      <div className="box-container">
        <div className="back-container">
          <span className="back-button" onClick={handleCancel}>
            <AiOutlineArrowLeft size={20} color="#ffffffe6" />
            <span>Voltar</span>
          </span>
        </div>

        <div className="form-container">
          <div className="content">
            <span>Conta</span>
            <input placeholder="Digite o nome da conta" ref={inputRef} />
          </div>
          <div className="content2">
          <span>Classificação</span>
          <div className="select-input">
            <Select
              placeholder="Selecione a classificação"
              options={[
                { value: 'entrada', label: 'entrada' },
                { value: 'saída', label: 'saída' }
              ]}
              onChange={(e) => setClassification(e?.value || "")}
            />
          </div>
        </div>
        <div className="content2">
          <span>DRE</span>
          <div className="select-input">
            <Select
              placeholder="Selecione o tipo da DRE"
              options={dreList}
              onChange={(e) => setDREClassification(e?.value || "")}
            />
          </div>
        </div>
          <div className="footer">
            <button className="cancel-btn" onClick={handleCancel}>Cancelar</button>
            <button className="save-btn" onClick={handleSave}>Salvar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParameterForm;