import React from 'react';
import { PageRoutes } from './routes';
import './assets/style/global.css';

function App() {
  return (
    <PageRoutes />
  );
}

export default App;
