export function getYears(): { label: number, value: number }[] {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + (year - 20));

  let yearsListSelect: { label: number, value: number }[] = [];

  for(const year of years){
    yearsListSelect.push({ label: year, value: year })
  }

  return yearsListSelect;
}


export function newGetYears(): { label: string, value: number }[] {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + (year - 20));

  let yearsListSelect: { label: string, value: number }[] = [];

  for(const year of years){
    yearsListSelect.push({ label: String(year), value: year })
  }

  return yearsListSelect;
}
