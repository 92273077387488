import React from 'react';
import packageInfo from '../../../package.json'

import './styles.css';

const Version: React.FC = () => {
  return <div className="version-container">
    <span> v{packageInfo.version}</span>
  </div>;
}

export default Version;