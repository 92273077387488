import React from 'react';
import { AuthContextProvider } from './AuthContext';
import { UIContextProvider } from './UIContext';

const ContextProvider: React.FC = ({ children }) => {
  return (
    <UIContextProvider>
      <AuthContextProvider>
        {children}
      </AuthContextProvider>
    </UIContextProvider>

  )
}

export default ContextProvider;