import React, { useContext, useEffect, useMemo, useState } from 'react';
import { subDays, isToday, isAfter, parse, isWithinInterval } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import './styles.css';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { THistoric, currentDataOutput, paymentsService } from '../../services/payments';
import AuthContext from '../../contexts/AuthContext';
import BackButton from '../../components/@DS/BackButton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router-dom';
import Button from '../../components/@DS/Button';

type TPaymentsProfileState = {
  customerId: number;
}

const Payments: React.FC = () => {
  const { token, userData, userType } = useContext(AuthContext);
  const location = useLocation();

  const [historicData, setHistoricData] = useState<THistoric[]>([]);
  const [currentSituation, setCurrentSituation] = useState<currentDataOutput>({
    dueDate: "",
    paymentMethod: "",
    status: ""
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentBtnLoading, setPaymentBtnLoading] = useState<boolean>(false);

  const locationState = location?.state as TPaymentsProfileState;


  const historic = useMemo(() => historicData, [historicData]);
  const backPath = useMemo(() => {
    const isAdminUser = userType === "Administrator" || userType === "Analyst";
    if (isAdminUser) return '/users';
    return '/profile';
  }, [userType])

  const getPayments = async () => {
    setLoading(true);
    let userId = userData.id;

    if (userType === "Administrator" || userType === "Analyst") {
      userId = locationState?.customerId;
    }

    try {
      const { status, data: { data: { current, historic } } } = await paymentsService.getCustomerPayments(token, userId);

      if (status === 200) {
        setHistoricData(historic);
        setCurrentSituation(current);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const generateLink = async () => {
    setPaymentBtnLoading(true);

    let userId = userData.id;

    if (userType === "Administrator" || userType === "Analyst") {
      userId = locationState?.customerId;
    }

    try {
      const { status, data: { data: { url } } } = await paymentsService.generatePaymentLink(userId, true);

      if (status === 200) {
        window.open(url);
      }
      setPaymentBtnLoading(false);
    } catch (error) {
      setPaymentBtnLoading(false);
    }
  }

  const statusColor = (status: string) => {
    if (!status) return "";

    if (currentSituation?.status.match(/Ativo/)) return "green";
    if (currentSituation?.status.match(/Inativo/)) return "red";
    return ""
  }

  function isApproachingDueDate(dueDate?: string) {
    if (!dueDate) return false;
    const parsedDate = parse(dueDate, 'dd/MM/yyyy', new Date(), { locale: ptBR });
    const targetDate = subDays(parsedDate, 5);

    return isWithinInterval(new Date(), { start: targetDate, end: parsedDate }) || isToday(parsedDate);
  }

  useEffect(() => {
    getPayments();
  }, [])

  return (
    <>
      {
        loading ?
          <>
            <Skeleton className='payments-loading' baseColor="#ececec" highlightColor="#f5f5f5" />
          </> :
          <div className='payments-form-container'>
            <BackButton path={backPath} />
            <div className='payments-header-container'>
              <div className='item'>
                <span className='title'>Status do Plano:</span>
                <span className={`content ${statusColor(currentSituation?.status)}`}>{currentSituation?.status || "-"}</span>
              </div>
              <div className='item'>
                <span className='title'>Vencimento:</span>
                <span className='content'>{currentSituation?.dueDate || "-"}</span>
              </div>
              <div className='item'>
                <span className='title'>Forma de Pagamento:</span>
                <span className='content'>{currentSituation?.paymentMethod || "-"}</span>
              </div>

              {isApproachingDueDate(currentSituation?.dueDate) &&
                <div className='item'>
                  <Button text='Pagar próximo mês' isLoading={paymentBtnLoading} onPress={generateLink} />
                </div>
              }
            </div>


            <div className='payments-table-container'>
              <span className='title'>Histórico Financeiro</span>
              <div className='table-container'>
                <Table key={'payments-table'}>
                  <Thead key="thead-cashflow">
                    <Tr>
                      <Th>Status do Pagamento</Th>
                      <Th>Metodo de Pagamento</Th>
                      <Th>Inicio do Periodo de Testes</Th>
                      <Th>Data do Registro</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {historic.map((data, index) => <Tr key={index}>
                      <Td>{data?.paymentStatus || "-"}</Td>
                      <Td>{data?.paymentMethod || "-"}</Td>
                      <Td>{data?.trialStartDate || "-"}</Td>
                      <Td>{data?.created_at || "-"}</Td>
                    </Tr>)}
                  </Tbody>
                </Table>
              </div>
            </div>

          </div>
      }
    </>
  )
}

export default Payments;