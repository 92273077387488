import React from 'react'
import { useEffect } from 'react'
import { icons } from '../../assets/images'
import { toastColors } from '../../helpers/toastColors'
import { toastIcons } from '../../helpers/toastIcons'

import './styles.css'

interface IProps {
  open: boolean
  message?: string
  duration?: number
  onClose(): void
  type: "info" | "warning" | "error" | "success"
}

const Toast: React.FC<IProps> = ({ open, onClose, duration, type, message }) => {

  useEffect(() => {
    if(open){
      setTimeout(() => onClose(), duration || 3000)
    }
  },[open])

  return (
    <>
      <div id={open ? "toast-container" : "toast-container-hidden"} style={{ background: toastColors(type).bg }} role="toast">
        <img src={icons.closeIcon} className="close-img" onClick={onClose} />
        <div className="toast-content">
          <div className="icon-container">
            <img src={toastIcons(type)} />
          </div>
          <div className="description-container">
            <span style={{ color: toastColors(type).color }}>{message || ""}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Toast