export function currencyFormat(number: number) {
  const currencyNumber = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "BRL",
  })
    .format(number)
    .replace("R$", "");

  return currencyNumber;
}
