import Logo_Blue_login from './login-logo.png'
import Login_BG from './loginbg.jpeg';
import SuccessIcon from './success-icon.png'
import ErrorIcon from './error-icon.png'
import InfoIcon from './info-icon.png'
import WarningIcon from './warning-icon.png'
import CloseIcon from './close.png'
import SideBarLogo from './sidebar-logo.png'
import SideBarLogoWhite from './sidebar-logo-white.png'
import SideBarLogoWhiteMobile from './sidebar-logo-white-mobile.png'
import SideBarLogoBlack from './sidebar-logo-black.png'
import DashboardIcon from './dashboard.png'
import PowerBIIcon from './powerbi-icon.png'
import ResumeIcon from './resume-icon.png'
import DreIcon from './dre.png'
import CashFlowIcon from './cashflow-icon.png'
import ParametersIcon from './parameters-icon.png'
import UsersIcon from './users-icon.png'
import MockProfle from './common-profile.png'
import DownArrowIcon from './down-arrow.png'
import BellIcon from './bell.png'
import ProfileIcon from './user-icon.png'
import LogoutIcon from './logout-icon.png'
import MenuMobileIcon from './menu-mobile-icon.png'
import CloseIconWhite from './close-icon.png'
import SearchIcon from './search-icon.png'
import EntranceMoneyIcon from './entrada.png'
import OutMoneyIcon from './saida.png'
import ToReceive from './receber.png'
import ToPay from './pagar.png'
import FilterIcon from './filter-icon.png'
import CloseIconAppColor from './close-icon-appcolor.png'
import ConsumerIcon from './consumer.png'
import AnalystIcon from './analyst.png'
import AdministratorIcon from './administrator.png'
import SaldoIcon from './saldo.png'
import PasswordKey from './digital-key.png'
import XlsxIcon from './xls.png'
import AddManualIcon from './add-manual.png'
import RefreshIcon from './refresh-icon.png'

export const icons = {
 successIcon: SuccessIcon,
 errorIcon: ErrorIcon,
 warningIcon: WarningIcon,
 infoIcon: InfoIcon,
 closeIcon: CloseIcon,
 sideBarLogo: SideBarLogo,
 sideBarLogoWhite: SideBarLogoWhite,
 sideBarLogoWhiteMobile: SideBarLogoWhiteMobile,
 sideBarLogoBlack: SideBarLogoBlack,
 dashboardIcon: DashboardIcon,
 powerBIIcon: PowerBIIcon,
 resumeIcon: ResumeIcon,
 dreIcon: DreIcon,
 cashFlowIcon: CashFlowIcon,
 parametersIcon: ParametersIcon,
 usersIcon: UsersIcon,
 downArrowIcon: DownArrowIcon,
 bellIcon: BellIcon,
 profileIcon: ProfileIcon,
 logoutIcon: LogoutIcon,
 menuMobileIcon: MenuMobileIcon,
 closeIconWhite: CloseIconWhite,
 searchIcon: SearchIcon,
 entranceMoneyIcon: EntranceMoneyIcon,
 outMoneyIcon: OutMoneyIcon,
 toReceive: ToReceive,
 toPay: ToPay,
 filterIcon: FilterIcon,
 closeIconAppColor: CloseIconAppColor,
 administratorIcon: AdministratorIcon,
 analystIcon: AnalystIcon,
 consumerIcon: ConsumerIcon,
 saldoIcon: SaldoIcon,
 passwordKey: PasswordKey,
 xlsxIcon: XlsxIcon,
 addManualIcon: AddManualIcon,
 refreshIcon: RefreshIcon
}

export const images = {
  MockProfle
}

export const login_logo = Logo_Blue_login
