import { API } from "./config"
import { AxiosResponse } from 'axios';
import { LoginResponse } from "../types/ILoginResponse";
import { ILoginResponse } from "../Interfaces/ILoginResponse";

export const loginService = {
  async auth(username: string, password: string): Promise<AxiosResponse<ILoginResponse>> {
    const data = { email: username, password };
    return await API.post('/auth/login', data);
  },

  async recoveryPassword(email: string): Promise<AxiosResponse<{ message: string }>> {
    const data = { email };
    return await API.post('/auth/recovery', data);
  }
}