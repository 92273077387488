import React, { Fragment, useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordFill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import ChangePassword from '../../components/ChangePassword';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { IEditUser } from '../../Interfaces/IUserFormSave';
import { IAdministrator, IAnalyst, IConsumerForm } from '../../Interfaces/IUserTypes';
import { userService } from '../../services/user';
import Select from 'react-select';
import { cloneDeep } from 'lodash';

import './styles.css';
import { orderByHelper } from '../../helpers/orderByHelper';
import { banksList } from '../../utils/contants/banksList';
import MoreBanksButton from '../../components/ConsumerForm/components/MoreBanksButton';

type BankTypeOptions = 'money' | 'bank';
type TBanks = MoneyBanksData | BankBanksData;
type MoneyBanksData = { id: number; type: 'money', accountName: string; };
type BankBanksData = {
  type: 'bank';
  id: number;
  accountName: string;
  bankName: string;
  agency: string;
  account: string;
  initialDate: string;
}

const Profile: React.FC = () => {
  const { userType, consumerData, analystData, adminData, token, userData, handlePopulateUser } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);
  const navigate = useNavigate();
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [bankData, setBankData] = useState<TBanks[]>([]);

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  }

  async function getUserBanks() {
    const { status, data } = await userService.getConsumerBanks(token, consumerData?.id || 0);
    if (status === 200) {
      setBankData(data.banks)
    }
  }

  function changeBankData(bankIndex: number, field: keyof MoneyBanksData | keyof BankBanksData, value: string) {
    let banks = cloneDeep(bankData);
    //@ts-ignore
    banks[bankIndex][field] = value;
    console.log(banks)
    setBankData(banks);
  }


  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    toAssignData()
    getUserBanks();
  }, [])

  const formData = {
    userData: {
      firstName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      lastName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      email: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      frameBI: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },

    },

    userTypeData: {
      cpf: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      companyName: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      cnpj: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },
      zipCode: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      street: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      city: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      state: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      neighborhood: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      numberAddress: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      telNumberPrimary: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: true
      },
      telNumberSecondary: {
        value: useRef<HTMLInputElement>(null),
        type: 'input-element',
        required: false
      },
    },

    // banksData: {
    //   id: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    //   accountName: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    //   bankName: {
    //     value: useRef<any>(null),
    //     type: 'select-element',
    //     required: true
    //   },
    //   agency: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    //   account: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    //   initialDate: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    //   openingBalance: {
    //     value: useRef<HTMLInputElement>(null),
    //     type: 'input-element',
    //     required: true
    //   },
    // }

  }

  const toAssignData = () => {
    switch (userType) {
      case "Consumer": return consumerData && assignDataToConsumer(consumerData);
      case "Analyst": return analystData && assignDataToAnalyst(analystData);
      case "Administrator": return adminData && assignDataToAdministrator(adminData);
      default: return null;
    }
  }

  async function assignDataToConsumer(data: IConsumerForm) {
    console.log(formData)

    const { firstName, email, lastName, frameBI } = formData.userData;
    if (firstName.value.current) firstName.value.current.value = data.user.firstName;
    if (email.value.current) email.value.current.value = data.user.email;
    if (lastName.value.current) lastName.value.current.value = data.user.lastName || "";
    if (frameBI.value.current) frameBI.value.current.value = data.user.frameBI || "";

    const { userTypeData } = formData;
    if (userTypeData.companyName.value.current) userTypeData.companyName.value.current.value = data.companyName;
    if (userTypeData.cnpj.value.current) userTypeData.cnpj.value.current.value = data.cnpj;
    if (userTypeData.cpf.value.current) userTypeData.cpf.value.current.value = data.cpf;
    if (userTypeData.zipCode.value.current) userTypeData.zipCode.value.current.value = data.zipCode.toString();
    if (userTypeData.street.value.current) userTypeData.street.value.current.value = data.street;
    if (userTypeData.city.value.current) userTypeData.city.value.current.value = data.city;
    if (userTypeData.state.value.current) userTypeData.state.value.current.value = data.state;
    if (userTypeData.neighborhood.value.current) userTypeData.neighborhood.value.current.value = data.neighborhood;
    if (userTypeData.numberAddress.value.current) userTypeData.numberAddress.value.current.value = String(data.numberAddress);
    if (userTypeData.telNumberPrimary.value.current) userTypeData.telNumberPrimary.value.current.value = data.telNumberPrimary;
    if (userTypeData.telNumberSecondary.value.current) userTypeData.telNumberSecondary.value.current.value = data.telNumberSecondary;
    // const { id, accountName, bankName, agency, account, initialDate, openingBalance } = formData.banksData;
    // if (id.value.current) id.value.current.value = String(data.banks[0].id);
    // if (accountName.value.current) accountName.value.current.value = data.banks[0].accountName;
    // if (bankName.value.current) bankName.value.current.setValue([{ value: data.banks[0].bankName, label: data.banks[0].bankName }])
    // if (agency.value.current) agency.value.current.value = data.banks[0].agency;
    // if (account.value.current) account.value.current.value = data.banks[0].account;
    // if (initialDate.value.current) initialDate.value.current.value = data.banks[0].initialDate;
    // if (openingBalance.value.current) openingBalance.value.current.value = data.banks[0].openingBalance.toString();
  }

  async function assignDataToAnalyst(data: IAnalyst) {
    const { firstName, email, lastName, frameBI } = formData.userData;
    if (firstName.value.current) firstName.value.current.value = data.user.firstName;
    if (email.value.current) email.value.current.value = data.user.email;
    if (lastName.value.current) lastName.value.current.value = data.user.lastName || "";
    if (frameBI.value.current) frameBI.value.current.value = data.user.frameBI || "";

    const { cpf } = formData.userTypeData;
    if (cpf.value.current) cpf.value.current.value = data.cpf;
  }

  async function assignDataToAdministrator(data: IAdministrator) {
    const { firstName, email, lastName, frameBI } = formData.userData;
    if (firstName.value.current) firstName.value.current.value = data.user.firstName;
    if (email.value.current) email.value.current.value = data.user.email;
    if (lastName.value.current) lastName.value.current.value = data.user.lastName || "";
    if (frameBI.value.current) frameBI.value.current.value = data.user.frameBI || "";

    const { cpf } = formData.userTypeData;
    if (cpf.value.current) cpf.value.current.value = data.cpf;
  }

  function defineMapData() {
    if (userType === "Administrator") {
      const mapData = {
        userData: formData.userData,
        userTypeData: {
          cpf: formData.userTypeData.cpf
        }
      }
      return mapData
    }

    if (userType === "Analyst") {
      const mapData = {
        userData: formData.userData,
        userTypeData: {
          cpf: formData.userTypeData.cpf
        }
      }
      return mapData
    }

    else {
      return formData;
    }
  }

  function getFormData(): { data: IEditUser; validation: boolean } {
    let formDataValues = {}
    let validation = true;
    const mapData = defineMapData()
    Object.entries(mapData).map(([property, data]) => {
      let tempObject = {}
      Object.entries(data).map(([prop, row]) => {
        if (row.required) {
          //@ts-ignore
          const fieldValue = row.type === "input-element" ? row.value?.current?.value || "" : row.value?.current?.getValue()[0].value || "";
          if (!fieldValue || fieldValue === "") {
            validation = false;
          }
        }

        if (row.type === "input-element") {
          row.value.current && Object.assign(tempObject, { [prop]: row.value.current.value })
        } else {
          //@ts-ignore
          row.value.current && Object.assign(tempObject, { [prop]: row.value.current.getValue()[0].value })
        }
      })
      Object.assign(formDataValues, { [property]: { ...tempObject } })
      tempObject = {};
    })

    return { data: formDataValues as IEditUser, validation };
  }

  function getBankValidation(): boolean {
    try {
      for (let bank of bankData) {
        if (!bank.type) throw "bank type";
        if (bank.type === "money") {
          if (!!!bank.accountName) throw "accountName"
          break;
        }

        if (!!!bank.accountName) throw "accountName";
        if (!!!bank.account) throw "account";
        if (!!!bank.bankName) throw "bankName";
        if (!!!bank.agency) throw "Agency";
        if (!!!bank.initialDate) throw "Agency";
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  const handleSave = async () => {
    try {
      const { data: formDataValues, validation } = getFormData();
      if (!validation) {
        Swal.fire(
          'Opss...',
          `Todos os campos obrigatórios devem ser preenchidos`,
          'warning'
        )

        return;
      }

      const bankValidation = getBankValidation();
      if (!bankValidation) {
        Swal.fire(
          'Opss...',
          `Todos os campos de dados bancários obrigatórios devem ser preenchidos`,
          'warning'
        )

        return;
      }

      const { userData: formUserData, userTypeData } = formDataValues as IEditUser;

      const { status, data: { user } } = await userService.update(token, { id: userData.id, bankData, userObject: { ...userTypeData, plan_id: consumerData?.plan_id, user: { ...formUserData, email: userData.email, openingBalance: consumerData?.user.openingBalance } }, userType: userData.user_type, isAdmin: userData.user_type === 1 })

      if (status === 200) {
        handlePopulateUser(user);
        showToast(`Perfil alterado com sucesso!`, "success");
      } else {
        throw `Falha ao atualizar perfil, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  const handleAddBank = () => {
    const emptyBank: MoneyBanksData = {
      id: 0,
      type: "money",
      accountName: ""
    }

    const banks = cloneDeep(bankData);
    banks.push(emptyBank);
    setBankData(banks)
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const goPayments = () => {
    navigate('/profile/payments')
  }

  return (
    <div className="profile-form-container">
      <div className="box-container">

        <div className="form-container">
          <div className="form-box">
            <span className="span-title"><span className="required">*</span>Nome</span>
            <input
              placeholder="Digite seu nome"
              className="text-input"
              ref={formData.userData.firstName.value}
            />
          </div>

          <div className="form-box">
            <span className="span-title"><span className="required">*</span>Sobrenome</span>
            <input
              placeholder="Digite seu sobrenome"
              className="text-input"
              ref={formData.userData.lastName.value}
            />
          </div>

          <div className="form-box">
            <span className="span-title"><span className="required">*</span>Email</span>
            <input
              placeholder="Digite seu email"
              className="text-input"
              ref={formData.userData.email.value}
              disabled={true}
            />
          </div>

          <div className="form-box">
            <span className="span-title"><span className="required">*</span>CPF</span>
            <input
              placeholder="Digite seu cpf"
              className="text-input"
              ref={formData.userTypeData.cpf.value}
            />
          </div>

          {userType === "Consumer" &&
            <Fragment>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Nome da Empresa</span>
                <input
                  placeholder="Digite o nome da empresa"
                  className="text-input"
                  ref={formData.userTypeData.companyName.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title">CNPJ</span>
                <input
                  placeholder="Digite o cnpj"
                  className="text-input"
                  ref={formData.userTypeData.cnpj.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>CEP</span>
                <input
                  placeholder="Digite o cep"
                  className="text-input"
                  ref={formData.userTypeData.zipCode.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Endereço</span>
                <input
                  placeholder="Digite o endereço"
                  className="text-input"
                  ref={formData.userTypeData.street.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Cidade</span>
                <input
                  placeholder="Digite a cidade"
                  className="text-input"
                  ref={formData.userTypeData.city.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Estado</span>
                <input
                  placeholder="Digite o estado"
                  className="text-input"
                  ref={formData.userTypeData.state.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Bairro</span>
                <input
                  placeholder="Digite o bairro"
                  className="text-input"
                  ref={formData.userTypeData.neighborhood.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Número</span>
                <input
                  placeholder="Digite o número"
                  className="text-input"
                  ref={formData.userTypeData.numberAddress.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title"><span className="required">*</span>Telefone</span>
                <input
                  placeholder="Digite o telefone"
                  className="text-input"
                  ref={formData.userTypeData.telNumberPrimary.value}
                />
              </div>

              <div className="form-box">
                <span className="span-title">Telefone Secundário</span>
                <input
                  placeholder="Digite o telefone secundario"
                  className="text-input"
                  ref={formData.userTypeData.telNumberSecondary.value}
                />
              </div>
            </Fragment>
          }
          {
            userType !== "Consumer" &&
            <div className="form-box">
              <span className="span-title">Power BI</span>
              <input
                placeholder="Insira a url"
                className="text-input"
                ref={formData.userData.frameBI.value}
              />
            </div>
          }

          <input type="file" ref={fileRef} style={{ display: 'none' }} onChange={async ({ target: { files } }) => {
            if (files && files.length > 0) {
              const base = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
              });
            }
          }}
          />

          {
            userType === "Consumer" && bankData.length > 0 &&
            <>
              {
                bankData.map((bank, bankIndex) => {

                  const bankTypeLabel = bank.type === "money" ? "Caixa" : "Banco";

                  if (bank.type === "bank") {
                    const selectedBank = banksList.find(row => row.value === bank.bankName);
                    return (
                      <>
                        <div className='form-banks'>
                          <span className="span-title-bank">Dados Bancários</span>
                          <div className='form-banks-childrens'>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Tipo de Conta</span>
                              <div className="select-input">
                                <Select
                                  placeholder="Selecione o tipo"
                                  options={[
                                    { value: 'bank', label: 'Banco' },
                                    { value: 'money', label: 'Caixa' }
                                  ]}
                                  defaultValue={{ value: 'bank', label: 'Banco' }}
                                  onChange={(ev) => { changeBankData(bankIndex, 'type', ev?.value || "") }}
                                  value={{ value: bank.type, label: bankTypeLabel }}
                                />
                              </div>
                            </div>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Nome da Conta Bancária</span>
                              <input
                                placeholder="Insira o nome da conta"
                                className="text-input"
                                onChange={(ev) => changeBankData(bankIndex, 'accountName', ev.target.value)}
                                value={bank.accountName}
                              />
                            </div>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Banco</span>
                              <div className="select-input">
                                <Select
                                  placeholder="Selecione o banco"
                                  options={orderByHelper<{ label: String; value: string }>(banksList, 'label')}
                                  onChange={(ev) => ev && changeBankData(bankIndex, 'bankName', ev.value || "")}
                                  value={bank.bankName && selectedBank ? { value: selectedBank.value, label: selectedBank.label } : undefined}
                                />
                              </div>
                            </div>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Agência</span>
                              <input
                                placeholder="Digite a agência"
                                className="text-input"
                                onChange={(ev) => changeBankData(bankIndex, 'agency', ev.target.value)}
                                value={bank.agency}
                              />
                            </div>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Conta</span>
                              <input
                                placeholder="Digite a conta"
                                className="text-input"
                                onChange={(ev) => changeBankData(bankIndex, 'account', ev.target.value)}
                                value={bank.account}
                              />
                            </div>
                            <div className="form-box">
                              <span className="span-title"><span className="required">*</span>Data Inicial</span>
                              <input
                                type="date"
                                className="input-date"
                                onChange={(e) => changeBankData(bankIndex, 'initialDate', e.target.value)}
                                value={bank.initialDate}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  return (
                    <>
                      <div className='form-banks'>
                        <span className="span-title-bank">Dados Bancários</span>
                        <div className='form-banks-childrens'>
                          <div className="form-box">
                            <span className="span-title"><span className="required">*</span>Tipo de Conta</span>
                            <div className="select-input">
                              <Select
                                placeholder="Selecione o tipo"
                                options={[
                                  { value: 'bank', label: 'Banco' },
                                  { value: 'money', label: 'Caixa' }
                                ]}
                                defaultValue={{ value: 'bank', label: 'Banco' }}
                                onChange={(ev) => { changeBankData(bankIndex, 'type', ev?.value || "") }}
                                value={{ value: bank.type, label: bankTypeLabel }}
                              />
                            </div>
                          </div>
                          <div className="form-box">
                            <span className="span-title"><span className="required">*</span>Nome da Conta Bancária</span>
                            <input
                              placeholder="Insira o nome da conta"
                              className="text-input"
                              onChange={(ev) => changeBankData(bankIndex, 'accountName', ev.target.value)}
                              value={bank.accountName}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
            </>
          }
          <MoreBanksButton onPress={handleAddBank} />
        </div>
        <div className="profile-info-container">
          {/* <div className="profile-image-container">
            <div className="image-box">
              <img src={images.MockProfle} role="profile-icon" className="profile-icon" />
            </div>
            <div className="image-footer">
              <button onClick={handleUpdateImage}>Alterar Imagem</button>
            </div>
          </div> */}
          <div className="buttons-container">
            <button className="save-btn" onClick={handleSave}>Salvar</button>
          </div>

          <div className="buttons-container">
            <button className="save-btn" onClick={goPayments}>Financeiro</button>
          </div>

          <div className="change-password">
            <button onClick={handleOpenChangePassword} className="btn-change-password">
              Alterar Senha
              <RiLockPasswordFill className="passwd-icon" onClick={handleOpenChangePassword} />
            </button>
          </div>
        </div>
      </div>
      <ChangePassword open={openChangePassword} onClose={handleCloseChangePassword} userId={userData.id} />
    </div>
  );
}

export default Profile;