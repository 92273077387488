import { API } from "./config"
import { AxiosResponse } from 'axios';
import { LoginResponse } from "../types/ILoginResponse";
import { ILoginResponse } from "../Interfaces/ILoginResponse";
import { IConsumer } from "../Interfaces/IUserTypes";

export const authService = {
  async getConsumers(token: string): Promise<AxiosResponse<{ consumers: IConsumer[] }>> {
    return await API.get('/consumers', { headers: { 'Authorization': `Bearer ${token}`  } });
  }
}