import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routers } from '../../@types/routers'
import { icons } from '../../assets/images'

import './styles.css'

const SideBarMobile: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [activeLink, setActiveLink] = useState<Routers>("dashboard")

  const navigate = useNavigate()

  const handleNavigate = (to: Routers) => {
    setActiveLink(to)
    return navigate(to)
  }

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <>
      <div onClick={handleOpen}>
        <img src={icons.menuMobileIcon} className="menu-mobile-icon" role="button" />
      </div>

      <div id={open ? "sidebar-open-container" : "sidebar-close-container"}>
        <div className="sidebar">
          {open && <>
            <div className="sidebar-header" onClick={handleClose} role="columnheader">
              <img src={icons.closeIconWhite} className="close-icon" />
            </div>
            <div className="menu-list">
              <div className="menu-title">
                <span>Financeiro</span>
              </div>
              <div onClick={() => handleNavigate("dashboard")} className={activeLink === "dashboard" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.dashboardIcon} role="dashboard-icon" className="menu-icon" />
                <span className="link">Dashboard</span>
              </div>
              <div onClick={() => handleNavigate("powerBi")} className={activeLink === "powerBi" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.powerBIIcon} role="powerbi-icon" className="menu-icon" />
                <span className="link">PowerBI</span>
              </div>
              <div onClick={() => handleNavigate("resume")} className={activeLink === "resume" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.resumeIcon} role="resume-icon" className="menu-icon" />
                <span className="link">Resumo</span>
              </div>
              <div onClick={() => handleNavigate("cashflow")} className={activeLink === "cashflow" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.cashFlowIcon} role="cashflow-icon" className="menu-icon" />
                <span className="link">Fluxo de caixa</span>
              </div>
              <div className="line" />
            </div>
            {/* <div className="menu-list">
              <div className="menu-title">
                <span>Controle</span>
              </div>
              <div onClick={() => handleNavigate("parameters")} className={activeLink === "parameters" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.parametersIcon} role="parameters-icon" className="menu-icon" />
                <span className="link">Parâmetros</span>
              </div>
              <div onClick={() => handleNavigate("users")} className={activeLink === "users" ? "menu-link-active" : "menu-link"} role="link">
                <img src={icons.usersIcon} role="users-icon" className="menu-icon" />
                <span className="link">Usuários</span>
              </div>
            </div> */}
          </>}
        </div>
        <div className="close-sidebar" onClick={handleClose} />
      </div>

    </>
  )
}

export default SideBarMobile