import axios from 'axios';

const url_prod = "https://backendapi.justoecalculado.com.br";
const url_stage = "https://apijc.justoecalculado.com.br/";
const url_hml = "http://localhost:3333/";

export const BASE_URL_API = url_prod;

export const API = axios.create({
  baseURL: BASE_URL_API,
  headers: { 'Accept-Language': 'pt-BR' },
  validateStatus: () => true
});

const noAuthPaths = ['/'];

API.interceptors.response.use((config) => {
  const validUrl = !noAuthPaths.includes(location.pathname)
  if (config.status === 403 && validUrl) {
    window.location.href = "/"
  }
  return config
}, (error) => {
  const errorString = JSON.stringify(error);
  const errorParse = JSON.parse(errorString);
  const errorStatus = errorParse.status;
  if (errorStatus === 401) {
    window.location.href = "/"
  }
});