import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import './styles.css';

export type CashflowStatusOptions = "criado" | "em análise" | "finalizado";

interface IProps {
  name: CashflowStatusOptions;
  selected: CashflowStatusOptions;
  changeSelected(name: CashflowStatusOptions): void;
}

const CashflowStatus: React.FC<IProps> = ({ name, selected, changeSelected }) => {
  const colors = {
    "criado": "#FFD500",
    "em análise": "#008BFF",
    "finalizado": "#00CD44"
  }

  const handleSelect = () => {
    changeSelected(name);
  }

  return (
    <div
      className="status-container"
      style={{ background: colors[name], opacity: name === selected ? 1 : 0.4 }}
      onClick={handleSelect}
    >
      <span>{name}</span>
    </div>
  );
}

export default CashflowStatus;