import { UserRole } from "../@types/userRole";

export const canViewAllOptionsCashflow = (type: UserRole) => {
  switch(type){
    case "Administrator": {
      return true;
    }

    case "Analyst": {
      return true;
    }

    case "Consumer": {
      return false;
    }

    default: {
      return false;
    }
  }
}