import React, { useContext, useState } from 'react';
import Select from 'react-select'
import { icons } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import { getConsumersSelectOptions } from '../../helpers/getConsumersSelectOptions';
import { getYears } from '../../helpers/years';
import { IResumeFilterData } from '../../Interfaces/IResumeFilterData';
import { canViewAllOptionsResume } from '../../policies/canViewAllOptionsResume';
import { resumeService } from '../../services/resume';

import './styles.css';

interface IProps {
  applyFilter(data: { year: number, userID: number }): void;
  handleExport(): void;
}

const ResumeFilter: React.FC<IProps> = ({ applyFilter, handleExport }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<{ label: number; value: number }>({ label: new Date().getFullYear(), value: new Date().getFullYear()}) 
  const [selectedConsumer, setSelectedConsumer] = useState<{ label: string; value: number }>({ label: '', value: 0 }) 

  const { consumerList, userType, userData   } = useContext(AuthContext);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleFilter = async () => {
    handleClose();
    applyFilter({ year: selectedYear.value, userID: selectedConsumer.value || userData.id })
  }


  const handleChangeSelect = (e: {label: number, value: number} | any, type: "consumer" | "year") => {
    if(type === "consumer") return setSelectedConsumer(e);
    if(type === "year") return setSelectedYear(e);
  }

  const optionsRule = canViewAllOptionsResume(userType);

  return (
    <>
      <div className="close-menu-container" onClick={handleOpen}>
        <img src={icons.filterIcon} alt="resume-filter-icon" />
      </div>

      {
        open &&
        <div className="menu-container">
          <div className="close-filter" onClick={handleClose} />

          <div className="side-filter">
            <div className="side-filter-header" onClick={handleClose} role="columnheader">
              <img src={icons.closeIconAppColor} className="close-icon" />
            </div>

            {optionsRule &&
              <>
                <div className="customer-container">
                  <span>Cliente</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o cliente"
                      options={getConsumersSelectOptions(consumerList)}
                      onChange={e => handleChangeSelect(e, "consumer")}
                    />
                  </div>
                </div>
              </>
            }

            <div className="year-container">
              <span>Ano</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o ano"
                  options={getYears()}
                  onChange={e => handleChangeSelect(e, "year")}
                  value={selectedYear}
                />
              </div>
            </div>

            <div className="search-container">
              <span onClick={handleFilter} role="search">Buscar</span>
            </div>

            <div className="export-container">
              <span onClick={handleExport} role="document">Exportar</span>
            </div>

          </div>
        </div>
      }
    </>
  );
}

export default ResumeFilter;