import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import './styles.css'

type TBackButton = {
  path: string
}

const BackButton: React.FC<TBackButton> = ({ path }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(path);
  }

  return (
    <div className="back-button-container">
      <span className="back-button" onClick={handleBack} >
        <AiOutlineArrowLeft size={20} color="#ffffffe6" />
        <span>Voltar</span>
      </span>
    </div>
  );
}

export default BackButton;