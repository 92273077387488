import React, { createContext } from 'react';
import { useState } from 'react';
import { IResumeContext } from '../Interfaces/IResumeContext';
import { IResumeData } from '../Interfaces/IResumeData';
import Resume from '../pages/Resume';

export const ResumeContext = createContext<IResumeContext>({} as IResumeContext);


const ResumeContextProvider: React.FC = () => {
  const [ resumeData, setResumeData ] = useState<IResumeData>({ header: [], body: [] });

  const setData = (data: IResumeData) => setResumeData(data);

  return (
    <ResumeContext.Provider value={{ data: resumeData, setData }} >
      <Resume />
    </ResumeContext.Provider>
  );
}

export default ResumeContextProvider;