import React from 'react';
import { GoPlus } from 'react-icons/go';
import './styles.css';

interface IProps {
  onPress(): void;
}

const MoreBanksButton: React.FC<IProps> = ({ onPress }) => {
  return (
    <div className="bank-button-container" onClick={onPress}>
      <GoPlus className="icon" color='black'/>
      <span className="label">Adicionar Conta</span>
    </div>
  )

}

export default MoreBanksButton;