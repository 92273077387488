import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routers } from '../../@types/routers'
import { icons } from '../../assets/images'
import AuthContext from '../../contexts/AuthContext'
import { canViewControlPainel } from '../../policies/canViewControlPanel'

import './styles.css'

const Sidebar: React.FC = () => {
  const navigate = useNavigate();

  const { userType } = useContext(AuthContext);

  const [activeLink, setActiveLink] = useState<Routers>("dashboard");

  const handleNavigate = (to: Routers) => {
    setActiveLink(to);
    return navigate(to);
  }

  return (
    <div id="sidebar" role="menu-bar">
      <div className="logo-container">
        <img src={icons.sideBarLogoWhite} alt="logo" className="logo" />
      </div>
      <div className="menu-list">
        <div className="menu-title">
          <span>Financeiro</span>
        </div>
        <div onClick={() => handleNavigate("dashboard")} className={activeLink === "dashboard" ? "menu-link-active" : "menu-link"} role="link">
          <img src={icons.dashboardIcon} role="dashboard-icon" className="menu-icon" />
          <span className="link">Dashboard</span>
        </div>
        <div onClick={() => handleNavigate("powerBi")} className={activeLink === "powerBi" ? "menu-link-active" : "menu-link"} role="link">
          <img src={icons.powerBIIcon} role="powerbi-icon" className="menu-icon" />
          <span className="link">PowerBI</span>
        </div>
        <div onClick={() => handleNavigate("resume")} className={activeLink === "resume" ? "menu-link-active" : "menu-link"} role="link">
          <img src={icons.resumeIcon} role="resume-icon" className="menu-icon" />
          <span className="link">Resumo</span>
        </div>
        <div onClick={() => handleNavigate("dre")} className={activeLink === "dre" ? "menu-link-active" : "menu-link"} role="link">
          <img src={icons.dreIcon} role="resume-icon" className="menu-icon" />
          <span className="link">DRE Simplificado</span>
        </div>
        <div onClick={() => handleNavigate("cashflow")} className={activeLink === "cashflow" ? "menu-link-active" : "menu-link"} role="link">
          <img src={icons.cashFlowIcon} role="cashflow-icon" className="menu-icon" />
          <span className="link">Fluxo de caixa</span>
        </div>
        <div className="line" />
      </div>
      {
        canViewControlPainel(userType) &&
        <div className="menu-list">
          <div className="menu-title">
            <span>Controle</span>
          </div>
          <div onClick={() => handleNavigate("parameters")} className={activeLink === "parameters" ? "menu-link-active" : "menu-link"} role="link">
            <img src={icons.parametersIcon} role="parameters-icon" className="menu-icon" />
            <span className="link">Parâmetros</span>
          </div>
          <div onClick={() => handleNavigate("users")} className={activeLink === "users" ? "menu-link-active" : "menu-link"} role="link">
            <img src={icons.usersIcon} role="users-icon" className="menu-icon" />
            <span className="link">Usuários</span>
          </div>
        </div>}
    </div>
  )
}

export default Sidebar