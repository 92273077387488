import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { cashflowService } from '../../services/cashflow';
import { BASE_URL_API } from '../../services/config';

import './styles.css';

type CloseData = { type: "close" | "manual" | "upload", classification?: "pagamento" | "recebimento" }

interface IProps {
  open: boolean;
  onClose(data: CloseData): void;
  defaultStep: number;
}

const CashflowOptions: React.FC<IProps> = ({ open, onClose, defaultStep }) => {
  const { setComponentUIState } = useContext(UIContext);
  const { consumerData, token } = useContext(AuthContext);
  const hiddenFileInput = useRef(null);
  const [stepData, setStepData] = useState<{ step: number, data: CloseData }>({ step: defaultStep, data: {} as CloseData })

  const handleFirstStep = () => {
    setStepData({ step: 2, data: { type: "manual" } });
  }

  const handleSecondStep = (classification: "pagamento" | "recebimento") => {
    onClose({ type: "manual", classification });
    setStepData({ step: 1, data: {} as CloseData });
  }

  useEffect(() => {
    if(open) setStepData({ step: defaultStep, data: {} as CloseData });
    if(!open) setStepData({ step: 1, data: {} as CloseData });
  },[open])

  const handleFileSelected = async (e: any): Promise<void> => {
    const dataForm = new FormData();
    //@ts-ignore
    for (const file of hiddenFileInput.current.files) {
      dataForm.append('file', file);
    }
    const clientID = consumerData?.id || 0;
    dataForm.append('clientID', JSON.stringify(clientID));
    const res = await fetch(`${BASE_URL_API}image/upload`, {
      method: 'POST',
      body: dataForm,
      headers: {
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
    });

    if (res.status === 200) {
      onClose({ type: "close" })
      showToast("Imagens importadas com sucesso!", "success");
    } else {
      onClose({ type: "close" })
      showToast("Erro não esperado ao importar imagem, tente novamente!", "error")
    }
  };

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleClickUpload = (event: any) => {
    //@ts-ignore
    hiddenFileInput.current.click();
  };


  return (
    <>
      <div className={open ? "background-options" : ""} onClick={() => onClose({ type: "close" })} />
      <div className={open ? "options-container" : "close-options-container"}>
        <div className="buttons-container">
          {
            stepData.step === 1 ?
              <>
                <button onClick={handleFirstStep}>Lançamento Manual</button>
                <span>OU</span>
                <button onClick={handleClickUpload}>Importar Imagem</button>
              </>
              :
              <>
                <button style={{ background: "rgb(240, 105, 105)" }} onClick={() => handleSecondStep("pagamento")}>Pagamento</button>
                <span>OU</span>
                <button style={{ background: "#349199" }} onClick={() => handleSecondStep("recebimento")}>Recebimento</button>
              </>
          }
          <input type="file" multiple ref={hiddenFileInput} onChange={handleFileSelected} style={{ display: "none" }} />
        </div>
      </div>
    </>
  );
}
export default CashflowOptions;