import React from 'react';

import './styles.css';

const MainContent: React.FC = ({ children }) => {
  return (
    <div id="main-content">
      { children }
    </div>
  );
}

export default MainContent;