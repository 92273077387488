import { AxiosResponse } from "axios";
import { API } from "./config";

export const imageService = {
  update: async (token: string, imageId: number, status: string): Promise<AxiosResponse> => {
    return await API.put(`/image/${imageId}`, { data: { status } }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  download: async (token: string, imageId: number): Promise<AxiosResponse> => {
    return await API.get(`/image/download/${imageId}`, {responseType: 'arraybuffer', headers: { 'Authorization': `Bearer ${token}` } });
  }

}