import React, { createContext } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ICashflowContext } from '../Interfaces/ICashflowContext';
import { ICashflowData } from '../Interfaces/ICashflowData';
import { ICashflowFilters } from '../Interfaces/ICashflowFilters';
import { ICashflowPaginateData } from '../Interfaces/ICashflowPaginate';
import { cashflowService } from '../services/cashflow';
import { parameterService } from '../services/parameter';
import { PageAction } from '../types/paginateOptions';
import AuthContext from './AuthContext';
import UIContext from './UIContext';

export const CashflowContext = createContext<ICashflowContext>({} as ICashflowContext);

const CashflowContextProvider: React.FC = ({ children }) => {
  const { token, userType, consumerData } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [cashflowData, setCashflowData] = useState<ICashflowData[]>([]);
  const [openImagesList, setOpenImagesList] = useState<boolean>(false);
  const [paginateData, setPaginateData] = useState<ICashflowPaginateData>({
    skip: 0,
    currentPage: 1,
    lastPage: null,
    limitePerPage: 10
  })
  const [filter, setFilter] = useState<ICashflowFilters>({});
  const [invoices, setInvoices] = useState<string[]>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<{ value: string; label: string }[]>([]);
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [parametersList, setParametersList] = useState<{ value: string; label: string, classification: string }[]>([]);

  const setData = (data: ICashflowData[]) => setCashflowData(data);

  const handleOpenImagesList = (open: boolean) => setOpenImagesList(open);


  function showToast(open: boolean, msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open, message: msg, type }
    })
  }

  async function getInvoices(): Promise<void> {
    try {
      const { status, data: { invoices } } = await cashflowService.getInvoices(token);
      if (status === 200) {
        setInvoices(invoices)
        const options: { value: string; label: string }[] = []
        invoices.map(invoice => options.push({ value: invoice, label: invoice }))
        setInvoiceOptions(options);
      }
    } catch (error) {

    }
  }


  async function getParameters(): Promise<void> {
    try {
      const { status, data: { params } } = await parameterService.findAll(token);
      if (status === 200) {
        const options: { value: string; label: string, classification: string }[] = []
        params.map(({ classification, invoice }) => options.push({ value: invoice, label: invoice, classification }))
        setParametersList(options);
      }
    } catch (error) { }
  }

  async function getCashflowData(action: PageAction = "Initial", paginateContent: ICashflowPaginateData = paginateData, appliedFilter = hasFilter): Promise<void> {
    if (appliedFilter) return getFilteredCashflow(action, paginateContent);

    if (userType === "Consumer") {
      return getConsumerData(action, paginateContent);
    }

    return getAllCashflow(action, paginateContent);
  }

  async function getFilteredCashflow(action: PageAction, paginateContent: ICashflowPaginateData) {
    const { skip } = paginateContent;
   
    try {
      const { status, data: { totalPages, data } } = await cashflowService.getFilterData(skip, action, filter, token)

      if (status !== 200) {
        throw "Falha não esperada";
      }

      setData(data)
      const paginateUpdate = getPaginateData(action);
      setPaginateData({
        ...paginateData,
        ...paginateUpdate,
        currentPage: paginateContent.currentPage !== paginateUpdate.currentPage ?  paginateContent.currentPage : paginateUpdate.currentPage,
        lastPage: totalPages ? totalPages : paginateData.lastPage
      })

      paginateData.currentPage === 1 && showToast(true, `Filtro aplicado com sucesso!`, "success");
    } catch (error) {
      showToast(true, `${error}`, "error");
    }
  }

  async function getConsumerData(action: PageAction, paginateContent: ICashflowPaginateData) {
    const { skip } = paginateContent;
    
    try {
      const { status, data: { data, totalPages } } = await cashflowService.getConsumerData(skip, consumerData?.id || 0, action, token);

      if (status !== 200) {
        throw "Falha não esperada";
      }

      setData(data)
      const paginateUpdate = getPaginateData(action);
      const paginateDataUpdt: ICashflowPaginateData = {
        ...paginateData,
        ...paginateUpdate,
        lastPage: totalPages ? totalPages : paginateData.lastPage,
      }
      setPaginateData(paginateDataUpdt)

      console.log("paginate: ", paginateData)
    } catch (error) {
      showToast(true, `${error}`, "error");
    }
  }

  async function getAllCashflow(action: PageAction, paginateContent: ICashflowPaginateData) {
    const { skip } = paginateContent;

    try {
      const { status, data: { data, totalPages } } = await cashflowService.getData(skip, action, token);

      if (status !== 200) {
        throw "Falha não esperada";
      }

      setData(data);

      const paginateUpdate = getPaginateData(action);
      const paginateDataUpdt: ICashflowPaginateData = {
        ...paginateData,
        ...paginateUpdate,
        lastPage: totalPages ? totalPages : paginateData.lastPage,
      }

      setPaginateData(paginateDataUpdt)
    } catch (error) {
      showToast(true, `${error}`, "error");
    }
  }

  const getPaginateData = (action: PageAction): { skip: number; currentPage: number } => {
    const { skip, currentPage } = paginateData;
    if (action === "Initial") {
      return {
        skip,
        currentPage: 1
      }
    }
    if (action === "Next") {
      return {
        skip: skip + 10,
        currentPage: currentPage + 1
      }
    }

    return {
      skip: skip - 10,
      currentPage: currentPage - 1
    }

  }

  useEffect(() => {
    if (window.location.pathname === '/cashflow') {
      getCashflowData();
    }
  }, [window.location.pathname])

  return (
    <CashflowContext.Provider value={{
      data: cashflowData,
      setData,
      handleOpenImagesList,
      openImagesList,
      paginateData,
      setPaginateData,
      filter,
      setFilter,
      invoiceOptions,
      getInvoices,
      getParameters,
      parametersList,
      hasFilter,
      setHasFilter,
      getCashflowData,
    }} >
      {children}
    </CashflowContext.Provider>
  );
}

export default CashflowContextProvider;