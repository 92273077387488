import React, { useContext } from 'react'
import Select from 'react-select'
import { icons } from '../../assets/images'
import { useState } from 'react'
import { canViewAllOptionsDashboard } from '../../policies/canViewAllOptionsDashboard'

import './styles.css'
import { IDashboardFilterData } from '../../Interfaces/IDashboardFilterData'
import AuthContext from '../../contexts/AuthContext'
import { getConsumersSelectOptions } from '../../helpers/getConsumersSelectOptions'
import { getDate } from '../../helpers/dates'

interface IProps {
  applyFilter(dashboardFilterData: IDashboardFilterData): void;
  banks: { label: string; value: number }[];
  selected: { label: string; value: number };
  onSelect(bank: { label: string; value: number }): void;
  getBanks(consumerId: number): void;
}

const initialStates = {
  dashboardFilterData: {
    initialDate: getDate.startOfCurrentMonth(),
    finalDate: getDate.endOfCurrentMonth(),
    consumer: 0
  } as IDashboardFilterData
}

const DashboardFilterMobile: React.FC<IProps> = ({ applyFilter, selected, onSelect, banks, getBanks }) => {
  const optionsRule = canViewAllOptionsDashboard("Administrator")
  const { consumerList } = useContext(AuthContext);

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<{ initialDate: string; finalDate: string; consumer?: number }>(initialStates.dashboardFilterData)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handleFilter = () => {
    applyFilter(data);
    handleClose();
  }

  const handleChangeDates = (field: keyof IDashboardFilterData, event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [field]: event.target.value })
  }

  const handleSelect = (bank: { label: string; value: number }) => {
    onSelect(bank);
  }

  const handleChangeConsumer = (userId: number) => {
    const consumerId = consumerList.find(consumer => consumer.user_id === userId);
    if (consumerId) getBanks(consumerId.id);
  }

  const isAllSelected = !selected.value;

  return (
    <>
      <button className="filter-button" onClick={handleOpen}>
        <img src={icons.filterIcon} alt="filter-icon" />
      </button>

      {open &&
        <div className="side-filter">

          <div className="close-sidebar" onClick={handleClose} />

          <div className="sidebar">
            <div className="sidebar-header" onClick={handleClose} role="columnheader">
              <img src={icons.closeIconAppColor} className="close-icon" />
            </div>

            <div className="date-container">
              <span>Data Inicial</span>
              <input
                type="date"
                onChange={(ev) => handleChangeDates("initialDate", ev)}
                value={data.initialDate}
              />
            </div>

            <div className="date-container">
              <span>Data Final</span>
              <input
                type="date"
                onChange={(ev) => handleChangeDates("finalDate", ev)}
                value={data.finalDate}
              />
            </div>

            {optionsRule &&
              <>
                <div className="customer-container">
                  <span>Cliente</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o cliente"
                      options={getConsumersSelectOptions(consumerList)}
                      onChange={(ev) => {
                        setData(prev => ({
                          ...prev,
                          consumer: ev?.value
                        }))
                        ev && handleChangeConsumer(ev.value);
                      }
                      }
                    />
                  </div>
                </div>
              </>
            }

            {
              banks.length > 0 &&
              <div className="banks-container">
                <span className="title">Conta Bancária</span>
                {
                  banks.map(bank => {
                    const isSelected = selected.value === bank.value;
                    return (
                      <div className="item" onClick={() => handleSelect(bank)}>
                        <label>{bank.label}</label>
                        <input type="radio" checked={isSelected} ></input>
                      </div>
                    )
                  })
                }

                <div className="item" onClick={() => handleSelect({ label: 'all', value: 0 })}>
                  <label>Todas</label>
                  <input type="radio" checked={isAllSelected}></input>
                </div>
              </div>
              || <></>}

            <div className="search-container">
              <span onClick={handleFilter} role="search">Buscar</span>
            </div>
          </div>

        </div>
      }
    </>
  )
}

export default DashboardFilterMobile