import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { AiFillEye } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';

import './styles.css';
import { useNavigate } from 'react-router-dom';
import { ICashflowFormLocationState } from '../../Interfaces/ICashflowFormLocationState';
import { ICashflowData } from '../../Interfaces/ICashflowData';
import { useContext } from 'react';
import { CashflowContext } from '../../contexts/CashflowContext';
import { currencyFormat } from '../../helpers/currencyFormat';
import Swal from 'sweetalert2';
import { cashflowService } from '../../services/cashflow';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { useEffect } from 'react';
import { fixSpelling } from '../../helpers/fixSpelling';
import { getDate } from '../../helpers/dates';
import DeleteRecurrenceModal from '../DeleteRecurrenceModal';

interface IProps {
  handleRefresh(): void;
}

const modalInitialState = {
  open: false,
  defaultSelected: 0,
  recurrenceId: ''
}

const CashflowTable: React.FC<IProps> = ({ handleRefresh }) => {
  const navigate = useNavigate();

  const { data } = useContext(CashflowContext);
  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [deleteRecurrenceModal, setDeleteRecurrenceModal] = useState({ ...modalInitialState });

  const handleEditCashflowForm = (tableData: ICashflowData) => {
    navigate("form", { state: { data: { ...tableData }, action: "edit" } as ICashflowFormLocationState });
  }

  const closeDeleteRecurrenceModal = () => {
    setDeleteRecurrenceModal({ ...modalInitialState });
  }


  const handleDelete = (id: number, recurrenceId: string | null) => {
    if (recurrenceId) return setDeleteRecurrenceModal({ open: true, defaultSelected: id, recurrenceId: recurrenceId });
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      icon: 'warning',
      html: 'Tem certeza que deseja deletar esse lançamento ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Sim',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i> Não',
      cancelButtonAriaLabel: 'Thumbs down'
    }).then((data) => {
      if (data.isConfirmed) {
        deleteCashflow(id);
      }
    })
  }

  const handleDeleteRecourrence = (deleteIds: number[]) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      icon: 'warning',
      html: `Tem certeza que deseja deletar ${deleteIds.length} lançamento(s) ?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Sim',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i> Não',
      cancelButtonAriaLabel: 'Thumbs down'
    }).then((data) => {
      if (data.isConfirmed) {
        deleteCashflow(deleteIds, true);
      }
    })
  }

  async function deleteCashflow(id: number | number[], isRecurrence: boolean = false) {
    try {
      const { status } = await cashflowService.deleteCashflow(token, id, isRecurrence);
      if (status === 200) {
        setDeleteRecurrenceModal({ ...modalInitialState })
        handleRefresh()
        setComponentUIState({ component: "Toast", componentState: { open: true, type: "success", message: "Lançamento(s) deletado com sucesso!" } });
      } else {
        setComponentUIState({ component: "Toast", componentState: { open: true, type: "error", message: "Falha ao apagar lançamento(s)!" } });
      }
    } catch (error) {
      setComponentUIState({ component: "Toast", componentState: { open: true, type: "error", message: "Falha ao apagar lançamento(s)!" } });
    }
  }

  return (
    <>
      <Table key={1}>
        <Thead key="thead-cashflow">
          <Tr>
            <Th>Cliente</Th>
            <Th>Mês</Th>
            <Th>Ano</Th>
            <Th>Data</Th>
            <Th>Conta</Th>
            <Th>Condição</Th>
            <Th>Origem</Th>
            <Th>Fornecedor</Th>
            <Th>Histórico</Th>
            <Th>Valor</Th>
            <Th>#</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data && data.map((data, index) => <Tr key={index}>
            <Td>{data.client.companyName}</Td>
            <Td>{data.month}</Td>
            <Td>{data.year}</Td>
            <Td>{getDate.brasilFormat(data.date)}</Td>
            <Td>{data.invoice}</Td>
            <Td>{fixSpelling(data.classification)}</Td>
            <Td>{fixSpelling(data.origin)}</Td>
            <Td title={data.supplier}>{data.supplier && data.supplier.length > 15 ? `${data.supplier.substr(0, 15)}...` : data.supplier}</Td>
            <Td title={data.history}>{data.history.length > 15 ? `${data.history.substr(0, 15)}...` : data.history}</Td>
            <Td>R$ {currencyFormat(Number(data.value.toFixed(2)))}</Td>
            <Td>
              <div className="icons">
                <AiFillEye className="eye-icon" onClick={() => handleEditCashflowForm(data)} />
                <FaTrash className="trash-icon" onClick={() => handleDelete(data.id, data.recurrenceId)} />
              </div>
            </Td>
          </Tr>)}
        </Tbody>
      </Table>

      {deleteRecurrenceModal.open && <DeleteRecurrenceModal data={{ defaultSelected: deleteRecurrenceModal.defaultSelected, recurrenceId: deleteRecurrenceModal.recurrenceId }} onClose={closeDeleteRecurrenceModal} onSave={(ids) => handleDeleteRecourrence(ids)} />}
    </>
  )
}

export default CashflowTable;