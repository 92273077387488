import React from 'react';
import { currencyFormat } from '../../helpers/currencyFormat';
import { orderByHelper } from '../../helpers/orderByHelper';
import { IResumesValues } from '../../Interfaces/IResumeResponse';

import './styles.css';

const tableHeader = ["Mês / Conta", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

interface IProps {
  data: IResumesValues[];
}

const GridTable: React.FC<IProps> = ({ data }) => {
  return (
    <>
      <div className="small-widget-box">
        <div className="table-grid">
          {tableHeader.map((field, index) =>
            <div className={index === 0 ? "header fixedToBoth" : "header fixedToTop"}>{field}</div>
          )}

          {orderByHelper<IResumesValues>(data, 'order').map(({ name, MOUNTH_VALUES, type, order }, index) => {
            const isOut = type === "saída";
            const outStyle = { color: isOut ? "red" : "" }
            const lineStyle = () => index % 2 === 0 ? { background: '#DCF5F8' } : {};
            return (
            <>
              <div className="body-table-title fixedToLeft" style={{ ...lineStyle() }} >{name}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.JANEIRO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.FEVEREIRO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.MARCO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.ABRIL)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.MAIO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.JUNHO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.JULHO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.AGOSTO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.SETEMBRO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.OUTUBRO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.NOVEMBRO)}</div>
              <div className="body-table" style={{...outStyle, ...lineStyle() }}>R${currencyFormat(MOUNTH_VALUES.DEZEMBRO)}</div>
            </>
            )}
          )}
        </div>
      </div>
    </>
  );
}

export default GridTable;