import axios, { AxiosResponse } from "axios";

interface IGetAddressResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export const commonService = {
  getAddress: async (zipCode: string): Promise<AxiosResponse<IGetAddressResponse>> => {
    return await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
  }
}