import React, { useState } from 'react';
import { useContext } from 'react';
import { icons } from '../../assets/images';
import { CashflowContext } from '../../contexts/CashflowContext';
import { ICashflowImages } from '../../Interfaces/ICashflowImages';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { BsCheckLg, BsDownload } from 'react-icons/bs';
import './styles.css';
import { imageService } from '../../services/images';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

interface IProps {
  data: ICashflowImages[];
}

const ImagesList: React.FC<IProps> = ({ data }) => {
  const { openImagesList, handleOpenImagesList } = useContext(CashflowContext);
  const { token, userType } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [listData, setListData] = useState<ICashflowImages[]>([]);

  useEffect(() => {
    setListData(data)
  }, [data])

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const handleClose = () => handleOpenImagesList(false);

  const handleDownload = async (imageId: number, imageName: string) => {
    try {
      const { status, headers, data, } = await imageService.download(token, imageId);
      if (status !== 200) throw `Falha não esperada, status code: ${status}`;
      const extension = headers['content-type'].split('/')[1];
      const type = headers['content-type']
      const blob = new Blob([data], { type: type, encoding: 'UTF-8' } as any)
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${imageName}`
      link.click()
    } catch (error) {
      showToast(`${error}`, "error");
    }
  }

  const handleChangeStatus = async (index: number, imageId: number) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      icon: 'warning',
      html: 'Tem certeza que deseja finalizar esse lançamento ?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Sim',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i> Não',
      cancelButtonAriaLabel: 'Thumbs down'
    }).then(async (data) => {
      if (data.isConfirmed) {
        try {
          const { status, data } = await imageService.update(token, imageId, "finalizado");
          if (status !== 200) throw data.msg || `Falha ao confirmar lançamento de anexo, status code: ${status}`;

          const newData = [...listData] as ICashflowImages[];
          newData.splice(index, 1);
          setListData(newData)
        } catch (error) {
          showToast(`${error}`, "error");
        }
      }
    })
  }

  return (
    openImagesList ?
      <>
        <div className="images-list-container" onClick={handleClose} />
        <div className="images-list-box">
          <img src={icons.closeIconAppColor} className="close-icon-modal" onClick={handleClose} />
          <div className="scroll-div">
            <Table key={1}>
              <Thead key="thead-cashflow">
                <Tr>
                  <Th>Imagem</Th>
                  <Th>Cliente</Th>
                  <Th>Data de Importação</Th>
                  <Th>Status</Th>
                  {userType !== "Consumer" && <Th>#</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {listData.map((data, index) => <Tr key={index}>
                  <Td>{data.name}</Td>
                  <Td>{data.client.companyName}</Td>
                  <Td>{data.created_at}</Td>
                  <Td>{data.status}</Td>
                  {userType !== "Consumer" && <Td>
                    <BsCheckLg style={{ cursor: "pointer", marginRight: '1em' }} onClick={() => handleChangeStatus(index, data.id)} />
                    <BsDownload style={{ cursor: "pointer" }} onClick={() => handleDownload(data.id, data.name)} />
                  </Td>}
                </Tr>)}
              </Tbody>
            </Table>
          </div>
        </div>
      </>
      :
      <></>
  );
}

export default ImagesList;