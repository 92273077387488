import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CashflowFilter from '../../components/CashflowFilter';
import CashflowOptions from '../../components/CashflowOptions';
import CashflowTable from '../../components/CashflowTable';
import ImagesList from '../../components/ImagesList';
import AuthContext from '../../contexts/AuthContext';
import { CashflowContext } from '../../contexts/CashflowContext';
import { ICashflowFormLocationState } from '../../Interfaces/ICashflowFormLocationState';
import { ICashflowImages } from '../../Interfaces/ICashflowImages';
import { ICashflowPaginateData } from '../../Interfaces/ICashflowPaginate';
import { canViewUploadCashflow } from '../../policies/canViewUploadCashflow';
import { cashflowService } from '../../services/cashflow';
 
import './styles.css';

type CloseData = { type: "close" | "manual" | "upload", classification?: "pagamento" | "recebimento" }

const cashflowOptionsInitialValue = { open: false, defaultStep: 1 };

const Cashflow: React.FC = () => {

  const { handleOpenImagesList, paginateData, getInvoices, getParameters, filter, getCashflowData } = useContext(CashflowContext)
  const { userType, token, consumerData } = useContext(AuthContext);

  const [cashflowOptions, setCashflowOptions] = useState<{ open: boolean, defaultStep: number }>({ ...cashflowOptionsInitialValue });
  const [images, setImages] = useState<ICashflowImages[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    getInvoices();
    getParameters();
    getImagesData();
  }, [])

  function handleNavigateForm(classification?: "pagamento" | "recebimento") {
    navigate("form", { state: { data: {}, action: "add", classification } as ICashflowFormLocationState });
  }

  const handleOpenCashflowOptions = (step: number) => {
    setCashflowOptions({ open: true, defaultStep: step });
  }

  const handleCloseCashflowOptions = (closeData: CloseData) => {
    if (closeData.type === "close") return setCashflowOptions(cashflowOptionsInitialValue);
    if (closeData.type === "manual") {
      handleNavigateForm(closeData.classification)
      return setCashflowOptions(cashflowOptionsInitialValue);
    }

    return setCashflowOptions(cashflowOptionsInitialValue);
  }

  const handleNewCashFlow = () => {
    const canView = canViewUploadCashflow(userType, consumerData);
    if (!canView) {
      return handleOpenCashflowOptions(2);
    }

    return handleOpenCashflowOptions(1);
  }

  async function getImagesData() {
    if (userType === "Administrator" || userType === "Analyst") {
      try {
        const { status, data: { images } } = await cashflowService.getImages(token);
        if (status === 200) {
          setImages(images);
        }
      } catch (error) { }
    }

    if (userType === "Consumer") {
      try {
        const { status, data: { images } } = await cashflowService.getConsumerImages(token, consumerData?.id || 0);
        if (status === 200) {
          setImages(images);
        }
      } catch (error) { }
    }
  }

  const handleNextPage = () => {
    if (paginateData.currentPage === paginateData.lastPage) return;
    const paginateContent = {
      ...paginateData,
      skip: paginateData.skip + 10,
      currentPage: paginateData.currentPage + 1
    }

    getCashflowData("Next", paginateContent)
  }

  const handlePrevPage = () => {
    if (paginateData.currentPage === 1) return;

    const paginateContent: ICashflowPaginateData = {
      ...paginateData,
      skip: paginateData.skip - 10,
      currentPage: paginateData.currentPage - 1
    }

    getCashflowData("Prev", paginateContent)
  }

  return (
    <div className="cashflow-container">
      <div className="cashflow-header">
        <button className="header-btn" onClick={handleNewCashFlow} >
          Novo Lançamento
        </button>
        <button className="header-btn" onClick={() => handleOpenImagesList(true)}>
          Imagens Importadas
        </button>
      </div>

      <div className="cashflow-body">
        <div className="table-buttons">
          <button onClick={handlePrevPage} className={paginateData.currentPage === 1 ? "disabled-btn" : ""}>Anterior</button>
          <span>Página {paginateData.lastPage ? paginateData.currentPage : 0} de {paginateData.lastPage}</span>
          <button onClick={handleNextPage} className={paginateData.currentPage === paginateData.lastPage ? "disabled-btn" : ""}>Próxima</button>
        </div>
        <div className="table-content">
          <CashflowTable key={'cashflow-table'} handleRefresh={getCashflowData} />
        </div>
      </div>

      <CashflowFilter cleanFilter={() => getCashflowData("Initial", paginateData, false)} />
      <ImagesList data={images} />
      <CashflowOptions open={cashflowOptions.open} onClose={handleCloseCashflowOptions} defaultStep={cashflowOptions.defaultStep} />
    </div>
  );
}

export default Cashflow;