import { IDashboardFilterData } from "../Interfaces/IDashboardFilterData";
import { DashboardOptions } from "../types/dashboardOptions";
import { API } from "./config";
import { AxiosResponse } from 'axios';

const routeConfig: any = {
  "saída": "status",
  "entrada": "status",
  "saldo": "balance"
}

export const dashboardService = {
  async filter(filter: Required<IDashboardFilterData>, bankId: number, status: DashboardOptions, token: string): Promise<AxiosResponse<{ amount: number }>> {
    const { finalDate, initialDate, consumer } = filter;
    const data = {
      status,
      userID: consumer,
      initialDate,
      finalDate,
    };
    const route = routeConfig[status] ? routeConfig[status] : 'classification'
    return await API.post(`/dashboard/${route}`, { ...data, bankId }, { headers: { 'Authorization': `Bearer ${token}` } });
  }
}