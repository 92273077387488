import React, { useContext, useState } from 'react';
import Select from 'react-select'
import { tsXLXS } from 'ts-xlsx-export';

import { icons } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import { CashflowContext } from '../../contexts/CashflowContext';
import UIContext from '../../contexts/UIContext';
import { daysBetweenDates } from '../../helpers/daysBetweenDates';
import { getConsumersSelectOptions, getConsumersSelectOptionsWithConsumerID } from '../../helpers/getConsumersSelectOptions';
import { ICashflowFilters } from '../../Interfaces/ICashflowFilters';
import { canViewAllOptionsCashflow } from '../../policies/canViewAllOptionsCashflow';
import { cashflowService } from '../../services/cashflow';
import { ConditionOptions } from '../../utils/contants/conditions';
import { OriginOptions } from '../../utils/contants/origin';

import './styles.css';

interface IViewData {
  initialDate?: string;
  finalDate?: string;
  invoice?: { value: string; label: string };
  conditions?: { value: string; label: string };
  origin?: { value: string; label: string };
  consumer?: { value: number; label: string };
}

interface IProps {
  cleanFilter(): void;
}


const CashflowFilter: React.FC<IProps> = ({ cleanFilter }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { filter, setFilter, invoiceOptions, paginateData, setPaginateData, setData, setHasFilter, hasFilter } = useContext(CashflowContext);
  const [viewData, setViewData] = useState<IViewData>({})
  const { consumerList, token, userType, consumerData } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  function showToast(open: boolean, msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open, message: msg, type }
    })
  }

  const handleFilter = async () => {
    filter.clientId = filter.clientId ? filter.clientId : consumerData?.id;
    try {
      const { status, data: { totalPages, data } } = await cashflowService.getFilterData(0, "Initial", filter, token)

      if (status !== 200) {
        throw "Falha não esperada";
      }

      setHasFilter(true);
      setData(data)
      setPaginateData({
        ...paginateData,
        currentPage: 1,
        skip: 0,
        lastPage: totalPages
      })
      handleClose();
 
      showToast(true, `Filtro aplicado com sucesso!`, "success");
    } catch (error) {
      showToast(true, `${error}`, "error");
    }
  }

  const handleExport = async () => {
    const { initialDate, finalDate } = viewData;
    if (!initialDate || initialDate === "") return showToast(true, "Data inicial deve ser preenchida para exportar", "warning");
    if (!finalDate || finalDate === "") return showToast(true, "Data final deve ser preenchida para exportar", "warning");
    if (daysBetweenDates(String(initialDate), String(finalDate)) >= 180) return showToast(true, "Periodo máximo para exportação é de 6 meses", "warning");

    showToast(true, "Os dados, serão exportados, aguarde....", "info");

    try {
      const { status, data: { data } } = await cashflowService.export(filter, token);

      if (status !== 200) throw `Falha para exportar dados, status code : ${status}`;

      let toExportCommitments = await Promise.all(
        data.map(row => {
          return {
            client: row.client.companyName,
            mes: row.month,
            ano: row.year,
            data: row.date,
            conta: row.invoice,
            condicao: row.classification,
            origem: row.origin,
            historico: row.history,
            valor: row.value,
            status: row.status
          }
        })
      )

      await tsXLXS()
        .exportAsExcelFile(toExportCommitments)
        .saveAsExcelFile(`export_fluxo_de_caixa`);

      showToast(true, "Exportação realizada com sucesso!", "success");
    } catch (error) {
      showToast(true, `Falha ao exportar dados, erro: ${error}`, "error")
    }

  }

  const handleChangeDates = (field: keyof ICashflowFilters, event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [field]: event.target.value })
  }

  const handleCleanFilter = () => {
    setFilter({})
    setViewData({
      conditions: { label: "", value: "" },
      consumer: { label: "", value: 0 },
      invoice: { label: "", value: "" },
      initialDate: "",
      origin: { label: "", value: "" },
      finalDate: ""
    })
    setViewData({})
    setHasFilter(false);
    setPaginateData({
      ...paginateData,
      skip: 0
    })
    cleanFilter();
    handleClose();
  }

  const optionsRule = canViewAllOptionsCashflow(userType);

  return (
    <>
      <div className="close-menu-container" onClick={handleOpen}>
        <img src={icons.filterIcon} alt="resume-filter-icon" />
      </div>

      {
        open &&
        <div className="menu-container">
          <div className="close-filter" onClick={handleClose} />

          <div className="side-filter">
            <div className="side-filter-header" onClick={handleClose} role="columnheader">
              <img src={icons.closeIconAppColor} className="close-icon" />
            </div>

            <div className="date-container">
              <span>Data Inicial</span>
              <input
                type="date"
                value={viewData.initialDate}
                onChange={(ev) => { handleChangeDates("dateStart", ev); setViewData(prev => ({ ...prev, initialDate: ev.target.value })) }}
              />
            </div>

            <div className="date-container">
              <span>Data Final</span>
              <input
                type="date"
                value={viewData.finalDate}
                onChange={(ev) => { handleChangeDates("dateEnd", ev); setViewData(prev => ({ ...prev, finalDate: ev.target.value })) }}
              />
            </div>

            <div className="select-container">
              <span>Conta</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione a conta"
                  options={invoiceOptions}
                  onChange={ev => { setFilter({ ...filter, invoice: ev?.value }); setViewData(prev => ({ ...prev, invoice: { value: ev?.value || "", label: ev?.label || "" } })) }}
                  value={viewData.invoice}
                />
              </div>
            </div>

            <div className="select-container">
              <span>Condição</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione a condição"
                  options={ConditionOptions}
                  onChange={ev => { setFilter({ ...filter, classification: ev?.value }); setViewData(prev => ({ ...prev, conditions: { value: ev?.value || "", label: ev?.label || "" } })) }}
                  value={viewData.conditions}
                />
              </div>
            </div>

            <div className="select-container">
              <span>Origem</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione a origem"
                  options={OriginOptions}
                  onChange={ev => { setFilter({ ...filter, origin: ev?.value }); setViewData(prev => ({ ...prev, origin: { value: ev?.value || "", label: ev?.label || "" } })) }}
                  value={viewData.origin}
                />
              </div>
            </div>

            {optionsRule &&
              <>
                <div className="select-container">
                  <span>Cliente</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o cliente"
                      options={getConsumersSelectOptionsWithConsumerID(consumerList)}
                      onChange={ev => { setFilter({ ...filter, clientId: ev?.value }); setViewData(prev => ({ ...prev, consumer: { value: ev?.value || 0, label: ev?.label || "" } })) }}
                      value={viewData.consumer}
                    />
                  </div>
                </div>
              </>
            }

            <div className="search-container">
              <span onClick={handleFilter} role="search">Buscar</span>
            </div>

            {hasFilter && <div className="clean-filter-container">
              <span onClick={handleCleanFilter}> Limpar Filtro </span>
            </div>}

            <div className="export-container">
              <span onClick={handleExport} role="search">Exportar Filtro</span>
            </div>

          </div>
        </div>
      }
    </>
  );
}

export default CashflowFilter;