export const getDate = {
  startOfCurrentMonth: (): string => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    return `${year}-${month}-01`
  },

  endOfCurrentMonth: (): string => {
    const date = new Date()
    const currentDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const day = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
    return `${year}-${month}-${day}`
  },

  brasilFormat: (date: string): string => {
    const splitDate = date.split('-');
    const reverseArray = splitDate.reverse();
    return reverseArray.join().replaceAll(',', '/');
  }
}