import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import OrderParameters from '../../components/OrderParameters';
import ParameterView from '../../components/ParameterView';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { confirmAlert } from '../../helpers/confirmAlert';
import { IParameters } from '../../Interfaces/IParameters';
import { parameterService } from '../../services/parameter';

import './styles.css';
const initialViewData = { id: 0, parameter: { id: 0, invoice: '', order: 0, classification: "", dreClassification: "" } };
const Parameters: React.FC = () => {
  const [viewData, setViewData] = useState<{ id: number; parameter: IParameters }>(initialViewData)
  const [openView, setOpenView] = useState<boolean>(false)
  const [openOrder, setOpenOrder] = useState<boolean>(false)
  const [data, setData] = useState<IParameters[]>([])

  const navigate = useNavigate()
  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const handleOpenView = (id: number, parameter: IParameters) => {
    setViewData({ id, parameter })
    setOpenView(true);
  }

  const handleCloseView = () => {
    setOpenView(false);
    setViewData(initialViewData)
  }

  const handleOpenOrder = () => {
    setOpenOrder(true);
  }

  const handleCloseOrder = () => {
    setOpenOrder(false);
  }

  const handleNewParameter = () => {
    navigate("new-parameter");
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  async function getParameters() {
    try {
      const { status, data: { params } } = await parameterService.findAll(token);
      if (status !== 200) throw `Falha para buscar os dados, error ${status}`;
      setData(params);
    } catch (error) {
      const msg = `${error}`
      showToast(msg, "error")
    }
  }

  const handleDelete = async (id: number) => {
    const { isConfirmed } = await confirmAlert("Tem certeza que deseja deleter esse parâmetro?")
    if(isConfirmed){
     try {
       const { status } = await parameterService.delete(token, id);
       if(status === 200) {
         showToast("Deletado com sucesso!", "success");
         getParameters()
       } else {
         throw `Falha ao deletar parâmetro, status code: ${status}`
       }
     } catch (error) {
       showToast(`${error}`, "error");
     }
    }
  }

  useEffect(() => {
    getParameters()
  }, [])

  return (
    <div className="parameters-container">
      <div className="parameters-header">
        <button className="header-btn" onClick={handleNewParameter}>
          Novo Parâmetro
        </button>
        <button className="header-btn" onClick={handleOpenOrder}>
          Ordenar Parâmetros
        </button>
      </div>
      <div className="parameters-body">

        <div className="table-header">
          <div>
            <span>Conta</span>
          </div>
          <div>
            <span>Classificação</span>
          </div>
          <div>
            <span>Tipo DRE</span>
          </div>
          <div>
            <span>#</span>
          </div>
        </div>

        <div className="table-body">
          {data.map(row =>
            <div className="line">
              <div>
                <span>{row.invoice}</span>
              </div>
              <div>
                <span>{row.classification}</span>
              </div>
              <div>
                <span>{row.dreClassification}</span>
              </div>
              <div>
                <AiFillEye className="eye-icon" onClick={() => handleOpenView(row.id, row)} />
                <FaTrash className="trash-icon" onClick={() => handleDelete(row.id)}/>
              </div>
            </div>
          )}
        </div>

      </div>
      <ParameterView open={openView} data={viewData} onClose={handleCloseView} onRefresh={getParameters} />
      <OrderParameters open={openOrder} data={data} onClose={handleCloseOrder} onRefresh={getParameters} />
    </div>
  );
}

export default Parameters;