import React, { createContext, FC, useEffect, useRef, useState } from "react";

type TPasswordFields = {
  'password': string;
  'confirmation': string;
}

interface IUsersFormContext {
  currencyRef: React.RefObject<HTMLInputElement>;
  banksData: BanksData[];
  setBanksData: React.Dispatch<React.SetStateAction<BanksData[]>>;
  formData: ConsumerFormData;
  setFormData: React.Dispatch<React.SetStateAction<ConsumerFormData>>;
  passwords: TPasswordFields;
  handleChangePassword(field: keyof TPasswordFields, newValue: string): void;
  deleteBanks: number[];
  setDeleteBanks: React.Dispatch<React.SetStateAction<number[]>>
}

const UsersFormContext = createContext<IUsersFormContext>({} as IUsersFormContext);


type BankTypeOptions = 'money' | 'bank';
type MoneyBanksData = { id: number; type: 'money', accountName: string; };
type BankBanksData = {
  type: 'bank';
  id: number;
  accountName: string;
  bankName: string;
  agency: string;
  account: string;
  initialDate: string;
}


export type BanksData = MoneyBanksData | BankBanksData;

type ConsumerFormData = {
  userData: {
    [field: string]: {
      required: boolean;
      value: string | number;
    }
  },

  userTypeData: {
    [field: string]: {
      required: boolean;
      value: string | number;
    }
  }
}

type TFormFields = {
  firstName: {
    value: string;
    required: boolean;
  },
  lastName: {
    value: string;
    required: boolean;
  },
  email: {
    value: string;
    required: boolean;
  },
  frameBI: {
    value: string;
    required: boolean;
  },
  cpf: {
    value: string;
    required: boolean;
  },
  companyName: {
    value: string;
    required: boolean;
  },
  cnpj: {
    value: string;
    required: false
  },
  zipCode: {
    value: string;
    required: boolean;
  },
  street: {
    value: string;
    required: boolean;
  },
  city: {
    value: string;
    required: boolean;
  },
  state: {
    value: string;
    required: boolean;
  },
  neighborhood: {
    value: string;
    required: boolean;
  },
  numberAddress: {
    value: string;
    required: boolean;
  },
  telNumberPrimary: {
    value: string;
    required: boolean;
  },
  telNumberSecondary: {
    value: string;
    required: boolean;
  },
  plan_id: {
    value: string;
    required: boolean;
  },
  openingBalance: {
    value: number;
    required: boolean;
  },
}

const emptyBanksData = {
  type: 'bank',
  id: 0,
  accountName: "",
  bankName: "",
  agency: "",
  account: "",
  initialDate: "",
} as BanksData;


export const UsersFormContextProvider: FC = ({ children }) => {

  const currencyRef = useRef<HTMLInputElement>(null);
  const [banksData, setBanksData] = useState<BanksData[]>([{ ...emptyBanksData }]);
  const [passwords, setPasswords] = useState<TPasswordFields>({
    confirmation: "",
    password: ""
  });
  const [deleteBanks, setDeleteBanks] = useState<number[]>([]);
  const [formData, setFormData] = useState<ConsumerFormData>({
    userData: {
      firstName: {
        value: "",
        required: true
      },
      lastName: {
        value: "",
        required: true
      },
      email: {
        value: "",
        required: true
      },
      frameBI: {
        value: "",
        required: false
      },

    },

    userTypeData: {
      cpf: {
        value: "",
        required: true
      },
      companyName: {
        value: "",
        required: true
      },
      cnpj: {
        value: "",
        required: false
      },
      zipCode: {
        value: "",
        required: true
      },
      street: {
        value: "",
        required: true
      },
      city: {
        value: "",
        required: true
      },
      state: {
        value: "",
        required: true
      },
      neighborhood: {
        value: "",
        required: true
      },
      numberAddress: {
        value: "",
        required: true
      },
      telNumberPrimary: {
        value: "",
        required: true
      },
      telNumberSecondary: {
        value: "",
        required: false
      },
      plan_id: {
        value: 1,
        required: true
      },
      openingBalance: {
        value: "",
        required: false
      },
    }
  })

  const handleChangePassword = (field: keyof TPasswordFields, newValue: string) => {
    setPasswords((prev) => ({
      ...prev,
      [field]: newValue
    }))
  }

  return (
    <UsersFormContext.Provider value={{ currencyRef, banksData, formData, setFormData, setBanksData, passwords, handleChangePassword, deleteBanks, setDeleteBanks }}>
      {children}
    </UsersFormContext.Provider>
  );
}

export default UsersFormContext;