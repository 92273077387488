import Swal from "sweetalert2"

export const confirmAlert = async (message: string) => {
 return await Swal.fire({
    title: '<strong>Confirmação</strong>',
    icon: 'warning',
    html: message,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> Sim',
    confirmButtonAriaLabel: 'Thumbs up, great!',
    cancelButtonText:
      '<i class="fa fa-thumbs-down"></i> Não',
    cancelButtonAriaLabel: 'Thumbs down'
  })
}
