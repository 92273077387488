import { IConsumer } from "../Interfaces/IUserTypes";

export const getConsumersSelectOptions = (data: IConsumer[]): {
  label: string;
  value: number;
}[] => {
  const options: { label: string; value: number }[] = []
  for (let consumer of data) {
    options.push({ label: consumer.companyName, value: consumer.user_id })
  }

  return options;
}

export const getConsumersSelectOptionsWithConsumerID = (data: IConsumer[]): {
  label: string;
  value: number;
}[] => {
  const options: { label: string; value: number }[] = []
  for (let consumer of data) {
    options.push({ label: consumer.companyName, value: consumer.id })
  }

  return options;
}