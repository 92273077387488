import React, { createContext, FC } from 'react';
import { useEffect } from 'react';
import { IAuthContext } from '../Interfaces/IAuthContext';
import { useLocation } from 'react-router-dom'
import { useState } from 'react';
import { UserRole } from '../@types/userRole';
import { LoginResponse } from '../types/ILoginResponse';
import { IAdministrator, IAnalyst, IConsumer, IConsumerForm, IUser } from '../Interfaces/IUserTypes';
import { authService } from '../services/auth';

const initUser: IUser = {
  id: 0,
  active: false,
  email: "",
  firstName: "",
  user_type: 1,
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: FC = ({ children }) => {
  const location = useLocation();
  const [isAutenticated, setIsAutenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [userType, setUserType] = useState<UserRole>("Consumer");
  const [userData, setUserData] = useState<IUser>(initUser);

  const [consumerData, setConsumerData] = useState<IConsumerForm>()
  const [analystData, setAnalystData] = useState<IAnalyst>()
  const [adminData, setAdminData] = useState<IAdministrator>()

  const [consumerList, setConsumerList] = useState<IConsumer[]>([]);

  const getConsumers = async (token: string) => {
    try {
      const { status, data: { consumers } } = await authService.getConsumers(token);

      if (status === 200) {
        setConsumerList(consumers)
      }

    } catch (error) {

    }
  }

  const handleAutenticate = (token: string, isAuth: boolean, data: LoginResponse) => {
    setToken(token)
    setIsAutenticated(isAuth)

    const { user_type } = data.user;
    if (user_type === 1 || user_type === 2 && isAuth) getConsumers(token);
  }

  const handlePopulateUser = (data: LoginResponse) => {
    const { user_type } = data.user;

    if (user_type === 1) {
      setUserType("Administrator")
      setAdminData(data as IAdministrator);
    }

    if (user_type === 2) {
      setUserType("Analyst");
      setAnalystData(data as IAnalyst);
    }

    if (user_type === 3) {
      setUserType("Consumer");
      setConsumerData(data as IConsumerForm);
    }

    setUserData(data.user);
  }

  const noAuthPaths = ['/', '/assinatura', '/recuperar-senha']

  useEffect(() => {
    if (!isAutenticated && !noAuthPaths.includes(location.pathname)) {
      setConsumerData(undefined);
      window.location.href = "/"
    }
  }, [location])

  return (
    <AuthContext.Provider value={{
      token,
      handleAutenticate,
      handlePopulateUser,
      isAutenticated,
      userType,
      consumerList,
      userData,
      consumerData,
      analystData,
      adminData
    }} >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;