import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { RiLockPasswordFill, RiMoneyDollarCircleLine } from 'react-icons/ri';
//@ts-ignore
import Switch from 'react-input-switch';
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../../components/ChangePassword';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';
import { confirmAlert } from '../../helpers/confirmAlert';
import { getUserRole } from '../../helpers/getUserRole';
import { IAdministratorFormData, IAnalystFormData, IConsumerFormData } from '../../Interfaces/IUserFormData';
import { IUserFormLocationState } from '../../Interfaces/IUserFormLocationState';
import { IUser } from '../../Interfaces/IUserTypes';
import { userService } from '../../services/user';
import { UsersTypeOptions } from '../../types/UsersType';

import './styles.css';

const Users: React.FC = () => {
  const { token } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const navigate = useNavigate()

  const [users, setUsers] = useState<IUser[]>([]);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const handleOpenPayments = (userId: number) => {
    navigate('/profile/payments', { state: { customerId: userId } });
  }

  const handleOpenChangePassword = (userId: number) => {
    setSelectedUserId(userId);
    setOpenChangePassword(true);
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  }

  function handleNewUser<T>(data: T) {
    type DataForm = T extends undefined
      ? never
      : T extends IConsumerFormData
      ? IConsumerFormData
      : T extends IAnalystFormData
      ? IAnalystFormData
      : IAdministratorFormData;

    navigate("form", { state: { action: "add" } as IUserFormLocationState<DataForm> });
  }

  const handleEditUser = async (user: IUser) => {
    const { id, user_type } = user;
    try {
      const { status, data: { user } } = await userService.getUserData(token, id, user_type);
      if (status !== 200) { throw `Falha ao buscar informações do usuário, status code: ${status}` }
      handleNavigateToEdit(user, id);
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  function handleNavigateToEdit<T>(data: UsersTypeOptions, userId: number) {
    type DataForm = T extends undefined
      ? never
      : T extends IConsumerFormData
      ? IConsumerFormData
      : T extends IAnalystFormData
      ? IAnalystFormData
      : IAdministratorFormData;
    navigate("form", { state: { action: "edit", data, userType: getUserRole(data.user.user_type), userId } as IUserFormLocationState<DataForm> });
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  const getUsers = async () => {
    try {
      const { status, data } = await userService.findAll(token);
      if (status === 200) {
        setUsers(data);
      } else {
        throw `Falha ao buscar usuários, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  const handleChangeStatus = async (id: number, newStatus: boolean, index: number, userType: number) => {
    if (userType === 1) {
      return showToast("Não é possivel desabiltiar um administrador", "warning");
    }

    try {
      const { status } = await userService.changeStatus(token, id, newStatus);
      if (status === 200) {
        const newUsers = users;
        newUsers[index].active = newStatus;
        setUsers(newUsers);
        showToast("Status alterado com sucesso!", "success");
      } else {
        throw `Falha ao buscar usuários, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  const getUserRoleName = (userType: number): string => {
    switch (userType) {
      case 1: return "Administrador";
      case 2: return "Analista";
      case 3: return "Cliente";
      default: return "N/A";
    }
  }

  const handleDelete = async (id: number, userType: number) => {
    const { isConfirmed } = await confirmAlert("Tem certeza que deseja deletar o usuário?");
    if(!isConfirmed) return;

    try {
      const { status } = await userService.delete(token, { id, typeId: userType });
      if(status === 200) {
        showToast("Usuário deletado com sucesso!", "success");
        const usersData = users;
        const deletedIndex = usersData.findIndex(user => user.id === id);
        usersData.splice(deletedIndex, 1)
        setUsers(usersData);
      } else if(status === 403) {
        showToast("Não é possivel apagar um administrador!", "warning");
      }else {
        throw `Falha ao deletar usuário, status code: ${status}`
      }
    } catch (error) {
      showToast(`${error}`, "error")
    }
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div className="users-container">
      <div className="users-header">
        <button className="header-btn" onClick={handleNewUser}>
          Novo Usuário
        </button>
      </div>
      <div className="users-body">
        <div className="table-header">
          <div>
            <span>Nome</span>
          </div>
          <div>
            <span>Email</span>
          </div>
          <div>
            <span>Tipo</span>
          </div>
          <div>
            <span>Ativo</span>
          </div>
          <div>
            <span>#</span>
          </div>
        </div>

        <div className="table-body">
          {users.map((user, index) =>
            <div className="line">
              <div>
                <span>{user.firstName} {user.lastName}</span>
              </div>
              <div>
                <span>{user.email}</span>
              </div>
              <div>
                <span>{getUserRoleName(user.user_type)}</span>
              </div>
              <div>
                <Switch on={true} off={false} value={user.active} onChange={(status: any) => handleChangeStatus(user.id, status, index, user.user_type)} />
              </div>
              <div>
                <AiFillEye className="eye-icon" onClick={() => handleEditUser(user)} />
                <FaTrash className="trash-icon" onClick={() => handleDelete(user.id, user.user_type)} />
                <RiLockPasswordFill className="passwd-icon" onClick={() => handleOpenChangePassword(user.id)} />
                <RiMoneyDollarCircleLine className="finance-icon" onClick={() => handleOpenPayments(user.id)} />
              </div>
            </div>
          )}
        </div>
      </div>
      <ChangePassword open={openChangePassword} onClose={handleCloseChangePassword} userId={selectedUserId}/>
    </div>

  );
}

export default Users;