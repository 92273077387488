import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { getConsumersSelectOptions } from '../../helpers/getConsumersSelectOptions';
import { canViewAllOptionsDashboard } from '../../policies/canViewAllOptionsDashboard';
import { parameterService } from '../../services/parameter';
import Select from 'react-select';
import { icons } from '../../assets/images';

import { ImArrowDown, ImArrowUp } from 'react-icons/im';

import './styles.css';
import { Months } from '../../utils/contants/months';
import { getYears, newGetYears } from '../../helpers/years';
import UIContext from '../../contexts/UIContext';
import { IDREGeneric, IDREResponse } from '../../Interfaces/IDreResponse';
import { currencyFormat } from '../../helpers/currencyFormat';
import { DREContext } from '../../contexts/DREContext';
import DREFilter from '../../components/DreFilter';
import CountUp from 'react-countup';
import { tsXLXS } from 'ts-xlsx-export';

interface IDreFilterData {
  month?: string;
  year?: string;
  consumer?: number;
}

const notApplyPercentage = ['Receita', 'Receita Bruta', 'Margem de Contribuição', 'Resultado']

const getSelectCurrentMonth = (): {
  label: string;
  value: string;
  number: number;
} => {
  const date = new Date();
  const currentMonthNumber = date.getMonth() + 1;;
  const month = Months.find(month => month.number === currentMonthNumber);
  if (month) return month;

  return { label: '', value: '', number: 0 }
}

const getCurrentYear = (): { label: string; value: number } => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return { label: String(currentYear), value: currentYear };
}

const initialStates = {
  dreFilterData: {
    month: getSelectCurrentMonth().value,
    year: String(getCurrentYear().value),
    consumer: undefined
  } as IDreFilterData
}

const DRE: React.FC = () => {
  const { token, userType, consumerList, userData } = useContext(AuthContext);
  const { filter, setFilter } = useContext(DREContext);
  const { setComponentUIState } = useContext(UIContext);

  const [dreList, setDreList] = useState<IDREGeneric>();

  const optionsRule = canViewAllOptionsDashboard(userType);

  useEffect(() => {
    if (userType === "Consumer") getInitialData();
  }, [])

  const getInitialData = async () => {
    const { data: { dre } } = await parameterService.getDre(token, { month: initialStates.dreFilterData.month, year: initialStates.dreFilterData.year, consumer: userData.id });
    setDreList(dre);
  }

  const handleChangeSelect = (ev: {
    value: number | string;
    label: string | number;
  } | null, field: keyof IDreFilterData) => {
    if (ev) {
      setFilter({ ...filter, [field]: ev.value })
    }
  }

  const handleFilter = async () => {
    if (!filter.consumer && userType !== "Consumer") {
      return setComponentUIState({
        component: "Toast",
        componentState: { open: true, message: 'Campo cliente é obrigatório', type: "warning" }
      })
    }

    if (!filter.year) {
      return setComponentUIState({
        component: "Toast",
        componentState: { open: true, message: 'Campo ano é obrigatório', type: "warning" }
      })
    }

    const consumer = userType === "Consumer" ? userData.id : filter.consumer || 0;
    const { data: { dre } } = await parameterService.getDre(token, { month: filter.month, year: filter.year, consumer });
    setDreList(dre);

    return;
  }

  const isNegativeStyle = (key: string, value: number): { color: 'red' } | {} => {
    if (negativeOptions.includes(key)) return { color: 'red' };
    if (Math.sign(value) === -1) return { color: 'red' };
    return {};
  }

  const renderArrowIcon = (key: string, value: number): JSX.Element => {
    if (negativeOptions.includes(key)) return <ImArrowDown color='red' size={25} className='arrow-down-icon' />;
    if (Math.sign(value) === -1) return <ImArrowDown color='red' size={25} className='arrow-down-icon' />;

    return <ImArrowUp color='green' size={25} className='arrow-up-icon' />
  }

  const fomartedTotalString = (total: number): string => {
    return `R$ ${currencyFormat(total)}`;
  }

  const handleExport = async () => {
    if (!dreList) {
      return setComponentUIState({
        component: "Toast",
        componentState: { open: true, message: 'Ops... Não existe dados filtrados', type: "warning" }
      })
    }


    let toExportResumes = await Promise.all(
      Object.keys(dreList).map((key: any) => {
        return {
          "Tipo": key,
          "Total": dreList[key].total,
          "Porcentagem": dreList[key].percentage
        }
      })
    )

    await tsXLXS()
      .exportAsExcelFile(toExportResumes)
      .saveAsExcelFile(`export_resume`);
  }

  const negativeOptions = ['Impostos', 'Custo Operacional', 'Despesas']

  return (
    <div className="drepage-container">
      <div className="drepage-header">
        <div className="date-container" style={!optionsRule ? { width: '45%' } : {}}>
          <span>Mês</span>
          <Select
            placeholder="Selecione o Mês"
            options={[{ label: '', value: '', number: 0 }, ...Months]}
            onChange={(ev) => {
              handleChangeSelect(ev, 'month')
            }}
            defaultValue={getSelectCurrentMonth()}
          />
        </div>
        <div className="date-container" style={!optionsRule ? { width: '45%' } : {}}>
          <span>Ano</span>
          <Select
            placeholder="Selecione o Ano"
            options={[{ label: '', value: 0 }, ...newGetYears()]}
            onChange={(ev) => handleChangeSelect(ev, 'year')}
            defaultValue={getCurrentYear()}
          />
        </div>
        {optionsRule &&
          <>
            <div className="customer-container">
              <span>Cliente</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o cliente"
                  options={getConsumersSelectOptions(consumerList)}
                  onChange={(ev) => handleChangeSelect(ev, 'consumer')}
                />
              </div>
            </div>
          </>
        }
        <div className="search-container" role="search" onClick={handleFilter}>
          <img src={icons.searchIcon} />
        </div>
      </div>
      <div className='dre-body'>
        <div style={{ position: 'relative' }}>
          <img src={icons.xlsxIcon} className="export-icon-dre" onClick={handleExport} />

          <div className="small-widget-box-dre">
            <div className="table-grid-dre">
              <div className="header fixedToTop">Demonstração do Resultado do Exercício</div>
              {dreList && Object.keys(dreList).map((key, i) => {
                const lineStyle = () => i % 2 === 0 ? { background: '#DCF5F8' } : {};

                return (
                  <>
                    <div className="body-table-dre" style={{ ...lineStyle() }}>
                      <div className='table-dre-type' style={{ ...isNegativeStyle(key, dreList[key].total) }}>
                        {renderArrowIcon(key, dreList[key].total)}
                        <span className='title'>{key}</span>
                      </div>
                    </div>
                    <div className="body-table-dre" style={{ ...isNegativeStyle(key, dreList[key].total), ...lineStyle() }}>
                      <div className='table-dre-type'>
                        <span className='money'>
                          <CountUp
                            start={0}
                            end={Math.abs(dreList[key].total)}
                            duration={1.2}
                            separator="."
                            decimals={2}
                            decimal=","
                            prefix="R$ "
                          />
                        </span>
                      </div>
                    </div>
                    <div className="body-table-dre" style={{ ...lineStyle() }}>
                      <div className='table-dre-type'>
                        <span className='percentage'>
                          {notApplyPercentage.includes(key) ? '-' : `${dreList[key].percentage === null ? 0 : dreList[key].percentage} %`}
                        </span>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <DREFilter applyFilter={handleFilter} handleExport={() => { }} />
    </div>
  );
}

export default DRE;