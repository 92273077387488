import React from 'react';

import './styles.css';

interface IButton {
  text: string;
  isLoading?: boolean;
  onPress(): void;
}

const Button: React.FC<IButton> = ({ text, isLoading, onPress }) => {
  return (
    <button className='common-btn' disabled={isLoading} onClick={() => !isLoading && onPress()}>
      {text}
    </button>
  )
}

export default Button;