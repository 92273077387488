export const toastColors = (type: "info" | "warning" | "error" | "success") => {
  switch (type) {
    case "info": return { bg: '#9EDBF3', color: 'rgba(255,255,255,0.8)' };
    case "success": return {bg: '#40D666', color: 'rgba(255,255,255,0.8)'}
    case "warning": return {bg: '#EBED53', color: 'rgba(88,88,88,1)'}
    case "error": return {bg: '#FA5E4B', color: 'rgba(255,255,255,0.8)'}
    default: {
      return {bg: '#9EDBF3', color: 'rgba(255,255,255,0.8)'}
    }
  }
}

