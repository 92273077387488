import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routers } from '../../@types/routers';
import { icons, images } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import SideBarMobile from '../SideBarMobile';

import './styles.css';

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { handleAutenticate } = useContext(AuthContext);

  const handleOpenProfileMenu = () => {
    setOpen(!open)
  }

  const handleOpenNotification = () => {

  }

  const handleNavigate = (to: Routers) => {
    setOpen(false)
    navigate(to)
  }

  const handleLogout = () => {
    navigate("/")
    handleAutenticate("", false, {} as any);
  }

  return (
    <div id="menu-header">
      <div className="sidebar-mobile">
        <SideBarMobile />
      </div>

      {/* <div className="notification-container">
        <img src={icons.bellIcon} onClick={() => { }} className="notification-icon" />
        <div className="notification" onClick={handleOpenNotification}>
          <span>100</span>
        </div>
      </div> */}

      <div className="avatar-container">
        <img src={images.MockProfle} alt="Avatar" className="avatar" />
        <div className="down-arrow">
          <img src={icons.downArrowIcon} onClick={handleOpenProfileMenu} role="menuitem" />
        </div>
      </div>

      <div className={open ? "profile-menu" : "profile-menu-inactive"}>
        {open && (
          <>
            <div onClick={() => handleNavigate("profile")} className={"menu-link"} role="link" id="profile">
              <img src={icons.profileIcon} role="profile-icon" className="menu-icon" />
              <span className="link">Perfil</span>
            </div>
            <div onClick={handleLogout} className={"menu-link"} role="link">
              <img src={icons.logoutIcon} role="logout-icon" className="menu-icon" />
              <span className="link">Sair</span>
            </div>
          </>)}
      </div>

      {open && <div className="close-profile-menu" onClick={handleOpenProfileMenu} /> }
    </div>
  )
}

export default Header