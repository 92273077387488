import React, { useContext, useState } from 'react';
import Select from 'react-select'
import { icons } from '../../assets/images';
import AuthContext from '../../contexts/AuthContext';
import { DREContext } from '../../contexts/DREContext';
import { getConsumersSelectOptions } from '../../helpers/getConsumersSelectOptions';
import { getYears, newGetYears } from '../../helpers/years';
import { canViewAllOptionsResume } from '../../policies/canViewAllOptionsResume';
import { Months } from '../../utils/contants/months';

import './styles.css';

interface IProps {
  applyFilter(): void;
  handleExport(): void;
}

interface IDreFilterData {
  month?: string;
  year?: string;
  consumer?: number;
}

const getSelectCurrentMonth = (): {
  label: string;
  value: string;
  number: number;
} => {
  const date = new Date();
  const currentMonthNumber = date.getMonth() + 1;;
  const month = Months.find(month => month.number === currentMonthNumber);
  if (month) return month;

  return { label: '', value: '', number: 0 }
}

const getCurrentYear = (): { label: string; value: number } => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return { label: String(currentYear), value: currentYear };
}

const DREFilter: React.FC<IProps> = ({ applyFilter, handleExport }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(getSelectCurrentMonth());
  const [selectedYear, setSelectedYear] = useState<any>(getCurrentYear());
  const [selectedConsumer, setSelectedConsumer] = useState<any>();

  const { filter, setFilter } = useContext(DREContext);
  const { consumerList, userType, userData } = useContext(AuthContext);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleFilter = async () => {
    handleClose();
    applyFilter();
  }


  const handleChangeSelect = (ev: {
    value: number | string;
    label: string | number;
  } | null, field: keyof IDreFilterData) => {
    if (ev) {
      setFilter({ ...filter, [field]: ev.value })
    }
  }


  const optionsRule = canViewAllOptionsResume(userType);

  return (
    <>
      <div className="close-menu-container-dre" onClick={handleOpen}>
        <img src={icons.filterIcon} alt="resume-filter-icon" />
      </div>

      {
        open &&
        <div className="menu-container-dre">
          <div className="close-filter" onClick={handleClose} />

          <div className="side-filter">
            <div className="side-filter-header" onClick={handleClose} role="columnheader">
              <img src={icons.closeIconAppColor} className="close-icon" />
            </div>

            <div className="year-container">
              <span>Mês</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o Mês"
                  options={[{ label: '', value: '', number: 0 }, ...Months]}
                  onChange={(ev) => {
                    handleChangeSelect(ev, 'month')
                    setSelectedMonth(ev)
                  }}
                  value={selectedMonth}
                  defaultValue={getSelectCurrentMonth()}
                />
              </div>
            </div>

            <div className="year-container">
              <span>Ano</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o Ano"
                  options={[{ label: '', value: 0 }, ...newGetYears()]}
                  onChange={(ev) => {
                    handleChangeSelect(ev, 'year')
                    setSelectedYear(ev)
                  }}
                  value={selectedYear}
                  defaultValue={getCurrentYear()}
                />
              </div>
            </div>

            {optionsRule &&
              <>
                <div className="customer-container">
                  <span>Cliente</span>
                  <div className="select-input">
                    <Select
                      placeholder="Selecione o cliente"
                      options={getConsumersSelectOptions(consumerList)}
                      onChange={e => {
                        handleChangeSelect(e, "consumer")
                        setSelectedConsumer(e)
                      }}
                      value={selectedConsumer}
                    />
                  </div>
                </div>
              </>
            }

            <div className="search-container">
              <span onClick={handleFilter} role="search">Buscar</span>
            </div>

            {/* <div className="export-container">
              <span onClick={handleExport} role="document">Exportar</span>
            </div> */}

          </div>
        </div>
      }
    </>
  );
}

export default DREFilter;