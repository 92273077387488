import { AxiosResponse } from "axios";
import { UserRole } from "../@types/userRole";
import { BanksData } from "../contexts/UsersFormContext";
import { IBanksData } from "../Interfaces/IUserFormData";
import { IAdministrator, IAnalyst, IBanks, IConsumer, IUser } from "../Interfaces/IUserTypes";
import { LoginResponse } from "../types/ILoginResponse";
import { UsersTypeOptions } from "../types/UsersType";
import { API } from "./config";

interface IUpdateParams {
  id: number;
  userType: number;
  userObject: IAdministrator | IAnalyst | IConsumer;
  bankData: IBanksData[];
  isAdmin: boolean;
}

interface ICreateParams {
  userData: IUser & { password: string };
  userDataType: IAdministrator | IAnalyst | IConsumer;
  bankData?: IBanks[] | BanksData[];
  userType: UserRole;
}

export const userService = {
  findAll: async (token: string): Promise<AxiosResponse<IUser[]>> => {
    return API.get('/users', { headers: { 'Authorization': `Bearer ${token}` } });
  },

  update: async (token: string, data: IUpdateParams): Promise<AxiosResponse<{ user: LoginResponse }>> => {
    return API.put('/users/updateOneUser', { ...data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  delete: async (token: string, data: { id: number; typeId: number }): Promise<AxiosResponse> => {
    return API.post('/users/delete', { ...data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  changeStatus: async (token: string, id: number, status: boolean): Promise<AxiosResponse> => {
    return API.put(`/users/active/${id}`, { status }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  create: async (token: string, data: ICreateParams): Promise<AxiosResponse> => {
    return API.post('/users', { ...data }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getUserData: async (token: string, id: number, typeId: number): Promise<AxiosResponse<{ user: UsersTypeOptions }>> => {
    return API.post('/users/findUserByIDandTypeUser', { id, typeId }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  changePassword: async (token: string, id: number, newPassword: string): Promise<AxiosResponse> => {
    return API.put(`/users/password`, { id, newPassword }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getConsumerBanks: async (token: string, consumerId: number): Promise<AxiosResponse<{ banks: IBanksData[] }>> => {
    return await API.get(`/consumers/banks/${consumerId}`, { headers: { 'Authorization': `Bearer ${token}` } });
  }
}