import React from 'react';

import './styles.css';

interface IProps {
  banks: { label: string; value: number }[];
  selected: { label: string; value: number };
  onSelect(bank: { label: string; value: number }): void;
}

const DashboardBankOptions: React.FC<IProps> = ({ banks, selected, onSelect }) => {
  const handleSelect = (bank: { label: string; value: number }) => {
    onSelect(bank);
  }

  const isAllSelected = !selected.value;


  return (
    <div className="dashboard-bankOptions-container">
      {
        banks.map(bank => {
          const isSelected = selected.value === bank.value;
          return (
            <div className="item">
              <label>{bank.label}</label>
              <input type="radio" checked={isSelected} onClick={() => handleSelect(bank)}></input>
            </div>
          )
        })
      }

      <div className="item">
        <label>Todas</label>
        <input type="radio" checked={isAllSelected} onClick={() => handleSelect({ label: 'all', value: 0})}></input>
      </div>
    </div>
  );
}

export default DashboardBankOptions;