import { PageAction } from "../types/paginateOptions";
import { API } from "./config";
import { AxiosResponse } from 'axios';
import { ICashflowAllDataResponse } from "../Interfaces/ICashflowAPIResponse";
import { ICashflowFilters } from "../Interfaces/ICashflowFilters";
import { ICashflowFormSave } from "../Interfaces/ICashflowFormSave";
import { ISaveCashflowForm } from "../Interfaces/ISaveCashflowForm";
import { IAllImagesResponse } from "../Interfaces/IImagesResponse";
import { CashflowStatusOptions } from "../components/CashflowStatus";
import { ICashflowData } from "../Interfaces/ICashflowData";

export const cashflowService = {
  getData: async (skip: number, action: PageAction, token: string): Promise<AxiosResponse<ICashflowAllDataResponse>> => {
    const start = action === "Initial";
    return await API.get(`/commitment/${skip}/${start}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getRecurrences: async (recurrenceId: string, token: string): Promise<AxiosResponse<{ data: Pick<ICashflowData, "id" | "date">[] }>> => {
    return await API.get(`/commitment/recurrence/${recurrenceId}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getConsumerData: async (skip: number, userID: number, action: PageAction, token: string) => {
    const start = action === "Initial";
    return await API.get(`/commitment/client/${skip}/${userID}/${start}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getInvoices: async (token: string): Promise<AxiosResponse<{ invoices: string[] }>> => {
    return await API.get('/parameter/find/invoices', { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getFilterData: async (skip: number, action: PageAction, filters: ICashflowFilters, token: string): Promise<AxiosResponse<ICashflowAllDataResponse>> => {
    const bodyData = {
      skip,
      start: action === "Initial",
      filters
    }
    return await API.post(`/commitment/customSearch`, { ...bodyData }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  create: async (token: string, data: ISaveCashflowForm, isRecourrence: boolean, recourrenceData?: { day: number, months: number, startOf?: string }): Promise<AxiosResponse> => {
    return await API.post("/commitment", { commitment: data, isRecourrence, recourrenceData }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  update: async (token: string, data: ISaveCashflowForm, id: string | number, isRecurrence: boolean ): Promise<AxiosResponse> => {
    return await API.put(`/commitment/${String(id)}`, { commitment: data, isRecurrence }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  uploadImage: async (token: string, dataForm: FormData) => {
    return await API.post(`/image/upload`, { dataForm }, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getImages: async (token: string): Promise<AxiosResponse<IAllImagesResponse>> => {
    return await API.get("/image", { headers: { 'Authorization': `Bearer ${token}` } });
  },

  getConsumerImages: async (token: string, consumerID: number): Promise<AxiosResponse<IAllImagesResponse>> => {
    return await API.get(`/image/${consumerID}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  deleteCashflow: async (token: string, id: number | number[], isRecurrence: boolean): Promise<AxiosResponse> => {
    return await API.delete(`/commitment/${id}?isRecurrence=${isRecurrence}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  changeStatus: async (token: string, id: number, status: CashflowStatusOptions, analystId: number): Promise<AxiosResponse> => {
    return await API.put(`/commitment/update/status/${id}`, { status, analystId }, { headers: { 'Authorization': `Bearer ${token}` } })
  },

  export: async (filters: ICashflowFilters, token: string): Promise<AxiosResponse<{ data: ICashflowData[] }>> => {
    const bodyData = { filters };
    return await API.post(`/commitment/export`, { ...bodyData }, { headers: { 'Authorization': `Bearer ${token}` } });
  },
}