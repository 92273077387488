import React, { useState } from 'react';
import { AiOutlineAlert } from 'react-icons/ai'
import './styles.css';

interface IProps {
  onClose(): void;
  onSave(isChecked: boolean): void;
}

const ModalConfirmRecourrence: React.FC<IProps> = ({ onClose, onSave }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false); 

  const handleCloseModal = () => {
    onClose();
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = () => {
    onSave(isChecked);
  }


  return (
    <div className='modal-confirm-recourrence'>
      <div className='off-container' />
      <div className='on-container'>
        <header>
          <AiOutlineAlert size={50} color='#ffcb2e' />
        </header>
        <body>
          <p>
            Este lançamento foi cadastrado em uma <b>recorrência</b>, caso queira alterar todos os lançamentos dessa mesma recorrência, marque a opção abaixo.
            Caso altere somente esse lançamento o mesmo será <b>desvinculado</b> da recorrência.
          </p>
          <div>
            <input type='checkbox' checked={isChecked} onClick={() => setIsChecked((prev) => !prev)} />
            <span><b><i>Alterar todos os lançamentos</i></b></span>
          </div>
        </body>
        <footer>
          <button className="cancel-btn" onClick={handleCancel}>Cancelar</button>
          <button className="save-btn" onClick={handleSave}>Salvar</button>
        </footer>
      </div>
    </div>
  );
}

export default ModalConfirmRecourrence;