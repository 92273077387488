import React, { useMemo } from 'react';

import './styles.css'
import { WhatsappIconSVG } from '../../assets/images/whatsappiconcomp';
import { useLocation } from 'react-router-dom';

const WhatsappButton: React.FC = () => {

  const location = useLocation()

  const isDashboard = useMemo(() => {
    return location.pathname === "/dashboard"
  }, [location.pathname])

  const redirectToWhatsapp = () => {
    const phoneNumber = "553196160130"
    const link = `https://wa.me/${phoneNumber}`
    window.open(link, "_blank")
  }

  return (
    <div
      className={isDashboard ? "whatsapp-button-dash" : "whatsapp-button"}
      onClick={redirectToWhatsapp}
    >
      <WhatsappIconSVG />
    </div>
  )
}

export default WhatsappButton;