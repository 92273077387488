import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import AuthContext from '../../contexts/AuthContext';
import UIContext from '../../contexts/UIContext';

import './styles.css';

const mockerdSrc = "https://app.powerbi.com/view?r=eyJrIjoiZDY4ZGVmYjMtZTBkZi00MjMyLTliNWEtMTA3OTA1MWY3YThlIiwidCI6IjEwMGFkZTMzLTllNDktNDcxZi1iNmQ4LWZiN2U0MzQ5OTQzNCJ9&pageName=ReportSection"
const PowerBI: React.FC = () => {

  const { userData: { frameBI } } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    linkValidation();
  }, [])

  const linkValidation = () => {
    if (!frameBI || frameBI === "") {
      setIsValid(false);
      return setComponentUIState({
        component: "Toast",
        componentState: { open: true, message: "Você não possui uma url para o powerBI", type: "warning", duration: 5000 }
      })
    }

    if (frameBI && !frameBI.trim().startsWith('https://app.powerbi')) {
      setIsValid(false);
      return setComponentUIState({
        component: "Toast",
        componentState: { open: true, message: "A URL cadastrada não é válida", type: "warning", duration: 5000 }
      })
    }

    setIsValid(true);
  }

  return (
    <div className="powerBi-container">
      <div className="chart">
        {isValid && <iframe title="Indicadores de Gestão" width="100%" height="600" src={frameBI} allowFullScreen={true} role="figure"></iframe>}
      </div>
    </div>
  );
}

export default PowerBI;