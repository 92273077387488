import { UserRole } from "../@types/userRole";
import { IConsumerForm } from "../Interfaces/IUserTypes";

export const canViewUploadCashflow = (type: UserRole, consumerData: IConsumerForm | undefined) => {
  switch(type) {
    case "Administrator": return false;
    case "Analyst": return false;
    case "Consumer": {
      if(consumerData) {
        return consumerData.plan_id === 2;
      } else {
        return false;
      }
    }
    default: return false
  }
}