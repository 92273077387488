import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import GridTable from '../../components/GridTable';
import ResumeFilter from '../../components/ResumeFilter';
import AuthContext from '../../contexts/AuthContext';
import { calculateTotalByMonth } from '../../helpers/calculateTotalByMonth';
import { currencyFormat } from '../../helpers/currencyFormat';
import { IResumesValues } from '../../Interfaces/IResumeResponse';
import { resumeService } from '../../services/resume';
import { tsXLXS } from "ts-xlsx-export";

import './styles.css';
import UIContext from '../../contexts/UIContext';

const Resume: React.FC = () => {

  const { token, userType, userData } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const [tableData, setTableData] = useState<IResumesValues[]>([]);

  useEffect(() => {
    userType === "Consumer" && getData()
  }, [])

  function showToast(open: boolean, msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open, message: msg, type }
    })
  }

  async function getData(filter: { year: number, userID: number } = { year: new Date().getFullYear(), userID: userData.id }) {
    showToast(true, "Buscando informações", "info");

    try {
      const { year, userID } = filter;
      const { status, data: { resume, initialValue } } = await resumeService.getData(year.toString(), userID, token)

      if (status === 200) {
        const totalByMonth = calculateTotalByMonth(resume, initialValue);
        resume.push(totalByMonth as any);
      }
      
      showToast(true, "Informações carregadas com sucesso!", "success");
      setTableData(resume)

    } catch (error) {
      showToast(true, "Falha ao buscar informações", "error");
    }
  }

  const handleExport = async () => {
    let toExportResumes = await Promise.all(
      tableData.map((resume: any) => {
        return {
          "Conta/Mêses": resume.name,
          "Jan": Number(currencyFormat(resume.MOUNTH_VALUES.JANEIRO).replaceAll('.', '').replace(',', '.')),
          "Fev": Number(currencyFormat(resume.MOUNTH_VALUES.FEVEREIRO).replaceAll('.', '').replace(',', '.')),
          "Mar": Number(currencyFormat(resume.MOUNTH_VALUES.MARCO).replaceAll('.', '').replace(',', '.')),
          "Abr": Number(currencyFormat(resume.MOUNTH_VALUES.ABRIL).replaceAll('.', '').replace(',', '.')),
          "Mai": Number(currencyFormat(resume.MOUNTH_VALUES.MAIO).replaceAll('.', '').replace(',', '.')),
          "Jun": Number(currencyFormat(resume.MOUNTH_VALUES.JUNHO).replaceAll('.', '').replace(',', '.')),
          "Jul": Number(currencyFormat(resume.MOUNTH_VALUES.JULHO).replaceAll('.', '').replace(',', '.')),
          "Ago": Number(currencyFormat(resume.MOUNTH_VALUES.AGOSTO).replaceAll('.', '').replace(',', '.')),
          "Set": Number(currencyFormat(resume.MOUNTH_VALUES.SETEMBRO).replaceAll('.', '').replace(',', '.')),
          "Out": Number(currencyFormat(resume.MOUNTH_VALUES.OUTUBRO).replaceAll('.', '').replace(',', '.')),
          "Nov": Number(currencyFormat(resume.MOUNTH_VALUES.NOVEMBRO).replaceAll('.', '').replace(',', '.')),
          "Dez": Number(currencyFormat(resume.MOUNTH_VALUES.DEZEMBRO).replaceAll('.', '').replace(',', '.')),
        }
      })
    )

    await tsXLXS()
      .exportAsExcelFile(toExportResumes)
      .saveAsExcelFile(`export_resume`);
  }

  return (
    <div className="resume-container">
      <div className="resume-data">
        <GridTable data={tableData} />
      </div>
      <ResumeFilter applyFilter={getData} handleExport={handleExport} />
    </div>
  );
}

export default Resume;