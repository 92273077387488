import React, { useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICashflowFormLocationState } from '../../Interfaces/ICashflowFormLocationState';
import Select from 'react-select'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import './styles.css';
import { ConditionOptions } from '../../utils/contants/conditions';
import { OriginOptions } from '../../utils/contants/origin';
import CurrencyInput from '../../components/CurrencyInput';
import { useRef } from 'react';
import { useEffect } from 'react';
import { ISaveCashflowForm } from '../../Interfaces/ISaveCashflowForm';
import Swal from 'sweetalert2';
import { CashflowContext } from '../../contexts/CashflowContext';
import { getConsumersSelectOptionsWithConsumerID } from '../../helpers/getConsumersSelectOptions';
import AuthContext from '../../contexts/AuthContext';
import { cashflowService } from '../../services/cashflow';
import UIContext from '../../contexts/UIContext';
import { getCurrentMonthByDate } from '../../helpers/getCurrentMonthByDate';
import { formatCurrencyToSave } from '../../helpers/formatCurrencyToSave';
import CashflowStatus, { CashflowStatusOptions } from '../../components/CashflowStatus';
import { useState } from 'react';
import { IBanksData } from '../../Interfaces/IUserFormData';
import { userService } from '../../services/user';
import { icons } from '../../assets/images';
import ModalConfirmRecourrence from '../../components/ModalConfirmRecourrence';

type TOptionSelected = 'manual' | 'recurrence';
type TRecourrenceValue = { day: number, months: number, startOf?: string }

const CashflowForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { parametersList } = useContext(CashflowContext);
  const { consumerList, token, analystData, userType, consumerData } = useContext(AuthContext);
  const { setComponentUIState } = useContext(UIContext);

  const { action, data, classification } = location.state as ICashflowFormLocationState;

  const [optionSelected, setOptionSelected] = useState<TOptionSelected>('manual');
  const [recourrenceValue, setRecourrenceValue] = useState<TRecourrenceValue>({ day: 1, months: 12, startOf: undefined })
  const [selectedStatus, setSelectedStatus] = useState<string>("criado");
  const [consumerBanks, setConsumerBanks] = useState<IBanksData[]>([]);
  const [recurrenceModal, setRecurrenceModal] = useState<boolean>(false);
  const consumerRef = useRef<any>(null);
  const dateRef = useRef<any>(null);
  const invoiceRef = useRef<any>(null);
  const conditionRef = useRef<any>(null);
  const originRef = useRef<any>(null);
  const historyRef = useRef<any>(null);
  const billValueRef = useRef<any>(null);
  const bankRef = useRef<any>(null);
  const supplierRef = useRef<any>(null);

  const classificationCondition = classification === "pagamento" ? "saída" : "entrada";
  const isRecourrence = action === "add" && optionSelected === "recurrence";

  const bankOptions = useMemo(() => {
    if (userType === "Consumer") {
      const banks = consumerData?.banks.map((bank) => ({ label: bank.accountName, value: bank.id })) || []
      return banks;
    }

    if (consumerBanks.length > 0) {
      const banks = consumerBanks.map((bank) => ({ label: bank.accountName, value: bank.id })) || []
      return banks;
    }

    return [];

  }, [consumerData, userType, consumerBanks])

  useEffect(() => {
    if (action === "edit" && userType !== "Consumer") {
      getConsumerBanks(data?.client_id || 0)
    }
  }, [action])

  const handleCloseRecourrenceModal = () => {
    setRecurrenceModal(false)
  }

  const getConsumerBanks = (consumerId: number) => {
    if (consumerId && userType !== "Consumer") {
      userService.getConsumerBanks(token, consumerId).then(({ data, status }) => {
        if (status !== 200) {
          return setComponentUIState({
            component: "Toast",
            componentState: { open: true, message: 'Falha para buscar bancos do cliente selecionado!', type: 'error' }
          })
        }


        return setConsumerBanks(data.banks);
      }).catch((err) => {
        setComponentUIState({
          component: "Toast",
          componentState: { open: true, message: 'Falha para buscar bancos do cliente selecionado!', type: 'error' }
        })
      })
    }
  }

  useEffect(() => {
    if (action === "edit") { assignData() }
  }, [])

  const handleCancel = () => {
    navigate("/cashflow", { replace: false })
  }

  function showToast(msg: string, type: "success" | "info" | "warning" | "error") {
    setComponentUIState({
      component: "Toast",
      componentState: { open: true, message: msg, type }
    })
  }

  function validationForm(data: ISaveCashflowForm) {
    const { client_id, date, invoice, classification, origin, history, value, bank_id, supplier } = data;

    try {
      if (!client_id || client_id === 0) throw "Cliente";
      if (!isRecourrence && !!!date) throw "Data";
      if (!!!invoice) throw "Conta";
      if (!!!classification) throw "Condição";
      if (!!!origin) throw "Origem";
      if (!value || value === 0) throw "Valor";
      if (!!!bank_id) throw "Conta Bancária";
      if (!!!supplier) throw "Fornecedor é obrigatório";

      return true;
    } catch (error) {
      Swal.fire(
        'Opss...',
        `O campo ${String(error).toUpperCase()} é obrigatório`,
        'warning'
      )
      return false;
    }
  }

  function validationRecourrenceData(data: TRecourrenceValue) {
    try {
      if (!data.day || data.day > 31) throw "Dia para repetição invalido";
      if (!data.day || data.day < 1) throw "Dia para repetição deve ser igual ou superior a 1";
      if (!data.months || data.months <= 1) throw "Meses para repetição deve ser superior a 1";
      if (!data.months || data.months > 48) throw "Máximo de meses para repetição é de 4 anos";

      if (!data.startOf) throw "Você deve preencher a data inicial da recorrência"

      return true;
    } catch (error) {
      Swal.fire(
        'Opss...',
        String(error),
        'warning'
      )
      return false;
    }
  }

  const handleSave = () => {
    const formData: ISaveCashflowForm = {
      client_id: userType === "Consumer" ? consumerData?.id || 0 : action === "edit" ? data?.client_id || 0 : Number(consumerRef?.current?.getValue()[0]?.value) || 0,
      date: dateRef.current.value || "",
      invoice: invoiceRef?.current?.getValue()[0]?.value || "",
      classification: conditionRef?.current?.getValue()[0]?.value || "",
      origin: originRef?.current?.getValue()[0]?.value || "",
      history: historyRef.current.value || "",
      value: formatCurrencyToSave(billValueRef.current.value) || 0,
      status: "criado",
      month: getCurrentMonthByDate(dateRef.current.value || ""),
      year: new Date(dateRef.current.value || "").getFullYear(),
      bank_id: bankRef?.current?.getValue()[0]?.value || 0,
      supplier: supplierRef?.current.value || ""
    };

    const validation = validationForm(formData);
    if (!validation) return;

    if (action === "add") {
      if (isRecourrence) {
        const validation = validationRecourrenceData(recourrenceValue);
        if (!validation) return;

        return createCashflow(formData, true, recourrenceValue);
      }

      return createCashflow(formData, false);
    }

    const isRecourrenceEdit = data?.recurrenceId || null;

    if (isRecourrenceEdit) {
      return setRecurrenceModal(true);
    }

    return editCashFlow(formData);
  }

  const handleEditRecurrence = (changeAll: boolean) => {
    const formData: ISaveCashflowForm = {
      client_id: userType === "Consumer" ? consumerData?.id || 0 : action === "edit" ? data?.client_id || 0 : Number(consumerRef?.current?.getValue()[0]?.value) || 0,
      date: dateRef.current.value || "",
      invoice: invoiceRef?.current?.getValue()[0]?.value || "",
      classification: conditionRef?.current?.getValue()[0]?.value || "",
      origin: originRef?.current?.getValue()[0]?.value || "",
      history: historyRef.current.value || "",
      value: formatCurrencyToSave(billValueRef.current.value) || 0,
      status: "criado",
      month: getCurrentMonthByDate(dateRef.current.value || ""),
      year: new Date(dateRef.current.value || "").getFullYear(),
      bank_id: bankRef?.current?.getValue()[0]?.value || 0,
      supplier: supplierRef?.current?.value || "" 
    };

    return editCashFlow(formData, changeAll, data?.recurrenceId || '');
  }

  async function createCashflow(data: ISaveCashflowForm, isRecourrence: boolean, recourrenceData?: TRecourrenceValue) {
    try {
      const { status } = await cashflowService.create(token, data, isRecourrence, recourrenceData);

      if (status !== 201) {
        throw "Falha para realizar operação!"
      }

      showToast("Operação realizada com sucesso!", "success");
      navigate("/cashflow", { replace: false })
    } catch (error) {
      showToast(`${error}`, "error");
    }

  }

  async function editCashFlow(dataForm: ISaveCashflowForm, isRecurrenceEdit: boolean = false, recourrenceId: string = '') {
    const id = isRecurrenceEdit ? recourrenceId : data?.id || 0;

    try {
      const { status } = await cashflowService.update(token, dataForm, id, isRecurrenceEdit);

      if (status !== 200) {
        throw "Falha para realizar operação!"
      }

      showToast("Operação realizada com sucesso!", "success");
      navigate("/cashflow", { replace: false })
    } catch (error) {
      showToast(`${error}`, "error");
    }
  }

  const assignData = () => {
    if (data) {
      console.log(data)
      dateRef.current.value = data.date;
      invoiceRef.current.setValue([{ value: data.invoice, label: data.invoice }])
      conditionRef.current.setValue([{ value: data.classification, label: data.classification }])
      originRef.current.setValue([{ value: data.origin, label: data.origin }])
      bankRef.current.setValue([{ value: data.bank_id, label: data.bank.accountName }])
      historyRef.current.value = data.history
      supplierRef.current.value = data.supplier || ""
      billValueRef.current.value = data.value.toString().includes(".") ? data.value.toString() : `${data.value.toString()},00`
      setSelectedStatus(data.status)
    }
  }

  const canViewStatus = () => {
    if (userType !== "Analyst") return false;
    return true;
  }

  const handleChangeSelected = async (name: CashflowStatusOptions) => {
    if (userType !== "Analyst") return showToast(`Apenas analistas podem alterar o status do fluxo de caixa!`, "warning");

    const analystId = analystData?.id;
    if (analystId && data?.id) {
      try {
        const { status } = await cashflowService.changeStatus(token, data.id, name, analystId);
        if (status === 200) {
          setSelectedStatus(name);
        } else {
          throw `Falha ao atualizar status, status code ${status}`
        }
      } catch (error) {
        Swal.fire(
          'Opss...',
          `${error}`,
          'warning'
        );
      }
    }
  }

  const handleChangeOption = (opt: TOptionSelected) => {
    setOptionSelected(opt)
  }

  const handleChangeRecourrenceValue = (field: keyof TRecourrenceValue, value: number | string) => {
    setRecourrenceValue((prev) => ({
      ...prev,
      [field]: value
    }))

    console.log('MEU VALOR INPUTADO: ', value)
  }

  return (
    <div className="cashflow-form-container">
      <div className="box-container">
        <div className="back-container">
          <span className="back-button" onClick={handleCancel}>
            <AiOutlineArrowLeft size={20} color="#ffffffe6" />
            <span>Voltar</span>
          </span>
        </div>

        {action === "edit" && canViewStatus() && <div className="status-container">
          {["criado", "em análise", "finalizado"].map(status =>
            <CashflowStatus
              key={status}
              name={status as CashflowStatusOptions}
              selected={selectedStatus as CashflowStatusOptions}
              changeSelected={handleChangeSelected} />
          )}
        </div>}

        {action === "add" && <div className='add-cashflow-options-container'>
          <div className={optionSelected === 'manual' ? 'cashflow-options-button active' : 'cashflow-options-button'} onClick={() => handleChangeOption('manual')}>
            <img src={icons.addManualIcon} alt='add-manual' />
            <span>Manual</span>
          </div>
          <div className={optionSelected === 'recurrence' ? 'cashflow-options-button active' : 'cashflow-options-button'} onClick={() => handleChangeOption('recurrence')}>
            <img src={icons.refreshIcon} alt='refresh-icon-recourrence' />
            <span>Recorrência</span>
          </div>
        </div>}

        {optionSelected === "recurrence" &&
          <div className="form-recourrence">
            <span>Repetir todo dia</span>
            <input
              type="number"
              max="31" min="1"
              value={recourrenceValue.day}
              className="recourrence-input"
              onChange={(e) => handleChangeRecourrenceValue('day', Number(e.target.value))}
            />
            <span>durante</span>
            <input
              type="number"
              max="48"
              min="2"
              value={recourrenceValue.months}
              className="recourrence-input"
              onChange={(e) => handleChangeRecourrenceValue('months', Number(e.target.value))}
            />
            <span>meses</span>
            <span>a partir de</span>
            <input
              type="date"
              className="recourrence-date"
              onChange={(e) => handleChangeRecourrenceValue('startOf', e.target.value)}
              value={recourrenceValue.startOf}
            />
          </div> || <></>
        }

        <div className="form-container">
          {
            userType !== "Consumer" && action === "add" &&
            <div className="form-box">
              <span className="span-title">Cliente</span>
              <div className="select-input">
                <Select
                  placeholder="Selecione o cliente"
                  options={getConsumersSelectOptionsWithConsumerID(consumerList)}
                  ref={consumerRef}
                  onChange={(data) => data && getConsumerBanks(data.value)}
                />
              </div>
            </div>}
          <div className="form-box" style={isRecourrence ? { display: 'none' } : {}}>
            <span className="span-title">Data</span>
            <input type="date" className="input-date" ref={dateRef} />
          </div>
          <div className="form-box">
            <span className="span-title">Conta</span>
            <div className="select-input">
              <Select
                placeholder="Selecione a conta"
                options={
                  !classification ?
                    parametersList.filter(({ classification }) => classification === data?.origin)
                    :
                    parametersList.filter(({ classification }) => classification === classificationCondition)
                }
                ref={invoiceRef}
              />
            </div>
          </div>

          <div className="form-box">
            <span className="span-title">Condição</span>
            <div className="select-input">
              <Select
                placeholder="Selecione a condição"
                options={
                  !classification ?
                    ConditionOptions.filter(({ label }) =>
                      data?.origin === "entrada" ?
                        ["A receber", "Recebido"].includes(label)
                        :
                        ["A pagar", "Pago"].includes(label)
                    )
                    :
                    ConditionOptions.filter(({ label }) =>
                      classificationCondition === "entrada" ?
                        ["A receber", "Recebido"].includes(label)
                        :
                        ["A pagar", "Pago"].includes(label)
                    )
                }
                ref={conditionRef}
              />
            </div>
          </div>

          <div className="form-box">
            <span className="span-title">Origem</span>
            <div className="select-input">
              <Select
                placeholder="Selecione a origem"
                options={OriginOptions}
                ref={originRef}
                defaultValue={
                  classification ?
                    classificationCondition === "entrada" ?
                      { label: "Entrada", value: "Entrada" } :
                      { label: "Saída", value: "Saída" }
                    : undefined
                }
                isDisabled={true}
              />
            </div>
          </div>

          <div className="form-box">
            <span className="span-title" >Cliente/Fornecedor</span>
            <input
              type='text'
              className="text-input"
              placeholder="Digite Cliente/Fornecedor"
              ref={supplierRef} />
          </div>


          <div className="form-box">
            <span className="span-title" >Histórico</span>
            <textarea className="text-area-input" placeholder="Descreva um histórico caso julgue nessesário..." ref={historyRef} />
          </div>

          <div className="form-box">
            <span className="span-title">Conta Bancária</span>
            <div className="select-input" style={{ marginTop: 12 }}>
              <Select
                placeholder="Selecione o banco"
                options={bankOptions}
                defaultValue={bankOptions[0]}
                ref={bankRef}
              />
            </div>
          </div>

          <div className="form-box">
            <span className="span-title">Valor</span>
            <CurrencyInput
              name="price"
              mask="currency"
              prefix="R$"
              placeholder="0,00"
              ref={billValueRef}
            />
          </div>


        </div>



        <div className="footer">
          <button className="cancel-btn" onClick={handleCancel}>Cancelar</button>
          <button className="save-btn" onClick={handleSave}>Salvar</button>
        </div>
      </div>

      {recurrenceModal && <ModalConfirmRecourrence onClose={handleCloseRecourrenceModal} onSave={handleEditRecurrence} />}
    </div>
  );
}

export default CashflowForm;