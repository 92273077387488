import { API } from "./config"
import { AxiosResponse } from 'axios';

export type currentDataOutput = {
  status: string;
  dueDate: string;
  paymentMethod: string;
}

export type THistoric = {
  paymentStatus: string;
  paymentMethod: string;
  trialStartDate: string;
  created_at: string;
}

interface ICustomerPayments {
  current: currentDataOutput;
  historic: THistoric[];
}

export const paymentsService = {
  async getCustomerPayments(token: string, userId: number): Promise<AxiosResponse<{ data: ICustomerPayments }>> {
    return await API.get(`/payments/customer/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } });
  },

  async generatePaymentLink(userId: number, onlyGenerate: boolean = false): Promise<AxiosResponse<{ data: { url: string } }>> {
    return await API.post(`/payments/link`, { data: { onlyGenerate, userId } });
  }
}